import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { ReactComponent as SpotifySvg } from 'svgs/spotify-circle-transparent.svg';
import { ReactComponent as WorldsIconSvg } from 'svgs/worlds-icon.svg';
import { ReactComponent as OverlayIconWorldsFilled } from 'svgs/overlay-icon-worlds-filled.svg';
import { ReactComponent as OverlayIconWorldsOutlined } from 'svgs/overlay-icon-worlds-outlined.svg';
import { ReactComponent as OverlayIconStorytelling } from 'svgs/storytelling.svg';
import { ReactComponent as OverlayIconPro } from 'svgs/profile.svg';
import { ReactComponent as OverlayIconChampion } from 'svgs/champion.svg';
import { ReactComponent as OverlayIconSkill } from 'svgs/skill.svg';
import { ReactComponent as OverlayIconEasterEgg } from 'svgs/easter-egg.svg';
import { HotspotOverlayTypes } from 'store/clubhouse.types';
import { ColorNames } from 'utils/styles/theme';
import * as Styled from './OverlayTitle.styles';
import { useMotion } from './OverlayTitle.motion';

export interface OverlayTitleRef {
  show: () => gsap.core.Timeline
}

export interface OverlayTitleProps {
  title?: string;
  hasWorldsIcon?: boolean;
  overlayType?: HotspotOverlayTypes;
  color?: ColorNames;
  dashColor?: ColorNames;
  spotifyProfile?: string;
}

const defaultProps = {
  title: '',
  hasWorldsIcon: true,
  overlayType: null,
  color: ColorNames.white,
  dashColor: ColorNames.roti,
  spotifyProfile: null,
};

const OverlayTitle = forwardRef<OverlayTitleRef, OverlayTitleProps>(({
  title,
  hasWorldsIcon,
  overlayType,
  color,
  dashColor,
  spotifyProfile,
}: OverlayTitleProps, ref) => {
  const {
    spotifyIconRef,
    worldsIconRef,
    secondWorldsIconRef,
    dashRef,
    overlayIconRef,
    labelRef,
    show
  } = useMotion();

  const icons = useMemo(() => {
    switch (overlayType) {
      case HotspotOverlayTypes.Storytelling:
        return {
          worlds: <OverlayIconWorldsOutlined />,
          hotspot: <OverlayIconStorytelling />,
          hotspotColor: ColorNames.white,
        };
      case HotspotOverlayTypes.EasterEgg:
        return {
          worlds: <OverlayIconWorldsOutlined />,
          hotspot: <OverlayIconEasterEgg />,
          hotspotColor: ColorNames.white,
        };
      case HotspotOverlayTypes.Pro:
        return {
          worlds: <OverlayIconWorldsFilled />,
          hotspot: <OverlayIconPro />,
        };
      case HotspotOverlayTypes.Champion:
        return {
          worlds: <OverlayIconWorldsFilled />,
          hotspot: <OverlayIconChampion />,
        };
      case HotspotOverlayTypes.Skill:
        return {
          worlds: <OverlayIconWorldsFilled />,
          hotspot: <OverlayIconSkill />,
        };
      default:
        return null;
    }
  }, [overlayType]);

  useImperativeHandle(
    ref,
    () => ({
      show
    })
  );

  return (
    <Styled.Wrapper>
      <Styled.Left color={color}>
        <Styled.SpotifyIcon ref={spotifyIconRef}>
          {spotifyProfile ? <img src={spotifyProfile} /> : <SpotifySvg />}
        </Styled.SpotifyIcon>

        {hasWorldsIcon && (
          <Styled.WorldsIcon ref={worldsIconRef}>
            <WorldsIconSvg />
          </Styled.WorldsIcon>
        )}
        {icons && (
          <Styled.SecondWorldsIcon ref={secondWorldsIconRef}>
            {icons.worlds}
          </Styled.SecondWorldsIcon>
        )}
      </Styled.Left>
      <Styled.Dash color={dashColor} ref={dashRef} />
      {icons && (
        <Styled.OverlayIconWrapper
          color={icons.hotspotColor}
          ref={overlayIconRef}
        >
          {icons.hotspot}
        </Styled.OverlayIconWrapper>
      )}
      <Styled.LabelWrapper ref={labelRef}>
        <Styled.Label color={color}>{title}</Styled.Label>
      </Styled.LabelWrapper>
    </Styled.Wrapper>
  );
});

OverlayTitle.displayName = 'OverlayTitle';
OverlayTitle.defaultProps = defaultProps;

export default OverlayTitle;
