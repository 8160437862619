import { useRef, useEffect, RefObject } from 'react';
import gsap from 'gsap'

import { SecondarySlideRef } from '../SecondarySlide/SecondarySlide';
import { isMobileLayout } from 'utils/styles/responsive';
import { SECONDARY_OVERLAY_ANIMATE_IN_DELAY } from 'utils/config';

export interface MotionRef {
  wrapperRef?: RefObject<HTMLDivElement>;
  whiteBackgroundRef?: RefObject<HTMLDivElement>;
  secondarySlideRef?: RefObject<SecondarySlideRef>;
}

const useMotion = (refs: MotionRef) => {
  const { wrapperRef, whiteBackgroundRef, secondarySlideRef } = refs;

  const animateInTl = useRef<gsap.core.Timeline>(null);

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    const slidingFromProps = isMobileLayout() ? { y: '-100%' } : { x: '100%' };
    const slidingToProps = isMobileLayout() ? { y: 0 } : { x: 0 };
    const slidingRefs = isMobileLayout() ? [wrapperRef.current, whiteBackgroundRef.current] : wrapperRef.current;

    animateInTl.current
      .fromTo(
        slidingRefs,
        { ...slidingFromProps, autoAlpha: 0 },
        { ...slidingToProps, autoAlpha: 1, duration: 1.2, ease: 'expo.inOut' }
      )
      .add(secondarySlideRef.current.animateIn(), 0);
  };

  useEffect(() => {
    setTimeout(() => animateIn(), SECONDARY_OVERLAY_ANIMATE_IN_DELAY);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      if (animateInTl.current) animateInTl.current.kill();
    };

    // eslint-disable-next-line
  }, []);
};

export default useMotion;
