import styled from 'styled-components';

import { hover } from 'utils/styles/mixins';
import { layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.li`
  cursor: pointer;
  margin-right: 14rem;

  svg {
    height: 40rem;
  }

  ${hover(`
    opacity: ${layout.hoverOpacity};
  `)}
`;

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style-type: none;

  ${IconWrapper} {
    &:last-child {
      margin-right: 0;
    }
  }
`;
