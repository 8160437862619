import { useClubhouseStore } from 'store';
import preloaderService from 'u9/services/preloader.service';
import { isStorybook } from 'u9/utils/platform';
import { HOTSPOT_ASSETS } from 'utils/config';

const useSkillOverlayAssets = () => {
  const { currentClubhouse } = useClubhouseStore();
  const clubhouse = isStorybook() ? 'seoul' : currentClubhouse;

  const getSkillImage = () => {
    return preloaderService.get(HOTSPOT_ASSETS.SKILL_IMAGES[clubhouse]);
  }

  return { getSkillImage };
};

export default useSkillOverlayAssets;
