import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PlayPause from 'components/PlayPause/PlayPause';
import AudioManager, { DEFAULT_FADE_DURATION } from 'services/audioManager/audioManager.service';
import { defaultBGMData, SoundData, SOUNDS_DATA, SoundType } from 'services/audioManager/audioManager.data';
import { useAudioStore, useCopyStore, useGlobalStore, usePlaylistsStore } from 'store';
import { PlaylistTypes, Track } from 'store/playlists.types';
import { ReactComponent as SvgSave } from 'svgs/save.svg';
import { ReactComponent as SvgSpotify } from 'svgs/spotify-circle-transparent.svg';
import { useIntersectionObserver } from 'u9/hooks';
import { isStorybook } from 'u9/utils/platform';
import { getRecommendedTracks } from 'utils/api';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './Player.styles';

export const validTypes = ['hotspot', 'favorite', 'link'] as const;
export const validThemes: (ColorNames.roti | ColorNames.black | ColorNames.blueRibbon)[] = [ColorNames.roti, ColorNames.black, ColorNames.blueRibbon];
export const validPremiumStates: ('' | ColorNames.black | ColorNames.white)[] = ['', ColorNames.black, ColorNames.white];

export interface PlayerProps {
  track: Track;
  type?: typeof validTypes[number];
  theme?: typeof validThemes[number];
  premiumPrompt?: typeof validPremiumStates[number];
  centered?: boolean;
}

const defaultProps: Partial<PlayerProps> = {
  type: 'hotspot',
  theme: ColorNames.roti,
  premiumPrompt: '',
  centered: false
};

const Player: React.FC<PlayerProps> = ({ track, type, theme, premiumPrompt, centered }) => {
  const { copy } = useCopyStore(state => state);
  const { user, toggleAuthPromptVisible, accessToken } = useGlobalStore(state => state);
  const {
    anthemTrack,
    favoritedTracks,
    addTrackToFavorites,
    removeTrackFromFavorites,
    playlists,
    updatePlaylist,
    setLastUnlockedPlaylist,
    initialFavoriteCount
  } = usePlaylistsStore(state => state);
  const { currentLoopId } = useAudioStore((state) => state);

  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [hasQueuedAdd, setQueuedAdd] = useState<boolean>(false);
  const [wrapperRef, isPlayerInViewport] = useIntersectionObserver();

  const isFavorited = useMemo(() => !!favoritedTracks.find((favoritedTrack) => favoritedTrack.id === track.id), [favoritedTracks, track]);
  const favoriteIcon = useMemo(() => <Styled.FavoriteIcon isFavorited={isFavorited}><SvgSave /></Styled.FavoriteIcon>, [isFavorited]);

  const favoritedTracksRef = useRef<Track[]>(favoritedTracks);

  const onCtaClick = () => {
    if (!user && !isStorybook()) {
      setQueuedAdd(true);
      AudioManager.play(SOUNDS_DATA.buttonClick);
      toggleAuthPromptVisible();
      return;
    }

    if (isFavorited) {
      // Prevent the anthem from being removed from the favourites
      if (track.id === anthemTrack?.id) return;

      AudioManager.play(SOUNDS_DATA.buttonClick);
      removeTrackFromFavorites(track.id);
      stopPlaying();
      stop();
    } else {
      AudioManager.play(SOUNDS_DATA.favoriteTrack);
      favoriteTrack();
    }
  };

  const startPlaying = () => {
    if (isPlaying) return;
    setPlaying(true);
    play();
  };

  const stopPlaying = () => {
    if (!isPlaying) return;
    setPlaying(false);
    stop();
  };

  const togglePlay = () => {
    if (isPlaying) stopPlaying();
    else startPlaying();
  };

  const play = () => {
    if (!AudioManager.cache[track.id]) return;

    AudioManager.stopType(SoundType.BGM, 0, () => {
      if (currentLoopId) AudioManager.setVolume(SOUNDS_DATA[currentLoopId], 0);
      AudioManager.play(SOUNDS_DATA[track.id], {
        fadeDuration: 0.2,
        forceRestart: true,
        onEnd: () => setPlaying(false)
      });
    });
  };

  const stop = () => {
    if (!AudioManager.cache[track.id]) return;

    const proceed = () => {
      if (currentLoopId) {
        // Reset the current loop's default volume
        AudioManager.setVolume(SOUNDS_DATA[currentLoopId], null, DEFAULT_FADE_DURATION);
      }
    };

    if (AudioManager.cache[track.id].sound.playing()) AudioManager.stop(SOUNDS_DATA[track.id], 0.2, proceed);
    else proceed();
  };

  const checkUnlockables = useCallback(async () => {
    // If the user has favourited the required amount of tracks since they've started the session
    // (minus one, as the anthem is favourited by default),
    if (
      !playlists.personalized.isUnlocked &&
      ((favoritedTracksRef.current.length - initialFavoriteCount) - 1) >= playlists.personalized.unlockConditions.favorites
    ) {
      // Get the recommended tracks based on the favourites
      const recommendedTracks = await getRecommendedTracks(favoritedTracksRef.current);
      recommendedTracks.forEach((track) => {
        addTrackToFavorites(track);

        // If the track was provided with a preview url
        if (track.previewUrl) {
          // Create its sound data
          const previewSound: SoundData = {
            ...defaultBGMData,
            id: track.id,
            file: track.previewUrl
          };

          SOUNDS_DATA[track.id] = previewSound;
        }
      });

      // Load the new sound previews
      AudioManager.init(null, () => {
        // And unlock the personalised playlist
        updatePlaylist(PlaylistTypes.personalized, {
          isUnlocked: true,
          data: { ...playlists.personalized.data, tracks: recommendedTracks }
        });

        setLastUnlockedPlaylist(PlaylistTypes.personalized);
      });
    }
  }, [
    addTrackToFavorites,
    initialFavoriteCount,
    playlists.personalized,
    setLastUnlockedPlaylist,
    updatePlaylist,
  ]);

  const favoriteTrack = useCallback(() => {
    favoritedTracksRef.current = addTrackToFavorites(track);
    checkUnlockables();
  }, [addTrackToFavorites, checkUnlockables, track]);

  useEffect(() => {
    if (hasQueuedAdd && accessToken) {
      if (!isFavorited) favoriteTrack();
      setQueuedAdd(false);
    }
  }, [hasQueuedAdd, accessToken, isFavorited, favoriteTrack]);

  useEffect(() => {
    if (SOUNDS_DATA[track.id]) AudioManager.cache[track.id].sound.on('end', stopPlaying);

    return () => {
      if (SOUNDS_DATA[track.id]) AudioManager.cache[track.id].sound.off('end', stopPlaying);
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPlayerInViewport) stopPlaying();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayerInViewport]);

  return (
    <Styled.Wrapper centered={centered} ref={wrapperRef}>
      <Styled.PlayerWrapper
        themeColor={theme}
        {...(track?.previewUrl ? { onMouseEnter: startPlaying, onMouseLeave: stopPlaying } : {})}
      >
        <Styled.Cover onClick={togglePlay} hasPreview={!!track?.previewUrl}>
          <img src={track.image} />

          {track?.previewUrl && (
            <Styled.PlayPauseWrapper>
              <PlayPause state={isPlaying ? 'pause' : 'play'} />
            </Styled.PlayPauseWrapper>
          )}
        </Styled.Cover>
        <Styled.Details isCentered={type !== 'hotspot'}>
          <Styled.Info>
            <a href={track?.url} target="_blank" rel="noreferrer">
              <div>{track?.title}</div>
              <div>{track?.artists.join(', ')}</div>
            </a>
          </Styled.Info>

          {type === 'hotspot' && (
            <Styled.CTA onClick={onCtaClick}>
              <span>
                {user || isStorybook()
                  ? isFavorited ? copy.experience.player.removeFavorite : copy.experience.player.addFavorite
                  : copy.experience.player.connect
                }
              </span>
              {favoriteIcon}
            </Styled.CTA>
          )}
        </Styled.Details>

        {type !== 'hotspot' && (
          <Styled.Icon {...(type === 'favorite' ? { onClick: onCtaClick } : {})}>
            {type === 'favorite' && favoriteIcon}
            {type === 'link' && <a href={track?.url} target="_blank" rel="noreferrer"><SvgSpotify /></a>}
          </Styled.Icon>
        )}
      </Styled.PlayerWrapper>

      <Styled.PremiumPrompt
        href={copy.experience.player.premiumLink}
        target="_blank"
        rel="noreferrer"
        color={premiumPrompt as ColorNames}
        isVisible={!!premiumPrompt && !user?.hasPremium}
      >
        {copy.experience.player.premiumLabel}
      </Styled.PremiumPrompt>
    </Styled.Wrapper>
  );
};

Player.defaultProps = defaultProps;

export default Player;
