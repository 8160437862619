import styled from 'styled-components';
import { colors, layout } from 'utils/styles/theme';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  height: ${setVh(100)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${layout.zIndex.overlays};
`;

export const PanelWrapper = styled(motion.div)`
  width: 100%;
  height: ${setVh(100)};
  color: ${colors.black};
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  z-index: ${layout.zIndex.overlays};
  position: relative;
  transform-origin: top;
  ${mediaTablet(`
    width: 75vw;
    height: auto;
    padding: ${setVh(layout.headerFooterHeight.desktop / 6)} ${layout.headerFooterPadding.desktop}rem ${setVh(layout.headerFooterHeight.desktop / 1.33)} 160rem;
  `)}

  ${mediaDesktop(`
    width: 50vw;
  `)}
`;

export const BlackOverlay = styled(motion.div)`
  width: 100%;
  height: ${setVh(100)};
  background: ${colors.black};
  opacity: 0.6;
  position: absolute;
`
export const WhiteBackground = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.white};
  z-index: -1;
  left: 0;
  top: 0;
`
export const WhiteOverlay = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.white};
  opacity: 0;
  left: 0;
  top: 0;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${setVh(layout.headerFooterHeight.mobile)};
  margin-bottom: 100rem;
  ${mediaTablet(`
    height: ${setVh(layout.headerFooterHeight.desktop)};
    margin-bottom: 0;
  `)}
`

export const Heading = styled.div`
  max-width: 75%;

  ${({ theme: { locale } }) => setTypography('heading4', locale)}
  ${({ theme: { locale } }) => mediaTablet(`
    ${setTypography('heading5', locale)}
  `)}
  span {
    color: ${colors.blueRibbon};
  }
`
export const Body = styled.div`
  ${({ theme: { locale } }) => setTypography('leadBody2', locale)}
  margin-top: 60rem;

  ${mediaTablet(`
    max-width: 75%;
  `)};
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80rem;
  ${mediaTablet(`
    align-items: center;
    flex-direction: row;
    margin-top: 120rem;
  `)}
`
