import styled from 'styled-components';
import { setVh } from 'utils/styles/mixins';
import { layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  z-index: ${layout.zIndex.footer};
  height: ${setVh(layout.headerFooterHeight.mobile)};
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  > div {
    height: ${layout.audioToggleHeight.mobile}rem;
  }

  > svg {
    height: 60rem;
  }
`;
