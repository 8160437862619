import React, { useRef } from 'react';

import Ribbon from 'components/Ribbon/Ribbon';
import { HotspotTypes } from 'store/clubhouse.types';
import { ChampionHotspotOverlay } from 'store/copy.types';
import { isMobileLayout } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

import useHotspotOverlayCopy from './../../HotspotOverlay.copy';
import SecondarySlide, { SecondarySlideRef } from './../SecondarySlide/SecondarySlide';
import useSkillOverlayAssets from './Skill.assets';
import useSkillCopy from './Skill.copy';
import useMotion from './Skill.motion';

import * as Styled from './Skill.styles';

export interface SkillProps { }

const defaultProps: Partial<SkillProps> = {};

const Skill: React.FC<SkillProps> = () => {
  const copy = useSkillCopy();
  const featuredSkill: ChampionHotspotOverlay = copy.featuredSkill;
  const track = copy.track;
  const { getSkillImage } = useSkillOverlayAssets();
  const { currentRibbon, currentClubhouse } = useHotspotOverlayCopy();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const whiteBackgroundRef = useRef<HTMLDivElement>(null);
  const secondarySlideRef = useRef<SecondarySlideRef>(null);

  useMotion({
    wrapperRef,
    whiteBackgroundRef,
    secondarySlideRef
  })

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {isMobileLayout() && <Styled.WhiteBackground ref={whiteBackgroundRef} />}
      <Styled.RibbonWrapper>
        <Ribbon
          copy={currentRibbon}
          alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
          icon={currentClubhouse}
          iconSize={layout.iconSizes[currentClubhouse].ribbon}
        />
      </Styled.RibbonWrapper>
      <SecondarySlide
        ref={secondarySlideRef}
        mainContent={{
          title: featuredSkill.title,
          subtitle: featuredSkill.subtitle,
          body: featuredSkill.body,
          spotifyTrack: track,
          musicIntro: featuredSkill.musicIntro,
        }}
        mediaContent={{
          staticMedia: getSkillImage()
        }}
        type={HotspotTypes.skill}
      />
    </Styled.Wrapper>
  );
};

Skill.defaultProps = defaultProps;

export default Skill;
