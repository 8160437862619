import React, { useImperativeHandle, useRef } from 'react';

import InteractiveVideo, { InteractiveVideoProps } from './InteractiveVideo/InteractiveVideo';
import InteractiveImage, { InteractiveImageProps, InteractiveImageRef } from './InteractiveImage/InteractiveImage';
import { MediaType } from './InteractiveMedia.types';
import * as Styled from './InteractiveMedia.styles';

export interface InteractiveMediaRef {
  showImage: () => gsap.core.Timeline;
  hideImage: () => gsap.core.Timeline;
}

export interface InteractiveMediaProps {
  type?: MediaType;
  videoProps?: InteractiveVideoProps;
  imageProps?: InteractiveImageProps;
}

const defaultProps: Partial<InteractiveMediaProps> = {
  type: MediaType.Video,
  videoProps: null,
  imageProps: null
};

const InteractiveMedia = React.forwardRef<InteractiveMediaRef, InteractiveMediaProps>(
  ({
    type,
    videoProps,
    imageProps
  }: InteractiveMediaProps,
    ref) => {
    const interactiveImageRef = useRef<InteractiveImageRef>(null);

    useImperativeHandle(
      ref,
      (): InteractiveMediaRef => ({
        showImage: () => interactiveImageRef.current.showImage(),
        hideImage: () => interactiveImageRef.current.hideImage()
      })
    )

    return (
      <Styled.Wrapper isVideoType={type === MediaType.Video}>
        {
          type === MediaType.Video
            ? <InteractiveVideo {...videoProps} />
            : <InteractiveImage ref={interactiveImageRef} {...imageProps} />}
      </Styled.Wrapper>
    );
  }
);

InteractiveMedia.displayName = 'InteractiveMedia';
InteractiveMedia.defaultProps = defaultProps;

export default InteractiveMedia;
