import styled from 'styled-components';

import { setTypography, breadcrumbFilledHexagon } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { ReactComponent as HexaRightSideSvg } from 'svgs/buttonHexaRightSide.svg';
import { ReactComponent as HexaLeftSideSvg } from 'svgs/buttonHexaLeftSide.svg';
import { validThemes } from './Breadcrumbs';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
  background-color: ${colors.roti};
  padding: 45rem 80rem;

  ${mediaTablet(`
    padding: 0;
    background-color: transparent;
    pointer-events: none;
  `)}
`;

export const FilledHexagonWrapper = styled.div`
  color: ${colors.roti};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  svg {
    width: 50rem;
  }

  ${mediaDesktop(`
    svg {
      width: 80rem;
    }
  `)}
`;

export const BackingHexagonWrapper = styled.div<{ colorTheme: typeof validThemes[number] }>`
  cursor: pointer;

  svg {
    width: 25rem;
    color: ${({ colorTheme }) =>
    colorTheme === 'dark' ? colors.white : colors.nobel};

    path {
      stroke-width: 20rem;
    }
  }

  ${mediaTablet(`
    svg {
      width: 32rem;
    }
  `)}

  ${mediaDesktop(`
    svg {
      width: 40rem;
    }
  `)}
`;

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;

  &:not(:last-child) {
    margin-right: 16rem;
  }
`;

export const BreadcrumbLabel = styled.p<{ colorTheme: typeof validThemes[number] }>`
  ${({ theme: { locale } }) => setTypography('subtitle2', locale)};
  margin: 0.125em 4rem 0 20rem;
  color: ${({ colorTheme }) =>
    colorTheme === 'dark' ? colors.white : colors.black};
  user-select: none;


  ${mediaDesktop(`
    margin: 0.125em 14rem 0 30rem;
  `)}
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

export const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

export const ScrollLabel = styled.p<{ colorTheme: typeof validThemes[number] }>`
  ${({ theme: { locale } }) => setTypography('subtitle2', locale)};
  margin-right: 20rem;
  color: ${({ colorTheme }) =>
    colorTheme === 'dark' ? colors.white : colors.black};
  user-select: none;

  ${mediaDesktop(`
    margin-right: 40rem;
  `)}
`;

export const Dash = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.roti};
`;

export const DashWrapper = styled.div`
  overflow-x: hidden;
  width: 130rem;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transform: translateY(-50%);

  ${mediaTablet(`
    width: 100rem;
  `)}

  ${mediaDesktop(`
    width: 130rem;
  `)}
`;

// Mobile components
export const MobileBreadcrumbs = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 36rem;
    color: ${colors.white};
  }
`;

export const BreadcrumbNumber = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle3', locale)};
  opacity: 0.75;
  margin-right: 20rem;
`;

export const MobileBreadcrumbLabel = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle3', locale)};
  opacity: 0.75;
  margin-left: 25rem;
`;

export const MobileRight = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 28rem;
  }
`;

export const PrevWrapper = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

export const NextWrapper = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;

  svg {
    transform: rotate(180deg);
  }
`;

export const Prev = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle3', locale)};
  margin-left: 20rem;
`;

export const Next = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle3', locale)};
  margin-right: 20rem;
`;

export const Divider = styled.div`
  width: 2rem;
  height: 24rem;
  background-color: ${colors.white};
  opacity: 0.3;
  margin-left: 24rem;
  margin-right: 24rem;
`;

export const InnerWrapper = styled.div`
  ${breadcrumbFilledHexagon().mobile}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    height: calc(100% - 20%);
    width: initial;
    fill: currentColor;
  }

  ${mediaTablet(`
    ${breadcrumbFilledHexagon().tablet}
  `)}

  ${mediaDesktop(`
    ${breadcrumbFilledHexagon().desktop}
  `)}
`;

export const HexaMiddleDiv = styled.div`
  flex: 0.4;
  height: calc(100% - 20%);
  background: currentColor;
`;

export const HexaLeftSide = styled(HexaLeftSideSvg)`
  position: absolute;
  height: 100%;
  left: 0;
`;

export const HexaRightSide = styled(HexaRightSideSvg)`
  position: absolute;
  height: 100%;
  right: 0;
`;

export const AnimatedTriangle = styled.div<{ colorTheme: typeof validThemes[number] }>`
  position: absolute;
  top: 25%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -25%);
  border-left: 12rem solid transparent;
  border-right: 12rem solid transparent;
  border-top: 22rem solid ${({ colorTheme }) => colorTheme === 'dark' ? colors.blueRibbon : colors.white};

  ${mediaTablet(`
    border-left-width: 10rem;
    border-right-width: 10rem;
    border-top-width: 18rem;
  `)}

  ${mediaDesktop(`
    border-left-width: 12rem;
    border-right-width: 12rem;
    border-top-width: 22rem;
  `)}
`;

export const TriangleWrapper = styled.div`
  ${breadcrumbFilledHexagon().mobile}
  position: absolute;
  left: 0;

  ${mediaTablet(`
    ${breadcrumbFilledHexagon().tablet}
  `)}

  ${mediaDesktop(`
    ${breadcrumbFilledHexagon().desktop}
  `)}
`;
