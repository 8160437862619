import { StorytellingOverlay } from './copy.types';

export enum PlaylistTypes {
  personalized = 'personalized'
}

export interface Track {
  id: string;
  artists: string[];
  artistIds: string[];
  title: string;
  image: string;
  previewUrl: string;
  url: string;
  isCurated?: boolean;
}

export interface PlaylistData {
  id: string;
  url: string;
  tracks: Track[];
}

export interface UnlockConditions {
  favorites?: number;
}

export interface Playlist {
  isUnlocked: boolean;
  unlockConditions: UnlockConditions;
  data: PlaylistData;
}

export interface HotspotTracks {
  storytelling: [
    Record<keyof StorytellingOverlay, Track>,
    Record<keyof StorytellingOverlay, Track>,
    Record<keyof StorytellingOverlay, Track>
  ];
  pro: Track[];
  champion: Track;
  skill: Track;
  easterEgg: Track;
}

export interface TrackInfo {
  id: string;
  previewUrl: string;
}

export interface StorytellingTrackInfo {
  page1: null;
  page2: TrackInfo;
  page3: TrackInfo;
  page4: TrackInfo;
}

export interface HotspotsTrackInfo {
  seoul: {
    storytelling: [StorytellingTrackInfo, StorytellingTrackInfo, StorytellingTrackInfo];
    pro: TrackInfo[];
    champion: TrackInfo;
    skill: TrackInfo;
    easterEgg: TrackInfo;
  };
  berlin: {
    storytelling: [StorytellingTrackInfo, StorytellingTrackInfo, StorytellingTrackInfo];
    pro: TrackInfo[];
    champion: TrackInfo;
    skill: TrackInfo;
    easterEgg: TrackInfo;
  };
  shanghai: {
    storytelling: [StorytellingTrackInfo, StorytellingTrackInfo, StorytellingTrackInfo];
    pro: TrackInfo[];
    champion: TrackInfo;
    skill: TrackInfo;
    easterEgg: TrackInfo;
  }
}
