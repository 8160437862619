import styled from 'styled-components';
import { rgba } from 'polished';

import { hover, setTypography, setVisibility } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

import { PlayerProps } from './Player';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ centered: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.centered ? 'center' : 'flex-start'};
  cursor: pointer;
`;

export const PlayerWrapper = styled.div<{ themeColor: PlayerProps['theme'] }>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${layout.player.height}rem;
  background: ${({ themeColor }) => colors[themeColor]};
  color: ${colors.white};

  ${mediaTablet(`
    height: ${layout.player.tabletHeight}rem;
  `)}

  ${mediaDesktop(`
    height: ${layout.player.height}rem;
  `)}
`;

export const Cover = styled.div<{ hasPreview: boolean }>`
  width: ${layout.player.height}rem;
  height: 100%;
  position: relative;
  ${({ hasPreview }) => hasPreview ? 'cursor: pointer;' : ''}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${mediaTablet(`
    width: ${layout.player.tabletHeight}rem;
  `)}

  ${mediaDesktop(`
    width: ${layout.player.height}rem;
  `)}
`;

export const PlayPauseWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: '';
    width: ${1.75 * layout.playPauseSize}rem;
    height: ${1.75 * layout.playPauseSize}rem;
    top: 50%;
    left: 50%;
    position: absolute;
    border: 4rem solid currentColor;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  ${hover(`
    &:after {
      opacity: ${layout.hoverOpacity};
    }
  `)}

  ${mediaTablet(`
    transform: scale(0.8) translate(-50%, -50%);
  `)}
`;

export const Details = styled.div<{ isCentered: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isCentered }) => isCentered ? 'center' : 'space-between'};
  padding: ${({ isCentered }) => isCentered ? layout.player.padding.centered : layout.player.padding.default}rem;
  width: calc(100% - ${layout.player.height}rem);

  &:not(:last-child) {
    width: calc(100% - ${2 * layout.player.height}rem);

    ${mediaTablet(`
      width: calc(100% - ${2 * layout.player.tabletHeight}rem);
    `)}

    ${mediaDesktop(`
      width: calc(100% - ${2 * layout.player.height}rem);
    `)}
  }

  ${props => mediaTablet(`
    width: calc(100% - ${layout.player.tabletHeight}rem);
    padding: ${props.isCentered ? layout.player.paddingTablet.centered : layout.player.paddingTablet.default}rem;
  `)}

  ${props => mediaDesktop(`
    width: calc(100% - ${layout.player.height}rem);
    padding: ${props.isCentered ? layout.player.padding.centered : layout.player.padding.default}rem;
  `)}
`;

export const Info = styled.div`
  div {
    white-space: nowrap;
    max-width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(1) {
      ${({ theme: { locale } }) => setTypography('leadBody2', locale)};
      margin-bottom: 0.2em;
    }

    &:nth-child(2) {
      ${({ theme: { locale } }) => setTypography('bodySmallBook', locale)};
    }
  }

`;

export const CTA = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  ${({ theme: { locale } }) => setTypography('footer', locale)};

  span {
    margin-right: 20rem;
  }
`;

export const FavoriteIcon = styled.div<{ isFavorited: boolean }>`
  height: 1em;

  svg {
    height: 100%;
    width: auto;
    transition: color 0.2s ease-out, stroke 0.2s ease-out;

    ${({ isFavorited }) => isFavorited ? '' : `
      color: transparent;
      stroke: ${colors.white};
      stroke-width: 3rem;
    `}
  }
`;

export const Icon = styled.div`
  width: ${layout.player.height}rem;
  height: 100%;
  border-left: 1px solid ${rgba(colors.white, 0.25)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    height: 1em;

    svg {
      height: 100%;
      width: auto;
    }
  }

  ${mediaTablet(`
    width: ${layout.player.tabletHeight}rem;

    a {
      height: 1.25em;
    }
  `)}

  ${mediaDesktop(`
    width: ${layout.player.height}rem;

    a {
      height: 1em;
    }
  `)}
`;

export const PremiumPrompt = styled.a<{ color: ColorNames; isVisible: boolean }>`
  position: absolute;
  bottom: -2.25em;
  display: flex;
  align-items: center;
  color: ${({ color }) => colors[color]};
  ${({ theme: { locale } }) => setTypography('label', locale)};
  ${({ isVisible }) => setVisibility(isVisible)}

  ${hover(`
    opacity: ${layout.hoverOpacity};
  `)}
`;
