import styled from 'styled-components';
import { motion } from 'framer-motion';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const BreadcrumbWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
  z-index: 10;

  ${mediaTablet(`
    position: absolute;
    left: 50%;
    bottom: ${layout.headerFooterPadding.tablet}rem;
    width: 50vw;
    padding-left: ${layout.storytellingPadding.tablet}rem;
    padding-right: ${layout.storytellingPadding.tablet}rem;
    pointer-events: none;
  `)}

  ${mediaDesktop(`
    bottom: ${layout.headerFooterPadding.desktop}rem;
    padding-left: ${layout.storytellingPadding.desktop}rem;
    padding-right: ${layout.storytellingPadding.desktop}rem;
  `)}
`;

export const OverlayTitleWrapper = styled(motion.div)`
  position: absolute;
  top: ${layout.headerFooterPadding.tablet}rem;
  left: 50%;
  width: 50vw;
  padding-left: ${layout.storytellingPadding.tablet}rem;
  padding-right: ${layout.storytellingPadding.tablet}rem;
  z-index: 10;

  ${mediaDesktop(`
    top: ${layout.headerFooterPadding.desktop}rem;
    padding-left: ${layout.storytellingPadding.desktop}rem;
    padding-right: ${layout.storytellingPadding.desktop}rem;
  `)}
`;

export const RibbonWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;
