import { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';

import { SoundData, SoundFiles, SOUNDS_DATA, SoundType } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { useAudioStore, useClubhouseStore, useGlobalStore } from 'store';
import { ROUTES } from 'utils/routes';


const useAudioLoops = () => {
  const { isReady } = useGlobalStore((state) => state);
  const { currentLoopId, setCurrentLoopId } = useAudioStore((state) => state);
  const { currentClubhouse, currentRoom, isLoading } = useClubhouseStore((state) => state);
  const router = useRouter();

  const [isMounted, setMounted] = useState<boolean>(false);
  const [hasStarted, setStarted] = useState<boolean>(false);
  const isReadyRef = useRef<boolean>(isReady);

  const start = () => {
    AudioManager.play(SOUNDS_DATA.loopLanding);
    AudioManager.play(SOUNDS_DATA.loopSeoulEntry, { volume: 0 });
    AudioManager.play(SOUNDS_DATA.loopSeoulInterior, { volume: 0 });
    AudioManager.play(SOUNDS_DATA.loopBerlinEntry, { volume: 0 });
    AudioManager.play(SOUNDS_DATA.loopBerlinInterior, { volume: 0 });
    AudioManager.play(SOUNDS_DATA.loopShanghaiEntry, { volume: 0 });
    AudioManager.play(SOUNDS_DATA.loopShanghaiInterior, { volume: 0 });

    setCurrentLoopId(SoundFiles.loopLanding);
    setStarted(true);
  };

  useEffect(() => {
    isReadyRef.current = isReady;
    if (!hasStarted && isReady && AudioManager.isUnlocked) start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  useEffect(() => {
    if (isMounted && !hasStarted) {
      if (router.pathname !== ROUTES.HOME) return;

      const checkUnlockedAudio = () => {
        if (isReadyRef.current) {
          AudioManager.eventManager.off('unlock', checkUnlockedAudio);
          start();
        }
      };

      if (!(isReadyRef.current && AudioManager.isUnlocked))
        AudioManager.eventManager.on('unlock', checkUnlockedAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, router.pathname]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!hasStarted) return;

    let currentLoop: SoundData;

    if (!currentClubhouse || (currentClubhouse && isLoading)) currentLoop = SOUNDS_DATA.loopLanding;
    else {
      if (!currentRoom) {
        if (currentClubhouse === 'seoul') currentLoop = SOUNDS_DATA.loopSeoulEntry;
        if (currentClubhouse === 'berlin') currentLoop = SOUNDS_DATA.loopBerlinEntry;
        if (currentClubhouse === 'shanghai') currentLoop = SOUNDS_DATA.loopShanghaiEntry;
      } else {
        if (currentClubhouse === 'seoul') currentLoop = SOUNDS_DATA.loopSeoulInterior;
        if (currentClubhouse === 'berlin') currentLoop = SOUNDS_DATA.loopBerlinInterior;
        if (currentClubhouse === 'shanghai') currentLoop = SOUNDS_DATA.loopShanghaiInterior;
      }
    }

    if (currentLoop && currentLoop.id !== currentLoopId) {
      AudioManager.setVolume(currentLoop, null, 0.6);
      setCurrentLoopId(currentLoop.id as SoundFiles);

      // Fade out all other loops
      Object.values(SOUNDS_DATA).forEach((loop) => {
        if (loop.type === SoundType.BGS && loop.id !== currentLoop.id) AudioManager.setVolume(loop, 0, 0.6);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClubhouse, currentRoom, isLoading, hasStarted, currentLoopId]);
};

export default useAudioLoops;
