import { createGlobalStyle, css } from 'styled-components';

import { setTypography, fullscreen } from './mixins';
import resetCSS from './reset';
import { setScalableFontSize } from './responsive';
import { colors } from './theme';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
  }

  ::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  #__next {
    ${fullscreen('fixed')}
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  html {
    ${setScalableFontSize()}
    position: absolute;
    overflow-x: hidden;
  }

  body {
    background: ${colors.black};
    color: ${colors.white};
    ${({ theme: { locale } }) => setTypography('body', locale)};
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  svg {
    outline: none;
    display: block;
    user-select: none;
  }
  img {
    user-select: none;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  #onetrust-banner-sdk {
    display: none !important;
    outline: none !important;
    background-color: ${colors.roti} !important;

    h3, p, .ot-link-btn, .cookie-setting-link, a {
      color: ${colors.white} !important;
    }
    .ot-close-icon {
      filter: saturate(0) brightness(100);
    }

    button#onetrust-pc-btn-handler.cookie-setting-link {
      background-color: transparent;
    }

    button {
      outline: none !important;
    }

    h3#onetrust-policy-title {
      margin-bottom: 1em !important;
      display: block;
    }
  }

  #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
    color: inherit !important;
    border: 0 !important;
    height: auto !important;
    white-space: inherit !important;
    word-wrap: inherit !important;
    padding: 0 !important;
    transition: inherit !important;

    &:hover, &:active {
      background-color: transparent !important;
    }
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
`;
