import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import BlackOverlay from 'components/BlackOverlay/BlackOverlay';
import AnthemVideoOverlay from 'components/overlays/AnthemVideoOverlay/AnthemVideoOverlay';
import AuthPromptOverlay from 'components/overlays/AuthPromptOverlay/AuthPromptOverlay';
import ClubhouseLoadingOverlay from 'components/overlays/ClubhouseLoadingOverlay/ClubhouseLoadingOverlay';
import HotspotOverlay from 'components/overlays/HotspotOverlay/HotspotOverlay';
import PlaylistsOverlay from 'components/overlays/PlaylistsOverlay/PlaylistsOverlay';
import TransitionBars from 'components/TransitionBars/TransitionBars';
import { useClubhouseStore, useGlobalStore, usePlaylistsStore } from 'store';
import { ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './MainLayout.styles';
import { isMobileLayout } from 'utils/styles/responsive';

interface MainLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const {
    currentHotspot,
    hasBeenOnboarded,
    isHotspotOverlayVisible,
    setHotspotOverlayVisible,
    onboardingStep,
    currentClubhouse,
  } = useClubhouseStore(state => state);
  const { isPlaylistsOverlayVisible } = usePlaylistsStore(state => state);
  const { isAuthPromptVisible, isAnthemVideoVisible, toggleAnthemVideoVisible } = useGlobalStore(state => state);
  const router = useRouter();

  useEffect(() => {
    if (currentHotspot && !isHotspotOverlayVisible)
      setHotspotOverlayVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHotspot]);

  return (
    <Styled.Wrapper>
      <Header
        background={
          router.pathname === ROUTES.HOME
            ? ColorNames.blueRibbon
            : ColorNames.black
        }
        toggleAnthemVideoVisible={toggleAnthemVideoVisible}
      />
      {children}
      <Footer toggleAnthemVideoVisible={toggleAnthemVideoVisible} />

      <HotspotOverlay isVisible={isHotspotOverlayVisible} />
      <ClubhouseLoadingOverlay
        clubhouse={currentClubhouse}
        isVisible={!!currentClubhouse}
      />
      <AnthemVideoOverlay
        isVisible={isAnthemVideoVisible}
        toggleAnthemVideoVisible={toggleAnthemVideoVisible}
      />
      <PlaylistsOverlay isVisible={isPlaylistsOverlayVisible} />
      <AuthPromptOverlay
        isVisible={isAuthPromptVisible}
        type={hasBeenOnboarded || !!onboardingStep ? 'personalized' : 'connect'}
      />
      <TransitionBars />
      {!isMobileLayout() && <BlackOverlay />}
    </Styled.Wrapper>
  );
};

export default React.memo(MainLayout);
