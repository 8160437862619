import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ColorNames, colors, layout } from 'utils/styles/theme';
import { fullscreen, interactiveMediaMessage, interactiveMediaMessageWrapper } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export interface ImageProps {
  image: string;
}

export const Wrapper = styled.div<{ background: string }>`
  ${fullscreen()}
  cursor: pointer;
  overflow: hidden;
  pointer-events: auto;
  background-image: url(${props => props.background});
  background-size: cover;

  ${mediaTablet(`
    pointer-events: none;
  `)}
`;

export const ImageWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
`;

export const MainImageWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
`;

export interface ImageInnerProps {
  scale: number;
}
export const Image = styled.img<ImageInnerProps>`
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(${props => props.scale});
  transform-origin: 50% 100%;
  object-fit: contain;
  object-position: bottom;
  user-select: none;
  pointer-events: none;
`;

export const MainImage = styled.img<ImageInnerProps>`
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(${props => props.scale});
  transform-origin: 50% 100%;
  object-fit: contain;
  object-position: bottom;
  user-select: none;
  pointer-events: none;
`;

export const Message = styled.p`
  ${interactiveMediaMessage()}
`;

export const MessageWrapper = styled(motion.div) <{ color: ColorNames }>`
  display: flex;
  align-items: center;
  z-index: 2;
  color: ${(props) => colors[props.color]};
  user-select: none;

  ${interactiveMediaMessageWrapper(`${layout.headerFooterPadding.desktop}rem`)}

  svg {
    width: 100rem;
  }
`;
