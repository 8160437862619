import React, { useRef } from 'react';
import { camelCase } from 'lodash';

import Ribbon from 'components/Ribbon/Ribbon';
import { HotspotTypes } from 'store/clubhouse.types';
import { ChampionHotspotOverlay } from 'store/copy.types';
import { HOTSPOT_ASSETS } from 'utils/config';
import { isMobileLayout } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

import useHotspotOverlayCopy from './../../HotspotOverlay.copy';
import SecondarySlide, { SecondarySlideRef } from './../SecondarySlide/SecondarySlide';
import useChampionOverlayAssets from './Champion.assets';
import useChampionCopy from './Champion.copy';
import useMotion from './Champion.motion';

import * as Styled from './Champion.styles';

export interface ChampionProps { }

const defaultProps: Partial<ChampionProps> = {};

const Champion: React.FC<ChampionProps> = () => {
  const copy = useChampionCopy();
  const featuredChampion: ChampionHotspotOverlay = copy.featuredChampion;
  const track = copy.track;
  const { getChampImage } = useChampionOverlayAssets();
  const { currentRibbon, currentClubhouse } = useHotspotOverlayCopy();
  const downloadable = HOTSPOT_ASSETS.DOWNLOADABLES.champion[camelCase(featuredChampion.downloadId)];

  const wrapperRef = useRef<HTMLDivElement>(null);
  const whiteBackgroundRef = useRef<HTMLDivElement>(null);
  const secondarySlideRef = useRef<SecondarySlideRef>(null);

  useMotion({
    wrapperRef,
    whiteBackgroundRef,
    secondarySlideRef
  })

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {isMobileLayout() && <Styled.WhiteBackground ref={whiteBackgroundRef} />}
      <Styled.RibbonWrapper>
        <Ribbon
          copy={currentRibbon}
          alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
          icon={currentClubhouse}
          iconSize={layout.iconSizes[currentClubhouse].ribbon}
        />
      </Styled.RibbonWrapper>
      <SecondarySlide
        ref={secondarySlideRef}
        mainContent={{
          title: featuredChampion.title,
          subtitle: featuredChampion.subtitle,
          tagline: featuredChampion.tagline,
          taglineBody: featuredChampion.body,
          triviaIntro: featuredChampion.triviaIntro,
          trivia: featuredChampion.trivia,
          musicIntro: featuredChampion.musicIntro,
          spotifyTrack: track
        }}
        mediaContent={{
          staticMedia: getChampImage(),
          downloadable
        }}
        type={HotspotTypes.champion}
      />
    </Styled.Wrapper>
  );
};

Champion.defaultProps = defaultProps;

export default Champion;
