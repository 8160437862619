import { RefObject } from 'react';
import gsap from 'gsap';


const EASING = 'expo.inOut';
const MASKING_DURATION = 1;
const SLIDING_DURATION = 1.4;

export interface MotionRefs {
  labelWrapperRef: RefObject<HTMLDivElement>;
  labelRef: RefObject<HTMLDivElement>;
  playerWrapperRef: RefObject<HTMLDivElement>;
  playerRef: RefObject<HTMLDivElement>;
  tooltipRef: (ref: HTMLElement) => void;
}

export interface MotionProps {
  hideTooltip: () => void;
}

export const maskIn = (refs: Partial<MotionRefs>) => {
  const { labelRef, labelWrapperRef, playerRef, playerWrapperRef } = refs;

  const timeline = gsap.timeline();

  timeline
    .set([labelWrapperRef.current, playerWrapperRef.current], { x: 0, autoAlpha: 1 })
    .fromTo(
      [labelRef.current, playerRef.current],
      { y: '180%' },
      { y: '0%', duration: MASKING_DURATION, ease: EASING }
    );

  return timeline;
};

export const maskOut = (refs: Partial<MotionRefs>, props: MotionProps) => {
  const { labelRef, playerRef } = refs;
  const { hideTooltip } = props;

  const timeline = gsap.timeline({
    onComplete: () => hideTooltip()
  });

  timeline
    .fromTo(
      [labelRef.current, playerRef.current],
      { y: '0%' },
      { y: '-180%', duration: MASKING_DURATION, ease: EASING }
    );

  return timeline;
};

export const slideIn = (refs: Partial<MotionRefs>) => {
  const { labelRef, labelWrapperRef, playerRef, playerWrapperRef } = refs;

  const timeline = gsap.timeline();

  timeline
    .set([labelRef.current, playerRef.current], { y: 0 })
    .fromTo(
      [labelWrapperRef.current, playerWrapperRef.current],
      { x: '100%', autoAlpha: 0 },
      { x: '0%', autoAlpha: 1, duration: SLIDING_DURATION, ease: EASING, stagger: 0.1 }
    );

  return timeline;
};

export const slideOut = (refs: Partial<MotionRefs>, props: MotionProps) => {
  const { labelRef, labelWrapperRef, playerRef, playerWrapperRef } = refs;
  const { hideTooltip } = props;

  const timeline = gsap.timeline({
    onComplete: () => hideTooltip()
  });

  timeline
    .set([labelRef.current, playerRef.current], { y: 0 })
    .fromTo(
      [labelWrapperRef.current, playerWrapperRef.current],
      { x: '0%', autoAlpha: 1 },
      { x: '-100%', autoAlpha: 0, duration: SLIDING_DURATION, ease: EASING, stagger: 0.1 }
    )

  return timeline;
};
