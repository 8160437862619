import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullscreen, setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ background: string }>`
  position: relative;
  width: 100%;
  height: ${setVh(100)};
  background-color: ${colors.white};
  cursor: pointer;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
`;

export const MainImageWrapper = styled(motion.div)`
  ${fullscreen()}
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const RevealedImageWrapper = styled(motion.div)`
  ${fullscreen()}
  top: 0;
  left: -180%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const Image = styled.img`
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(0.95);
  transform-origin: 50% 100%;
  object-fit: contain;
  object-position: bottom;
  user-select: none;
  pointer-events: none;
`;
export const MainImage = styled.img`
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(0.95);
  transform-origin: 50% 100%;
  object-fit: contain;
  object-position: bottom;
  user-select: none;
  pointer-events: none;
`;

export const Downloadable = styled.a`
  position: absolute;
  top: 60rem;
  left: 60rem;
  margin-right: 40rem;
  z-index: 10;

  ${mediaTablet(`
    top: ${layout.headerFooterPadding.tablet}rem;
    left: ${layout.headerFooterPadding.tablet}rem;
    transform: translateY(-25%);
  `)}

  ${mediaDesktop(`
    top: ${layout.headerFooterPadding.desktop}rem;
    left: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;

export const InteractiveMediaMessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 60rem;
  transform: translateX(-50%);
  width: fit-content;
  z-index: 10;

  ${mediaTablet(`
    bottom: ${layout.headerFooterPadding.tablet - 12}rem;
  `)}

  ${mediaDesktop(`
    bottom: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;
