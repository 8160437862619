import { useCopyStore, useClubhouseStore, usePlaylistsStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { HotspotTypes } from 'store/clubhouse.types';
import { placeholderHotspotTracks } from 'store/playlists.data';

const useStorytellingCopy = () => {
  let currentClubhouse = useClubhouseStore(state => state.currentClubhouse);
  let currentHotspot = useClubhouseStore(state => state.currentHotspot);

  // Storybook fallback
  if (isStorybook()) {
    currentClubhouse = 'seoul';
    currentHotspot = {
      type: HotspotTypes.storytelling1,
      label: '',
      position: { x: 0, y: 0 },
      roomNb: 0
    };
  }
  const { hotspotTracks } = usePlaylistsStore();

  const clubhouseCopy = useCopyStore(state => state.copy.clubhouses[currentClubhouse]);
  if (!clubhouseCopy) return null;

  const storytellingIndex = currentHotspot.type.match(/\d+/) ? parseInt(currentHotspot.type.match(/\d+/)[0]) - 1 : 0;
  const storyCopy = clubhouseCopy.hotspotsOverlays.storytelling[storytellingIndex];
  if (!storyCopy) return;

  const tracks = isStorybook() ?
    placeholderHotspotTracks.storytelling[storytellingIndex] :
    hotspotTracks[currentClubhouse].storytelling[storytellingIndex];

  const stories = Object.keys(storyCopy).map(page => storyCopy[page]);

  return {
    stories,
    tracks,
    currentClubhouse,
    storytellingIndex
  };
};

export default useStorytellingCopy;
