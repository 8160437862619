import styled from 'styled-components';
import { fullscreen, setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  ${fullscreen()}
  height: auto;
  position: relative;
  background-color: ${colors.white};
  opacity: 0;
  visibility: hidden;

  ${mediaTablet(`
    height: ${setVh(100)};
    overflow: hidden;
    transform: translate(100%, 0);
    opacity: 1;
    visibility: visible;
  `)}
`;

export const OverlayTitleWrapper = styled.div`
  position: fixed;
  left: 310rem;
  top: ${layout.headerFooterPadding.desktop}rem;
  width: 50vw;
  padding-right: 260rem;
  pointer-events: none;
  z-index: 3;
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const WhiteBackground = styled.div`
  ${fullscreen()}
  width: 100vw;
  height: ${setVh(100)};
  background-color: ${colors.white};
  opacity: 0;
  visibility: hidden;
`;
