import { useState, useRef, useEffect, RefObject } from 'react';
import gsap from 'gsap'

import { InteractiveImagesRef } from 'components/InteractiveImages/InteractiveImages';
import { BreadcrumbsRef } from 'components/Breadcrumbs/Breadcrumbs';
import { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import { SecondarySlideRef } from '../SecondarySlide/SecondarySlide';
import { isMobileLayout } from 'utils/styles/responsive';
import { SECONDARY_OVERLAY_ANIMATE_IN_DELAY } from 'utils/config';

export interface MotionRef {
  whiteBackgroundRef?: RefObject<HTMLDivElement>;
  contentWrapperRef?: RefObject<HTMLDivElement>;
  secondarySlideRefs?: RefObject<SecondarySlideRef[]>;
  mediaContentWrapperRef?: RefObject<HTMLDivElement>;
  overlayTitleRef?: RefObject<OverlayTitleRef>;
  breadcrumbsRef?: RefObject<BreadcrumbsRef>;
  breadcrumbWrapperRef?: RefObject<HTMLDivElement>;
  interactiveImagesRef?: RefObject<InteractiveImagesRef>;
}

export interface MotionProps {
  slideIndex?: number;
}

const useMotion = (refs: MotionRef, props: MotionProps) => {
  const {
    whiteBackgroundRef,
    contentWrapperRef,
    secondarySlideRefs,
    mediaContentWrapperRef,
    overlayTitleRef,
    breadcrumbsRef,
    breadcrumbWrapperRef,
    interactiveImagesRef
  } = refs;
  const { slideIndex } = props;

  const [contentIndex, setContentIndex] = useState<number>(0);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const previousContentIndex = useRef<number>(0);

  const animateInTl = useRef<gsap.core.Timeline>(null);
  const transitionInTl = useRef<gsap.core.Timeline>(null);
  const transitionOutTl = useRef<gsap.core.Timeline>(null);

  const transitionIn = () => {
    if (transitionInTl.current) transitionInTl.current.kill();

    transitionInTl.current = gsap.timeline({
      onComplete: () => transitionInTl.current = null
    });

    secondarySlideRefs.current[contentIndex] && transitionInTl.current
      .add(secondarySlideRefs.current[contentIndex].transitionIn(), 0);
  };

  const transitionOut = () => {
    if (transitionOutTl.current) transitionOutTl.current.kill();

    transitionOutTl.current = gsap.timeline({
      onComplete: () => transitionOutTl.current = null
    });

    previousContentIndex.current = contentIndex;

    secondarySlideRefs.current[previousContentIndex.current] &&
      transitionOutTl.current
        .add(secondarySlideRefs.current[previousContentIndex.current].transitionOut(), 0);

    transitionOutTl.current
      .add(() => setContentIndex(slideIndex));
  };

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    const slidingFromProps = isMobileLayout() ? { y: '-100%', } : { x: '100%' };
    const slidingToProps = isMobileLayout() ? { y: 0 } : { x: 0 };
    const slidingRefs = isMobileLayout() ? [contentWrapperRef.current, whiteBackgroundRef.current] : contentWrapperRef.current;

    animateInTl.current
      .fromTo(
        slidingRefs,
        { ...slidingFromProps, autoAlpha: 0 },
        { ...slidingToProps, autoAlpha: 1, duration: 1.2, ease: 'expo.inOut' }
      )

    if (mediaContentWrapperRef.current) {
      animateInTl.current
        .fromTo(
          mediaContentWrapperRef.current,
          { clipPath: 'inset(0% 0% 0% 100%)' },
          { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.9, ease: 'expo.inOut' },
          1
        );
    }

    if (interactiveImagesRef.current) {
      animateInTl.current
        .add(interactiveImagesRef.current.animateIn(), 1);
    }

    overlayTitleRef.current &&
      animateInTl.current
        .add(overlayTitleRef.current.show(), 0.5)

    if (isMobileLayout()) {
      breadcrumbsRef.current &&
        animateInTl.current
          .fromTo(
            breadcrumbWrapperRef.current,
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 0.8, ease: 'expo.inOut' },
            0.5
          )
    } else {
      breadcrumbsRef.current &&
        animateInTl.current
          .add(breadcrumbsRef.current.show(), 0.5)
    }

    animateInTl.current
      .add(secondarySlideRefs.current[0].animateIn(), 0);
  };

  useEffect(() => {
    if (isInitial) {
      setTimeout(() => animateIn(), SECONDARY_OVERLAY_ANIMATE_IN_DELAY);
      setIsInitial(false);
    } else {
      transitionOut();
    }

    // eslint-disable-next-line
  }, [slideIndex]);

  useEffect(() => {
    !isInitial && transitionIn();

    // eslint-disable-next-line
  }, [contentIndex]);

  useEffect(() => {
    return () => {
      if (animateInTl.current) animateInTl.current.kill();
      if (transitionInTl.current) transitionInTl.current.kill();
      if (transitionOutTl.current) transitionOutTl.current.kill();
    };

    // eslint-disable-next-line
  }, []);
};

export default useMotion;
