import { useState, useRef, useEffect, useMemo, RefObject } from 'react';

import { useHotspotOverlayStore } from 'store';
import { isTouchDevice } from 'u9/utils/platform';
import WheelIndicator from 'wheel-indicator'

export const TRANSITION_COOLDOWN = 1500;
const DRAG_THRESHOLD = 100;
export interface ScrollLockGestureProps {
  targetRef: RefObject<HTMLDivElement>;
  slidesLength: number;
}

const useGesture = ({ targetRef, slidesLength }: ScrollLockGestureProps) => {
  const { slideIndex, setSlideIndex, setPreviousSlideIndex, setTransitioning, isTransitioning } = useHotspotOverlayStore();
  const [touched, setTouched] = useState(false);
  const [dragged, setDragged] = useState(false);
  const indicator = useRef(null);
  const dragTimeout = useRef(null);
  const dragEnabled = useRef(true);
  const dragAmount = useRef(0);
  const dragStart = useRef(0);
  const isTouchable = useMemo(() => isTouchDevice(), []);

  const handleTouchStart = (event) => {
    setTouched(true);
    dragStart.current = event.changedTouches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    setTouched(false);
    dragAmount.current = event.changedTouches[0].clientX - dragStart.current;
    setDragged(Math.abs(dragAmount.current) >= DRAG_THRESHOLD);
  };

  useEffect(() => {
    if (dragEnabled.current) {
      if (dragged && !touched && !isTransitioning) {
        setTransitioning(true);
        dragEnabled.current = false;
        const toLeft = isTouchable ? dragAmount.current > 0 : dragAmount.current < 0;
        const toRight = isTouchable ? dragAmount.current < 0 : dragAmount.current > 0;
        if (toLeft && !(slideIndex <= 0)) {
          setPreviousSlideIndex(slideIndex);
          slideIndex > 0 && setSlideIndex(slideIndex - 1);
        } else if (toRight && !(slideIndex >= slidesLength - 1)) {
          setPreviousSlideIndex(slideIndex);
          slideIndex < slidesLength - 1 && setSlideIndex(slideIndex + 1);
        }

        if (dragTimeout.current) clearTimeout(dragTimeout.current);
        dragTimeout.current = setTimeout(() => {
          setTransitioning(false);
          setDragged(false);
          dragTimeout.current = null;
          dragEnabled.current = true;
        }, TRANSITION_COOLDOWN);
      }
    }

    // eslint-disable-next-line
  }, [dragged, touched, slideIndex, setSlideIndex, slidesLength, isTouchable, isTransitioning]);

  useEffect(() => {
    const target = targetRef.current;

    if (target) {
      target.addEventListener('touchstart', handleTouchStart);
      target.addEventListener('touchend', handleTouchEnd);
      indicator.current = new WheelIndicator({
        elem: target,
        callback: function (e) {
          dragAmount.current = e.direction === 'up' ? -1 : 1;
          setDragged(true);
        }
      });
    }

    return () => {
      setTransitioning(false);
      if (target) {
        target.removeEventListener('touchstart', handleTouchStart);
        target.removeEventListener('touchend', handleTouchEnd);
        indicator.current.destroy();
      }

      if (dragTimeout.current) clearTimeout(dragTimeout.current);
    };

    // eslint-disable-next-line
  }, [targetRef]);
};

export default useGesture;
