import React, { useRef, useImperativeHandle, useMemo } from 'react';

import useMotion from './SplitContent.motion';
import * as Styled from './SplitContent.styles';

export enum SplitContentFontScale {
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading6 = 'heading6',
  LeadBody = 'leadBody',
  LeadBodyLighter = 'leadBodyLighter',
  LeadBody3 = 'leadBody3',
  Subtitle2 = 'subtitle2',
  Body = 'body',
  Caption = 'caption',
}

export enum SplitContentAlignment {
  Left = 'Left',
  Center = 'Center'
}

export enum SplitContentMotionType {
  SlidingGroup = 'SlidingGroup',
  SlidingLines = 'SlidingLines',
  Masking = 'Masking',
}

export interface SplitContentRef {
  maskIn: () => gsap.core.Timeline;
  maskOut: () => gsap.core.Timeline;
  slideInGroup: () => gsap.core.Timeline;
  slideOutGroup: () => gsap.core.Timeline;
  slideInLines: () => gsap.core.Timeline;
  slideOutLines: () => gsap.core.Timeline;
}

export interface SplitContentProps {
  copy?: string;
  fontScale?: SplitContentFontScale;
  alignment?: SplitContentAlignment;
  motionType?: SplitContentMotionType;
}

const defaultProps: Partial<SplitContentProps> = {
  copy: '',
  fontScale: SplitContentFontScale.LeadBody,
  alignment: SplitContentAlignment.Left,
  motionType: SplitContentMotionType.Masking
};

const SplitContent = React.forwardRef<SplitContentRef, SplitContentProps>(
  (
    props: SplitContentProps,
    ref
  ) => {
    const { copy, fontScale, alignment, motionType } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { maskIn, maskOut, slideInGroup, slideOutGroup, slideInLines, slideOutLines } = useMotion(
      { wrapperRef }
    );

    useImperativeHandle(
      ref,
      (): SplitContentRef => ({
        maskIn,
        maskOut,
        slideInGroup,
        slideOutGroup,
        slideInLines,
        slideOutLines
      })
    );

    const copySplitLines = useMemo(() => copy.split(/<br\s*[/]?>/gi), [copy]);

    // if text has <br> tags, split them into separate lines.
    return (
      copySplitLines.length > 1
        ? <Styled.Wrapper
          ref={wrapperRef}
          fontScale={fontScale}
          alignment={alignment}
          motionType={motionType}
        >
          {
            copySplitLines.map((line, index) => (
              <p key={`SplitLine-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
            ))
          }
        </Styled.Wrapper>
        : <Styled.Wrapper
          ref={wrapperRef}
          fontScale={fontScale}
          alignment={alignment}
          motionType={motionType}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
    );
  }
);

SplitContent.displayName = 'SplitContent';
SplitContent.defaultProps = defaultProps;

export default SplitContent;
