import { useEffect, useState, RefObject } from 'react';
import gsap from 'gsap';

import { splitCopy } from './SplitContent.utils';
import { maskIn, maskOut, slideInGroup, slideOutGroup, slideInLines, slideOutLines } from './SplitContent.motion.timelines';

export interface MotionRefs {
  wrapperRef?: RefObject<HTMLDivElement>;
}

const useMotion = (refs: MotionRefs) => {
  const { wrapperRef } = refs;
  const [splitElements, setSplitElements] = useState<Element[]>([]);

  useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        setSplitElements(splitCopy(wrapperRef.current).lines);
        gsap.set(wrapperRef.current, { opacity: 1 });
      }, 200);
    }
  }, [wrapperRef]);

  return {
    maskIn: () => maskIn(splitElements),
    maskOut: () => maskOut(splitElements),
    slideInGroup: () => slideInGroup(splitElements),
    slideOutGroup: () => slideOutGroup(splitElements),
    slideInLines: () => slideInLines(splitElements),
    slideOutLines: () => slideOutLines(splitElements),
  }
};

export default useMotion;
