import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Wrapper as ButtonCloseWrapper } from 'components/ButtonClose/ButtonClose.styles';
import { colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { setVh, setTypography, introContent, fullscreen } from 'utils/styles/mixins';

export interface ImageWrapperProps {
  background: string;
}

export const Wrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: ${layout.zIndex.overlays};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;

  ${mediaTablet(`
    flex-direction: row;
    height: ${setVh(100)};
    overflow: hidden;
  `)}

  ${ButtonCloseWrapper} {
    transform: translateY(-25%);

    ${mediaTablet(`
      transform: none;
    `)}
  }
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: ${setVh(80)};
  transform: translateY(-100%);
  z-index: 2;
  width: 100%;
  overflow: hidden;

  ${mediaTablet(`
    left: 50%;
    top: unset;
    right: unset;
    transform: translateX(-50%);
    width: auto;
  `)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)};
  height: 1.5em;
  img {
    transform: translateZ(0) translateY(25%);
    height: 100%;
    margin-right: 20rem;
  }
  ${({ theme: { locale } }) => mediaTablet(`
    ${setTypography('subtitle2', locale)};
  `)}
`;

export const ContentLeft = styled.div`
  ${introContent('start')};
  height: ${setVh(80)};
  padding-top: ${layout.headerFooterPadding.mobile}rem;
  flex-shrink: 0;
  overflow-x: hidden;

  ${mediaTablet(`
    height: ${setVh(100)};
  `)}
`;

export const ContentRight = styled.div`
  position: relative;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${layout.headerFooterPadding.mobile}rem;
  flex-shrink: 0;

  ${mediaTablet(`
    overflow-y: auto;
    width: 50vw;
    justify-content: space-between;
    padding: ${layout.overlayContentPadding.tablet.h + 40}rem ${layout.overlayContentPadding.tablet.w}rem ${layout.overlayContentPadding.tablet.h}rem;
    padding-left: ${layout.overlayContentPadding.tablet.w + (layout.ribbon.width.desktop / 2)}rem;
  `)}

  ${mediaDesktop(`
    padding: ${layout.overlayContentPadding.desktop.h + 40}rem ${layout.overlayContentPadding.desktop.w}rem ${layout.overlayContentPadding.desktop.h}rem;
    padding-left: ${layout.overlayContentPadding.desktop.w + (layout.ribbon.width.desktop / 2)}rem;
  `)}
`

export const ContentInner = styled.div``;

export const ButtonWrapper = styled.div`
  margin-top: 100rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${layout.zIndex.overlays};
  > button:not(:last-child) {
    margin-bottom: 30rem;
  }
  ${mediaTablet(`
    align-items: center;
    flex-direction: row;
    > button:not(:last-child) {
      margin-right: 50rem;
      margin-bottom: 0;
    }
  `)}

`;
export const HeadingWrapper = styled.div`
  margin-top: 120rem;
  width: 75%;
  ${mediaTablet(`
    margin-top: 0;
    width: unset;
  `)};
  span {
    color: ${colors.roti};
  }
`;

export const BodyWrapper = styled.div`
  margin-top: 80rem;
  ${mediaTablet(`
    width: 75%;
  `)};
`;

export const TooltipWrapper = styled.div`
  position: fixed;
  padding-top: ${1 * layout.tooltipSize}rem;
  z-index: ${layout.zIndex.overlays};

  ${mediaTablet(`
    transform: translate(-50%, -100%);
    padding-bottom: ${2 * layout.tooltipSize}rem;
  `)}
`;

export const BlackBackground = styled.div`
  ${fullscreen('fixed')}
  background-color: ${colors.black};
  width: 100vw;
  height: ${setVh(100)};
  opacity: 0;
  visibility: hidden;
  z-index: 12;
  pointer-events: none;
`;
