import { useRef, useEffect, RefObject, useState } from 'react';
import gsap from 'gsap';

import { ButtonRef } from 'components/Button/Button';
import { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';

export interface MotionRefs {
  imagesWrapperRefs?: RefObject<HTMLDivElement[]>;
  downloadableRef?: RefObject<HTMLAnchorElement>;
  interactiveMediaMessageRef?: RefObject<InteractiveMediaMessageRef>;
  interactiveMediaMessageWrapperRef?: RefObject<HTMLDivElement>;
  downloadButtonRef?: RefObject<ButtonRef>;
}

export interface MotionProps {
  held: boolean;
  currentIndex: number;
  previousIndex: number;
}

const useMotion = (refs: MotionRefs, props: MotionProps) => {
  const [isInitial, setIsInitial] = useState(true);

  const animateInTl = useRef<gsap.core.Timeline>(null);
  const slideInTl = useRef<gsap.core.Timeline>(null);
  const slideOutTl = useRef<gsap.core.Timeline>(null);
  const fadeInTl = useRef<gsap.core.Timeline>(null);
  const fadeOutTl = useRef<gsap.core.Timeline>(null);

  const {
    imagesWrapperRefs,
    downloadableRef,
    interactiveMediaMessageRef,
    interactiveMediaMessageWrapperRef,
    downloadButtonRef
  } = refs;
  const { held, currentIndex, previousIndex } = props;

  const slideInImage = () => {
    if (slideInTl.current) slideInTl.current.kill();

    slideInTl.current = gsap.timeline({
      onComplete: () => slideInTl.current = null
    });

    slideInTl.current
      .fromTo(
        imagesWrapperRefs.current[currentIndex],
        { left: 0 },
        { left: '180%', duration: 1, ease: 'power4.inOut' }
      )
      .fromTo(
        interactiveMediaMessageWrapperRef.current,
        { opacity: 1 },
        { opacity: 0, duration: 0.5, ease: 'power4.inOut' },
        0
      )
  };

  const slideOutImage = () => {
    if (slideOutTl.current) slideOutTl.current.kill();

    slideOutTl.current = gsap.timeline({
      onComplete: () => slideOutTl.current = null
    });

    slideOutTl.current
      .fromTo(
        imagesWrapperRefs.current[currentIndex],
        { left: '180%' },
        { left: 0, duration: 1, ease: 'power4.inOut' }
      )
      .fromTo(
        interactiveMediaMessageWrapperRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
        0
      )
  };

  const fadeInImage = (imageIndex = 0) => {
    if (fadeInTl.current) fadeInTl.current.kill();

    fadeInTl.current = gsap.timeline({
      delay: 1,
      onComplete: () => fadeInTl.current = null
    });

    fadeInTl.current
      .fromTo(
        imagesWrapperRefs.current[imageIndex],
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1, ease: 'power4.inOut' }
      );

    if (imageIndex === 0) {
      fadeInTl.current
        .fromTo(
          downloadableRef.current,
          { autoAlpha: 0 },
          { autoAlpha: 1, duration: 1, ease: 'power4.inOut' },
          0
        );
    }
  };

  const fadeOutImage = (imageIndex = 0) => {
    if (fadeOutTl.current) fadeOutTl.current.kill();

    fadeOutTl.current = gsap.timeline({
      delay: 1,
      onComplete: () => fadeOutTl.current = null
    });

    fadeOutTl.current
      .fromTo(
        imagesWrapperRefs.current[imageIndex],
        { autoAlpha: 1 },
        { autoAlpha: 0, duration: 1, ease: 'power4.inOut' }
      );

    if (imageIndex === 0) {
      fadeOutTl.current
        .fromTo(
          downloadableRef.current,
          { autoAlpha: 1 },
          { autoAlpha: 0, duration: 1, ease: 'power4.inOut' },
          0
        );
    }
  };

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    animateInTl.current
      .fromTo(
        imagesWrapperRefs.current[0],
        { autoAlpha: 0, scale: 1.4 },
        { autoAlpha: 1, scale: 1, duration: 1, ease: 'expo.inOut' },
        0
      )
      .add(downloadButtonRef.current.show(), 0.5)
      .add(interactiveMediaMessageRef.current.show(), 0.5)

    return animateInTl.current;
  };

  useEffect(() => {
    if (held) {
      slideInImage();
    } else {
      !isInitial && slideOutImage();
    }
    // eslint-disable-next-line
  }, [held]);

  useEffect(() => {
    setIsInitial(false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isInitial) {
      fadeOutImage(previousIndex);
      fadeInImage(currentIndex);
    }
    // eslint-disable-next-line
  }, [currentIndex, previousIndex]);

  useEffect(() => {
    return () => {
      if (animateInTl.current) animateInTl.current.kill();
      if (slideInTl.current) slideInTl.current.kill();
      if (slideOutTl.current) slideOutTl.current.kill();
      if (fadeInTl.current) fadeInTl.current.kill();
      if (fadeOutTl.current) fadeOutTl.current.kill();
    }
  }, []);

  return {
    animateIn
  }
};

export default useMotion;
