import React, { forwardRef, useImperativeHandle } from 'react';

import backgroundTexture from 'assets/images/ui/earthPattern.png';
import Billboard from 'components/Billboard/Billboard';
import Ribbon, { validIcons } from 'components/Ribbon/Ribbon';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './DuelingBillboards.styles';
import PreloaderService from 'u9/services/preloader.service';
import { useMotion } from './DuelingBillboards.motion';

export interface DuelingBillboardsProps {
  topCopy: string;
  ribbonCopy: string;
  ribbonMessage?: string;
  bottomCopy: string;
  ribbonIcon: typeof validIcons[number];
  selfRibbonSlide?: boolean;
  animateInLettersTop?: boolean;
  animateInLettersBottom?: boolean;
  preventScrolling?: boolean;
  ribbonIconSize?: { width : number; height : number; }
}

export interface DuelingBillboardsRef {
  animateOut: (
    onStartSlideOut?: () => void,
    finishAfterRotate?: boolean
  ) => gsap.core.Timeline;
}

const DuelingBillboards = forwardRef<
  DuelingBillboardsRef,
  DuelingBillboardsProps
>(
  (
    {
      topCopy,
      ribbonCopy,
      ribbonMessage = '',
      ribbonIcon,
      bottomCopy,
      selfRibbonSlide = false,
      animateInLettersTop,
      animateInLettersBottom,
      preventScrolling,
      ribbonIconSize
    },
    ref
  ) => {
    const {
      animateOut,
      wrapperRef,
      ribbonWrapperRef,
      ribbonRef,
      centerRef,
      centerStartRef,
      centerEndRef,
      billboardsHidden,
      startFillerRef,
      endFillerRef,
    } = useMotion(selfRibbonSlide);

    useImperativeHandle(
      ref,
      (): DuelingBillboardsRef => ({
        animateOut,
      })
    );

    return (
      <Styled.Wrapper ref={wrapperRef}>
        {!billboardsHidden && (
          <Styled.BillboardWrapper backgroundColor={ColorNames.blueRibbon}>
            <Billboard
              copy={topCopy}
              textColor={ColorNames.white}
              direction={'left'}
              isScrolling={!preventScrolling}
              animateInLetters={animateInLettersTop}
            />
          </Styled.BillboardWrapper>
        )}
        <Styled.StaticCenter>
          <Styled.StartFiller ref={startFillerRef} />
          <Styled.Center ref={centerRef}>
            <Styled.CenterStart ref={centerStartRef} />
            <Styled.RibbonWrapper ref={ribbonWrapperRef}>
              <Ribbon
                copy={ribbonCopy}
                alignment={'horizontal'}
                icon={ribbonIcon}
                backgroundColor={ColorNames.black}
                textColor={ColorNames.roti}
                iconColor={ColorNames.white}
                message={ribbonMessage}
                ref={ribbonRef}
                forceEnterAnimation={selfRibbonSlide}
                selfRibbonSlide={selfRibbonSlide}
                iconSize={ribbonIconSize}
              />
            </Styled.RibbonWrapper>
            <Styled.CenterEnd ref={centerEndRef} />
          </Styled.Center>
          <Styled.EndFiller ref={endFillerRef} />
        </Styled.StaticCenter>
        {!billboardsHidden && (
          <Styled.BillboardWrapper
            backgroundImage={PreloaderService.get(backgroundTexture)}
          >
            <Billboard
              copy={bottomCopy}
              textColor={ColorNames.blueRibbon}
              direction={'right'}
              isScrolling={!preventScrolling}
              animateInLetters={animateInLettersBottom}
            />
          </Styled.BillboardWrapper>
        )}
      </Styled.Wrapper>
    );
  }
);

DuelingBillboards.displayName = 'DuelingBillboards';

export default React.memo(DuelingBillboards);
