import { RefObject, useEffect, useRef } from 'react';
import gsap from 'gsap';

import { useHotspotOverlayStore } from 'store';
import { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';

export interface MotionRefs {
  closeRef: RefObject<ButtonCloseRef>;
}

export interface MotionProps {
  inDelay: number;
  outDelay: number;
}

const useMotion = (refs: MotionRefs, props: MotionProps) => {
  const { closeRef } = refs;
  const { inDelay = 0, outDelay = 0 } = props;
  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  const setBlackOverlayTransition = useHotspotOverlayStore(state => state.setBlackOverlayTransition);

  const animateIn = () => {
    inTl.current && inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => inTl.current = null
    });

    closeRef.current &&
      inTl.current
        .call(() => setBlackOverlayTransition('in'), null, 0)
        .add(closeRef.current.show(), inDelay);
  };

  const animateOut = () => {
    outTl.current && outTl.current.kill();
    outTl.current = gsap.timeline({
      onComplete: () => outTl.current = null
    });

    closeRef.current &&
      outTl.current
        .call(() => setBlackOverlayTransition('out'), null, 0)
        .add(closeRef.current.show().reversed(true), outDelay);
  };

  useEffect(() => {
    return () => {
      inTl.current?.kill();
      outTl.current?.kill();
      inTl.current = null;
      outTl.current = null;
    };
  }, []);

  return {
    animateIn,
    animateOut
  };
};

export default useMotion;
