import { useRef, RefObject, useEffect, useState } from 'react';
import gsap from 'gsap';

export interface MotionRefs {
  wrapperRef: RefObject<HTMLDivElement>;
  svgRef: RefObject<HTMLDivElement>;
  messageRef: RefObject<HTMLParagraphElement>;
}

const useMotion = ({
  wrapperRef,
  svgRef,
  messageRef
}: MotionRefs) => {
  const [animatedIn, setAnimateIn] = useState(false);

  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  const show = () => {
    if (inTl.current) inTl.current.kill();
    if (animatedIn) return;

    inTl.current = gsap.timeline({
      onComplete: () => {
        inTl.current = null
        setAnimateIn(true);
      }
    });

    const wrapperCenter = wrapperRef.current.getBoundingClientRect().width / 2;

    inTl.current
      .fromTo(
        wrapperRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'expo.inOut' }
      )
      .fromTo(
        svgRef.current,
        { x: `${wrapperCenter}` },
        { x: 0, duration: 0.9, ease: 'expo.inOut' },
        0
      )
      .fromTo(
        messageRef.current,
        { clipPath: 'inset(0% 0% 0% 100%)', x: `-${wrapperCenter - 40}` },
        { clipPath: 'inset(0% 0% 0% 0%)', x: 0, duration: 0.9, ease: 'expo.inOut' },
        0
      )

    return inTl.current;
  };

  const hide = () => {
    if (outTl.current) outTl.current.kill();
    if (!animatedIn) return;

    outTl.current = gsap.timeline({
      onComplete: () => {
        outTl.current = null
        setAnimateIn(false);
      }
    });

    const wrapperCenter = wrapperRef.current.getBoundingClientRect().width / 2;

    outTl.current
      .fromTo(
        svgRef.current,
        { x: 0 },
        { x: `${wrapperCenter}`, duration: 0.9, ease: 'expo.inOut' }
      )
      .fromTo(
        messageRef.current,
        { clipPath: 'inset(0% 0% 0% 0%)', x: 0 },
        { clipPath: 'inset(0% 0% 0% 100%)', x: `-${wrapperCenter - 40}`, duration: 0.9, ease: 'expo.inOut' },
        0
      )
      .fromTo(
        wrapperRef.current,
        { opacity: 1 },
        { opacity: 0, duration: 0.5, ease: 'expo.inOut' }
      )

    return outTl.current;
  };

  useEffect(() => {
    return () => {
      if (inTl.current) inTl.current.kill();
    };
  }, []);

  return { show, hide };

};

export default useMotion;
