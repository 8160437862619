import React from 'react';

import * as Styled from './PlayPause.styles';

export const validStates = ['play', 'pause'] as const;

export interface PlayPauseProps {
  state: typeof validStates[number];
  onClick?: () => void;
}

const defaultProps: Partial<PlayPauseProps> = {
  state: 'play',
  onClick: () => null
};

const PlayPause: React.FC<PlayPauseProps> = ({ state, onClick }) => {
  return (
    <Styled.Wrapper onClick={onClick} state={state}>
      <div />
      <div />
    </Styled.Wrapper>
  );
};

PlayPause.defaultProps = defaultProps;

export default PlayPause;
