import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { setTypography, setVh, setVisibility, spinHexagonIcon } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled(motion.div)`
  height: ${setVh(100)};
  width: 100%;
  position: fixed;
  background: ${colors.black};
  z-index: ${layout.zIndex.overlays};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Spacer = styled.div`
  position: relative;
  height: ${setVh(layout.headerFooterHeight.mobile)};
`
export const CloseWrapper = styled(motion.div)`
  overflow: hidden;
  will-change: transform;
  transform: scaleY(0);
`;

export const TitleWrapper = styled.h3`
  opacity: 0;
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)};
`;
export const ControlsWrapper = styled.div`
  opacity: 0;
`;
export const HeaderBar = styled.div`
  position: absolute;
  background: ${colors.black};
  top: 0;
  width: 100%;
  height: ${setVh(layout.headerFooterHeight.mobile)};
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${layout.zIndex.aboveTransitionBars};
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)};

  ${mediaTablet(`
    height: ${setVh(layout.headerFooterHeight.desktop)};
    padding: 0 ${layout.headerFooterPadding.desktop}rem;
  `)}
`;

export const FooterBar = styled.div`
  position: absolute;
  background: ${colors.black};
  bottom: 0;
  width: 100%;
  height: ${setVh(layout.headerFooterHeight.mobile)};
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${layout.zIndex.aboveTransitionBars};

  ${mediaTablet(`
    height: ${setVh(layout.headerFooterHeight.desktop)};
    padding: 0 ${layout.headerFooterPadding.desktop}rem;
  `)}

  > div {
    &:nth-child(1) {
      height: ${layout.audioToggleHeight.mobile}rem;
      display: flex;
      flex: 1;

      ${mediaTablet(`
        height: ${layout.audioToggleHeight.desktop}rem;
      `)}

      > div:not(:last-child) {
        margin-right: ${layout.footerElementMargin.desktop}rem;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  video {
    width: 100%;
    cursor: pointer;
  }
`;

export const SpotifyWrapper = styled.div`
  visibility: hidden;
`;

export const StalledOverlay = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  background: ${rgba(colors.black, 0.75)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isVisible }) => setVisibility(isVisible)}

  svg {
    width: 90rem;
    color: ${colors.roti};

    ${spinHexagonIcon(true)}
  }
`;
