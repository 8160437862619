import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import gsap from 'gsap';

import AudioToggle from 'components/AudioToggle/AudioToggle';
import Button, { ButtonRef } from 'components/Button/Button';
import ButtonClose, { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager, { DEFAULT_FADE_DURATION } from 'services/audioManager/audioManager.service';
import { useAudioStore, useCopyStore, usePlaylistsStore, useGlobalStore } from 'store';
import { BlackBarPositionState, BlackBarTransitionState } from 'store/global.types';
import { ReactComponent as SvgSpinner } from 'svgs/hexagon-icon.svg';
import gtm from 'u9/utils/gtm';
import { ROUTES } from 'utils/routes';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';

import AnthemVideoOverlayControls from './AnthemVideoOverlayControls/AnthemVideoOverlayControls';

import * as Styled from './AnthemVideoOverlay.styles';

interface AnthemVideoOverlayProps {
  isVisible?: boolean;
  toggleAnthemVideoVisible: () => void;
}

const AnthemVideoOverlay = ({
  isVisible = false,
  toggleAnthemVideoVisible,
}: AnthemVideoOverlayProps) => {
  const [isStalled, setStalled] = useState<boolean>(false);
  const [animateOut, setAnimateOut] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const titleRef = useRef(null);
  const controlsRef = useRef(null);
  const closeRef = useRef<ButtonCloseRef>(null);
  const ctaRef = useRef<ButtonRef>(null);
  const spotifyRef = useRef();
  const router = useRouter() || { push: () => null, route: '/' };

  const { isMuted, currentLoopId } = useAudioStore(state => state);
  const copy = useCopyStore(state => state.copy);
  const { anthemTrack } = usePlaylistsStore(state => state);
  const { setBlackBarTransition } = useGlobalStore(state => state)
  const controls = useMemo(
    () => (
      <AnthemVideoOverlayControls
        video={videoRef}
        isStalled={isStalled}
        setStalled={setStalled}
      />
    ),
    [isStalled, setStalled]
  );

  useEffect(() => {
    let tl = gsap.timeline({});
    if (isVisible) {
      if (!animateOut) {
        tl
          .set(spotifyRef.current, { autoAlpha: 1 })
          .add(closeRef.current.show())
          .add(ctaRef.current.show())
          .fromTo(titleRef.current,
            {
              x: 300,
              opacity: 0
            },
            {
              x: 0,
              opacity: 1,
              duration: 0.4,
              ease: 'power4.out'
            },
            '<'
          )
          .fromTo(controlsRef.current,
            {
              y: '100%',
              opacity: 0
            },
            {
              y: '0%',
              opacity: 1,
              duration: 0.3,
              ease: 'power4.out'
            },
            '-=0.1'
          )
      } else {
        tl
          .add(closeRef.current.show().reversed(true))
          .fromTo(titleRef.current,
            {
              y: 0,
              opacity: 1
            },
            {
              y: -100,
              opacity: 1,
              duration: 0.3,
              ease: 'power4.in'
            }
          )
          .fromTo(controlsRef.current,
            {
              y: 0,
              opacity: 1
            },
            {
              y: 100,
              opacity: 0,
              duration: 0.3,
              ease: 'power4.in'
            },
            '-=0.3'
          )
          .fromTo(spotifyRef.current,
            {
              y: 0,
              opacity: 1
            },
            {
              y: 100,
              opacity: 0,
              duration: 0.3,
              ease: 'power4.in'
            },
            '-=0.3'
          )
      }
    }
    return () => {
      tl?.kill();
      tl = null;
    }
  }, [isVisible, animateOut]);

  useEffect(() => {
    if (currentLoopId) AudioManager.setVolume(SOUNDS_DATA[currentLoopId], isVisible ? 0 : null, isVisible ? 0 : DEFAULT_FADE_DURATION);

    if (isVisible) {
      setBlackBarTransition(BlackBarTransitionState.Out, BlackBarPositionState.Cover, BlackBarPositionState.Edge, () => {
        setBlackBarTransition(BlackBarTransitionState.None, BlackBarPositionState.Cover, BlackBarPositionState.Ribbon)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  if (!copy.experience) return null;
  const {
    experience: { videoPlayer: videoPlayerCopy },
  } = copy;

  const onCtaClick = () => {
    gtm.trackEvent('Video Fullscreen Overlay - Footer', 'Click - Listen', 'Video Fullscreen - Listen on Spotify');
  };
  const handleClose = () => {
    const ribbonOut = router.route === ROUTES.HOME ? BlackBarPositionState.Ribbon : BlackBarPositionState.Edge;
    setAnimateOut(true);

    setBlackBarTransition(BlackBarTransitionState.In, BlackBarPositionState.Edge, BlackBarPositionState.Cover, () => {
      toggleAnthemVideoVisible();
      gtm.trackEvent('Video Fullscreen Overlay - Footer', 'Click - Exit button', 'Video Fullscreen - Exit button');
      setBlackBarTransition(BlackBarTransitionState.Out, BlackBarPositionState.Cover, ribbonOut, () => {
        setAnimateOut(false);
        setBlackBarTransition(BlackBarTransitionState.None, BlackBarPositionState.Cover, BlackBarPositionState.Ribbon)
      })
    });
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <Styled.Wrapper>
            <Styled.Spacer />
            <Styled.Content>
              <video
                ref={videoRef}
                muted={isMuted}
                crossOrigin="anonymous"
                playsInline
                autoPlay
              />
              <Styled.StalledOverlay isVisible={isStalled}>
                <SvgSpinner />
              </Styled.StalledOverlay>
              {isMobileLayout() && controls}
            </Styled.Content>
            <Styled.Spacer />
          </Styled.Wrapper>

          <Styled.HeaderBar>
            <Styled.TitleWrapper ref={titleRef}>
              {videoPlayerCopy.header}
            </Styled.TitleWrapper>
            <ButtonClose onClick={handleClose} wrapped={true} ref={closeRef} />
          </Styled.HeaderBar>

          <Styled.FooterBar>
            <Styled.ControlsWrapper ref={controlsRef}>
              <AudioToggle />
              {!isMobileLayout() && controls}
            </Styled.ControlsWrapper>

            <Styled.SpotifyWrapper ref={spotifyRef}>
              <a
                href={anthemTrack.url}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  ref={ctaRef}
                  label={videoPlayerCopy.cta}
                  onClick={onCtaClick}
                  color={ColorNames.mountainMeadow}
                  isBig
                />
              </a>
            </Styled.SpotifyWrapper>
          </Styled.FooterBar>
        </>
      )}
    </AnimatePresence>
  );
};

export default AnthemVideoOverlay;
