import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import gsap from 'gsap';
import { AnimatePresence, motion } from 'framer-motion';

import Player from 'components/Player/Player';
import SplitContent, { SplitContentRef, SplitContentFontScale } from 'components/SplitContent/SplitContent';
import { usePlaylistsStore, useCopyStore, useGlobalStore } from 'store';
import { placeholderAnthemTrack, placeholderFavoriteTracks } from 'store/playlists.data';
import { ReactComponent as SvgSpinner } from 'svgs/hexagon-icon.svg';
import { ReactComponent as SvgSave } from 'svgs/save.svg';
import { isStorybook } from 'u9/utils/platform';
import { overlayMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import useMotion from './Playlists.motion';
import * as Styled from './Playlists.styles';

export interface PlaylistsRef {
  animateIn?: () => gsap.core.Timeline;
  animateOut?: () => gsap.core.Timeline;
  animateFavoriteTracks?: () => gsap.core.Timeline;
}
export interface PlaylistsProps { }

const defaultProps: Partial<PlaylistsProps> = {};

const Playlists = React.forwardRef<PlaylistsRef, PlaylistsProps>(
  (props: PlaylistsProps, ref) => {
    const { user } = useGlobalStore(state => state);
    const { favoritedTracks, playlists, anthemTrack, isLoading } = usePlaylistsStore(state => state);

    // prevent favorite tracks from staggering
    // when a track is removed
    const [favoriteTracksAnimated, setFavoriteTracksAnimated] = useState<boolean>(false);
    const [isInitial, setIsInitial] = useState<boolean>(true);

    const favoriteTrackAnimateTimeout = useRef(null);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const anthemTrackWrapperRef = useRef<HTMLDivElement>(null);
    const divideLineRefs = useRef<HTMLDivElement[]>([]);
    const favoritesTitleRef = useRef<SplitContentRef>(null);
    const recommendsTitleRef = useRef<SplitContentRef>(null);
    const recommendsDescRef = useRef<SplitContentRef>(null);
    const favoriteTrackRefs = useRef<HTMLDivElement[]>([]);
    const recommendedTrackRefs = useRef<HTMLDivElement[]>([]);
    const noFavoriteRef = useRef<HTMLDivElement>(null);
    const noRecommendRef = useRef<HTMLDivElement>(null);

    const { animateIn, animateOut, animateFavoriteTracks } = useMotion({
      wrapperRef,
      anthemTrackWrapperRef,
      divideLineRefs,
      favoritesTitleRef,
      recommendsTitleRef,
      recommendsDescRef,
      favoriteTrackRefs,
      recommendedTrackRefs,
      noFavoriteRef,
      noRecommendRef
    });

    useImperativeHandle(
      ref,
      (): PlaylistsRef => ({
        animateIn,
        animateOut,
        animateFavoriteTracks
      })
    )

    const favoriteTracks = isStorybook() ? placeholderFavoriteTracks : favoritedTracks;
    const favoritedList = favoriteTracks.filter((track) => !track.isCurated).map((track, index) => {
      return (
        <Styled.FavoriteList
          ref={ref => favoriteTrackRefs.current[index] = ref}
          key={`${track.id}_${index}`}
        >
          <Player track={track} type={'favorite'} />
        </Styled.FavoriteList>
      )
    });

    const recommendedTracks = isStorybook() ? placeholderFavoriteTracks : playlists.personalized.data.tracks;
    const recommendedList = recommendedTracks.map((track, index) => {
      return (
        <Styled.FavoriteList
          ref={ref => recommendedTrackRefs.current[index] = ref}
          key={`${track.id}_${index}`}
        >
          <Player track={track} type={'link'} theme={ColorNames.black} />
        </Styled.FavoriteList>
      )
    });

    useEffect(() => {
      if (favoriteTracks.length > 1 && favoritedList.length > 0 && !isInitial) {
        if (favoriteTracksAnimated) {
          if (favoriteTrackRefs.current.length) gsap.set(favoriteTrackRefs.current, { opacity: 1 });
        } else {
          if (favoriteTrackAnimateTimeout.current) clearTimeout(favoriteTrackAnimateTimeout.current);
          setFavoriteTracksAnimated(true);
          favoriteTrackAnimateTimeout.current = setTimeout(() => {
            animateFavoriteTracks();
            favoriteTrackAnimateTimeout.current = null;
          }, 500);
        }
      }

      // eslint-disable-next-line
    }, [favoriteTracks, favoritedList]);

    useEffect(() => {
      setIsInitial(false);

      return () => {
        setIsInitial(true);
        setFavoriteTracksAnimated(false);
        if (favoriteTrackAnimateTimeout.current) clearTimeout(favoriteTrackAnimateTimeout.current);
      };
    }, [])

    const { copy } = useCopyStore(state => state);
    if (!copy.experience) return null;

    const playlistsCopy = copy.experience.personalizedPlaylist.overlay.playlists;

    return (
      <Styled.Wrapper ref={wrapperRef}>
        <Styled.SectionWrapper ref={anthemTrackWrapperRef}>
          <Player
            track={isStorybook() ? placeholderAnthemTrack : anthemTrack}
            type={'link'}
            premiumPrompt={ColorNames.black}
          />
          <Styled.DivideLine
            ref={ref => divideLineRefs.current[0] = ref}
            hasExtraTopPadding={!user?.hasPremium}
          />
        </Styled.SectionWrapper>

        <Styled.SectionWrapper>
          <Styled.TitleWrapper>
            <Styled.Title>
              <SplitContent
                ref={favoritesTitleRef}
                copy={playlistsCopy.favorites.title}
                fontScale={SplitContentFontScale.Heading6}
              />
            </Styled.Title>
          </Styled.TitleWrapper>

          <AnimatePresence exitBeforeEnter>
            {!isLoading && favoritedList.length && (
              <motion.div key="favorites">
                {favoritedList}
              </motion.div>
            )}
            {(isLoading || !favoritedList.length) && (
              <motion.div key="noFavorites" {...overlayMotionProps}>
                <Styled.NoContent ref={noFavoriteRef} isFavorites isLoading={isLoading}>
                  <AnimatePresence exitBeforeEnter>
                    {isLoading && (
                      <motion.div key="noFavorites_loading" {...overlayMotionProps}>
                        <SvgSpinner />
                      </motion.div>
                    )}

                    {!isLoading && (
                      <motion.div key="noFavorites_content" {...overlayMotionProps}>
                        <Styled.NoContentTitle>
                          {playlistsCopy.favorites.none_heading}
                        </Styled.NoContentTitle>
                        <Styled.NoContentSubtitle>
                          <SvgSave />
                          {playlistsCopy.favorites.none_description}
                        </Styled.NoContentSubtitle>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Styled.NoContent>
              </motion.div>
            )}
          </AnimatePresence>
        </Styled.SectionWrapper>
        <Styled.DivideLine ref={ref => divideLineRefs.current[1] = ref} />
        <Styled.SectionWrapper>
          <Styled.TitleWrapper>
            <Styled.Title>
              <SplitContent
                ref={recommendsTitleRef}
                copy={playlistsCopy.recommended.title}
                fontScale={SplitContentFontScale.Heading6}
              />
            </Styled.Title>
          </Styled.TitleWrapper>
          {recommendedList.length ? (
            <Styled.PlayerWrapper>
              {recommendedList}
            </Styled.PlayerWrapper>
          ) : null}
          {!recommendedList.length ? (
            <Styled.NoContent ref={noRecommendRef}>
              <Styled.NoContentTitle>
                {playlistsCopy.recommended.none_heading}
              </Styled.NoContentTitle>
              <Styled.NoContentSubtitle>
                <SvgSave />
                {playlistsCopy.recommended.none_description}
              </Styled.NoContentSubtitle>
            </Styled.NoContent>
          ) : null}
        </Styled.SectionWrapper>
      </Styled.Wrapper>
    );
  }
);

Playlists.defaultProps = defaultProps;

export default Playlists;
