import React from 'react';

import { ReactComponent as FacebookIcon } from 'svgs/facebook.svg';
import { ReactComponent as TwitterIcon } from 'svgs/twitter.svg';
import share from 'u9/utils/share';
import { useCopyStore } from 'store';
import gtm from 'u9/utils/gtm';
import * as Styled from './SocialShare.styles';

interface SocialShareProps {}

const defaultProps = {};

const SocialShare: React.FC<SocialShareProps> = () => {
  const { copy } = useCopyStore(state => state);

  const handleTwitterShare = () => {
    gtm.trackEvent('Global - Footer', 'Click - Twitter share', 'Twitter share');
    share.twitter(window.location.href, copy.head.description);
  };
  const handleFacebookShare = () => {
    gtm.trackEvent('Global - Footer', 'Click - Facebook share', 'Facebook share');
    share.facebook();
  };

  return (
    <Styled.Wrapper>
      <Styled.List>
        <Styled.IconWrapper onClick={handleFacebookShare}>
          <FacebookIcon />
        </Styled.IconWrapper>
        <Styled.IconWrapper onClick={handleTwitterShare}>
          <TwitterIcon />
        </Styled.IconWrapper>
      </Styled.List>
    </Styled.Wrapper>
  );
};

SocialShare.defaultProps = defaultProps;

export default SocialShare;
