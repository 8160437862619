import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import gsap from 'gsap';

import AudioToggle from 'components/AudioToggle/AudioToggle';
import Button, { ButtonRef } from 'components/Button/Button';
import { MenuItem } from 'components/ContextualMenu/ContextualMenu';
import ContextualMenuSelector from 'components/ContextualMenuSelector/ContextualMenuSelector';
import FooterMobile from 'components/FooterMobile/FooterMobile';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import SocialShare from 'components/SocialShare/SocialShare';
import { useClubhouseStore, useCopyStore, useGlobalStore } from 'store';
import { BlackBarPositionState, BlackBarTransitionState } from 'store/global.types';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';
import { isTablet } from 'u9/utils/platform';
import gtm from 'u9/utils/gtm';

import * as Styled from './Footer.styles';

interface FooterProps {
  toggleAnthemVideoVisible: () => void;
}

const Footer = ({ toggleAnthemVideoVisible }: FooterProps) => {
  const copy = useCopyStore(state => state.copy);
  const {
    isReady: isLandingReady,
    countryType,
    setBlackBarTransition,
  } = useGlobalStore(state => state);
  const { isReady: isClubhouseReady } = useClubhouseStore(state => state);

  const legalLinksRef = useRef<HTMLAnchorElement[]>([]);
  const audioButtonEl = useRef<HTMLDivElement>(null);
  const videoCtaEl = useRef<ButtonRef>(null);
  const socialShareEl = useRef<HTMLDivElement>(null);
  const languageSelectorEl = useRef<HTMLDivElement>(null);
  const creditsEl = useRef<HTMLDivElement>(null);
  const videoCtaElWrapper = useRef<HTMLDivElement>(null);
  const spotifyCredits = useRef<HTMLDivElement>(null);

  const enterTimelineRef = useRef<gsap.core.Timeline>(null);
  const [isMounted, setMounted] = useState<boolean>(false);

  const animation = useCallback(
    (delay = 0) => {
      enterTimelineRef.current = gsap.timeline({
        onComplete: () => {
          enterTimelineRef.current = null;
        },
      });

      enterTimelineRef.current
        .set(videoCtaElWrapper.current, { visibility: 'hidden' }, delay);

      if (!isTablet()) {
        enterTimelineRef.current.fromTo(
          legalLinksRef.current,
          {
            x: -150,
            visibility: 'hidden',
          },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.4,
            stagger: -0.1,
            ease: 'power2.inOut',
          }
        );
      }

      enterTimelineRef.current
        .fromTo(
          [audioButtonEl.current, spotifyCredits.current],
          { opacity: 0 },
          { opacity: 1, duration: 1.5 },
          '<'
        )
        .set(videoCtaElWrapper.current, { visibility: 'visible' }, '<')
        .add(videoCtaEl.current.show(), '<')
        .fromTo(
          [
            videoCtaElWrapper.current,
            socialShareEl.current,
            languageSelectorEl.current,
            creditsEl.current,
          ],
          { x: 150, visibility: 'hidden' },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.4,
            stagger: 0.1,
            ease: 'power2.inOut',
            delay: 0.1,
          },
          '<'
        );

      return enterTimelineRef.current;
    },
    []
  );

  useEffect(() => {
    if (!isMobileLayout() && isMounted) {
      if (isClubhouseReady) {
        animation(1);
      } else if (isLandingReady) {
        animation();
      } else {
        animation().pause(0);
      }
    }
  }, [isClubhouseReady, isLandingReady, animation, isMounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const router = useRouter() || { push: () => null, route: '/' };
  const legalLinks: MenuItem[] = useMemo(() => {
    return copy.experience?.footer?.legal_menu?.map(item => {
      return {
        label: item.label,
        value: item.link,
        isLink: true,
      };
    });
  }, [copy.experience?.footer?.legal_menu]);

  if (!copy.experience) return null;
  const {
    experience: { footer: footerCopy },
  } = copy;

  if (!footerCopy) return null;

  const handleVideoButton = () => {
    const ribbonIn =
      router.route === '/'
        ? BlackBarPositionState.Ribbon
        : BlackBarPositionState.Edge;
    setBlackBarTransition(
      BlackBarTransitionState.In,
      ribbonIn,
      BlackBarPositionState.Cover,
      () => {
        toggleAnthemVideoVisible();
        gtm.trackEvent('Global - Footer', 'Click - Play', 'Play Anthem');
      }
    );
  };

  const handleLegalLinks = (index: number) => {
    let event = '';
    switch (index) {
      case 0:
        event = 'T&Cs';
        break;
      case 1:
        event = 'Privacy Center';
        break;
      case 2:
        event = 'Privacy Policy';
        break;
      default:
        event = 'Cookies';
    }
    gtm.trackEvent('Global - Footer', 'Click - ' + event, event);
  };

  return isMobileLayout() ? (
    <FooterMobile />
  ) : (
    <Styled.Wrapper>
      <Styled.Section>
        <Styled.ChildWrapper ref={audioButtonEl}>
          <AudioToggle />
        </Styled.ChildWrapper>
        <Styled.ChildWrapper ref={videoCtaElWrapper}>
          <Button
            ref={videoCtaEl}
            label={footerCopy.videoCta.desktop}
            onClick={handleVideoButton}
            color={ColorNames.blueRibbon}
            icon="button-hexagon"
            iconColor={ColorNames.roti}
            showFrom="right"
          />
        </Styled.ChildWrapper>
        <Styled.ChildWrapper ref={socialShareEl}>
          <SocialShare />
        </Styled.ChildWrapper>
        <Styled.ChildWrapper ref={languageSelectorEl}>
          <LanguageSelector />
        </Styled.ChildWrapper>
        <Styled.ChildWrapper ref={creditsEl}>
          <Styled.Text>
            <a href={footerCopy.credits1_link} target="_blank" rel="noreferrer">
              {footerCopy.credits1}
            </a>
          </Styled.Text>
        </Styled.ChildWrapper>
      </Styled.Section>
      <Styled.Section>
        <Styled.ChildWrapper>
          {isTablet() && (
            <ContextualMenuSelector
              items={legalLinks}
              label={footerCopy.legal}
              onItemSelect={value =>
                handleLegalLinks(
                  legalLinks.findIndex(link => link.value === value)
                )
              }
            />
          )}
          {!isTablet() &&
            legalLinks.map((item, idx) => (
              <Styled.LinkList
                key={idx}
                ref={el => (legalLinksRef.current[idx] = el)}
                {...(idx !== legalLinks.length - 1 || countryType !== 1
                  ? {
                    href: item.value,
                    target: '_blank',
                    onClick: () => handleLegalLinks(idx),
                  }
                  : {})}
                {...(countryType === 1 && idx === legalLinks.length - 1
                  ? { id: 'ot-sdk-btn', className: 'ot-sdk-show-settings' }
                  : {})}
              >
                {item.label}
              </Styled.LinkList>
            ))}
        </Styled.ChildWrapper>
        <Styled.ChildWrapper ref={spotifyCredits}>
          <Styled.Text opacity={0.6}>{footerCopy.credits2}</Styled.Text>
        </Styled.ChildWrapper>
      </Styled.Section>
    </Styled.Wrapper>
  );
};

export default React.memo(Footer);
