import { useRef, RefObject, useEffect } from 'react';
import gsap from 'gsap';

import { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import { LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import { SplitContentRef } from 'components/SplitContent/SplitContent';
import { BackingImageRef } from 'components/BackingImage/BackingImage';
import { MoveDirection } from 'components/BackingImage/BackingImage.motion';
import { isMobileLayout } from 'utils/styles/responsive';

export interface MotionRefs {
  contentWrapperRef?: RefObject<HTMLDivElement>;
  ribbonWrapperRef?: RefObject<HTMLDivElement>;
  backingImageRefs?: RefObject<BackingImageRef[]>;
  overlayTitleRef?: RefObject<OverlayTitleRef>;
  subtitleRef?: RefObject<SplitContentRef>;
  bodyRef?: RefObject<SplitContentRef>;
  teaserRef?: RefObject<SplitContentRef>;
  trackRef?: RefObject<LabeledPlayerRef>;
}

const useMotion = (refs: MotionRefs) => {
  const animateInTl = useRef<gsap.core.Timeline>(null);
  const animateOutTl = useRef<gsap.core.Timeline>(null);

  const {
    contentWrapperRef,
    ribbonWrapperRef,
    backingImageRefs,
    overlayTitleRef,
    subtitleRef,
    bodyRef,
    teaserRef,
    trackRef
  } = refs;

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    const ribbonMotionProps = isMobileLayout() ?
      { top: 0 } :
      { left: 0 };

    animateInTl.current
      .to(
        contentWrapperRef.current,
        { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.7, ease: 'expo.inOut' }
      )
      .to(
        ribbonWrapperRef.current,
        { ...ribbonMotionProps, duration: 0.7, ease: 'expo.inOut' },
        0
      );

    backingImageRefs.current.forEach((backingImage, index) => {
      let direction = MoveDirection.Down;
      if (index === 1) direction = MoveDirection.Right;
      if (index === 2) direction = MoveDirection.Up;
      animateInTl.current
        .add(backingImage.maskIn(), 0.2 * (index + 1))
        .add(backingImage.move(direction), 0.2 * (index + 1))
    });

    animateInTl.current
      .add(overlayTitleRef.current.show(), 0.2)
      .add(subtitleRef.current.maskIn(), 0.4)
      .add(bodyRef.current.maskIn(), 0.6)
      .add(teaserRef.current.maskIn(), 0.8)
      .add(trackRef.current.maskIn(), 1)

    return animateInTl.current;
  };

  const animateOut = (cb = () => null) => {
    if (animateOutTl.current) animateOutTl.current.kill();

    animateOutTl.current = gsap.timeline({
      onComplete: () => {
        cb();
        animateOutTl.current = null
      }
    });

    const ribbonMotionProps = isMobileLayout() ?
      { top: '-100%' } :
      { left: '-100%' };

    backingImageRefs.current.slice().reverse().forEach((backingImage, index) => {
      animateOutTl.current
        .add(backingImage.maskOut(), 0.2 * (index + 1))
    });

    animateOutTl.current
      .add(trackRef.current.maskOut(), 0.2)
      .add(teaserRef.current.maskOut(), 0.4)
      .add(bodyRef.current.maskOut(), 0.6)
      .add(subtitleRef.current.maskOut(), 0.8)
      .to(
        ribbonWrapperRef.current,
        { ...ribbonMotionProps, duration: 1, ease: 'expo.inOut' },
        0.6
      )
      .to(
        contentWrapperRef.current,
        { clipPath: 'inset(0% 0% 100% 0%)', duration: 1, ease: 'expo.inOut' },
        0.6
      )

    return animateOutTl.current;
  };

  useEffect(() => {
    setTimeout(() => animateIn(), 500);

    return () => {
      if (animateInTl.current) animateInTl.current.kill();
      if (animateOutTl.current) animateOutTl.current.kill();
    };

    // eslint-disable-next-line
  }, []);

  return {
    animateIn,
    animateOut
  }
};

export default useMotion;
