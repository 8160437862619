import React, { useMemo } from 'react';

import StorytellingLayout from './StorytellingLayout/StorytellingLayout';
import useHotspotOverlayCopy from '../HotspotOverlay.copy';
import useStorytellingAssets from './Storytelling.assets';
import useStorytellingCopy from './Storytelling.copy';
import useGtm from './Storytelling.gtm';
import * as Styled from './Storytelling.styles';

export interface StorytellingProps { }

const defaultProps = {};

const Storytelling = () => {
  const { stories, storytellingIndex, tracks } = useStorytellingCopy();
  const { currentRibbon } = useHotspotOverlayCopy();

  const {
    entryImage,
    interactiveThumbnails,
    interactiveVideos
  } = useStorytellingAssets();

  useGtm(storytellingIndex);

  const title = useMemo(() => stories[0].title, [stories]);
  const breadcrumbs = useMemo(() => stories.map(story => story.breadcrumb), [stories]);

  return (
    <Styled.Wrapper>
      <StorytellingLayout
        stories={stories}
        tracks={tracks}
        ribbon={currentRibbon}
        entryImage={entryImage}
        interactiveThumbnails={interactiveThumbnails}
        interactiveVideos={interactiveVideos}
        title={title}
        breadcrumbs={breadcrumbs}
      />
    </Styled.Wrapper>
  );
};

Storytelling.defaultProps = defaultProps;

export default React.memo(Storytelling);
