import { RefObject } from 'react';
import gsap from 'gsap';

import { isMobileLayout } from 'utils/styles/responsive';

const useMotion = (held = false, interactionOverlayRef: RefObject<HTMLDivElement>, imageWrapperRef: RefObject<HTMLDivElement>) => {

  const slideMotionMain = {
    initial: { left: '0%' },
    animate: { left: held ? '100%' : '0%' },
    transition: { duration: isMobileLayout() ? 0.5 : 0.7, ease: 'easeInOut' }
  };

  const slideMotionSecondary = {
    initial: { left: '-130%' },
    animate: { left: held ? '0' : '-130%' },
    transition: { duration: isMobileLayout() ? 0.5 : 0.7, ease: 'easeInOut' }
  };

  const fadeMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2 }
  };

  const showImage = () => {
    const timeline = gsap.timeline();

    timeline
      .set(interactionOverlayRef.current, { pointerEvents: 'auto' })
      .fromTo(
        imageWrapperRef.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.8, ease: 'expo.inOut' }
      );

    return timeline;
  }

  const hideImage = () => {
    const timeline = gsap.timeline();

    timeline
      .fromTo(
        imageWrapperRef.current,
        { autoAlpha: 1 },
        { autoAlpha: 0, duration: 0.8, ease: 'expo.inOut' }
      )
      .set(interactionOverlayRef.current, { pointerEvents: 'none' })

    return timeline;
  }

  return { fadeMotion, slideMotionMain, slideMotionSecondary, showImage, hideImage };
};

export default useMotion;
