import styled from 'styled-components';

import { fullscreen, interactiveMediaMessageWrapper, setVh, storytellingContentMargin, storytellingContentPadding } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  ${fullscreen()}
  background-color: ${colors.blueRibbon};
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;

  ${mediaTablet(`
    background-color: transparent;
    overflow: hidden;
  `)}
`;

export const ContentWrapper = styled.div<{
  hasBodyIntro: boolean,
  hasTrivia: boolean,
  hasTrack: boolean,
  hasBothTriviaTrack: boolean,
  isFirstSlide: boolean
}>`
  ${({ hasTrivia, hasTrack, hasBothTriviaTrack, isFirstSlide }) => `

    ${fullscreen()}
    ${isFirstSlide ? 'overflow-y: scroll; overflow-x: hidden;' : ''}
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 100%;
    padding: 350rem ${layout.headerFooterPadding.mobile}rem 200rem;

    ${isFirstSlide ? `grid-template-areas: 'subtitle' 'body';
      ` : hasBothTriviaTrack ? `grid-template-areas: 'subtitle' 'interactiveMedia' 'body' 'trivia' 'track';
      ` : hasTrivia ? `grid-template-areas: 'subtitle' 'interactiveMedia' 'body' 'trivia';
      ` : hasTrack ? `grid-template-areas: 'subtitle' 'interactiveMedia' 'body' 'track';
      ` : `grid-template-areas: 'subtitle' 'interactiveMedia' 'body';
      `
    }
  `}

  ${({ hasTrivia, hasTrack, hasBothTriviaTrack, isFirstSlide }) => `
    ${mediaTablet(`
        padding: 220rem;
        grid-template-rows: 50% 50%;
        pointer-events: none;

        ${isFirstSlide ? `
          grid-template-columns: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        ` : `
          grid-template-columns: 55% 45%;
        `}

        ${hasBothTriviaTrack ? `grid-template-areas: 'subtitle triviaTrack' 'body .'
          ` : hasTrivia ? `grid-template-areas: 'subtitle trivia' 'body .'
          ` : hasTrack ? `grid-template-areas: 'subtitle body' '. track'
          ` : isFirstSlide ? `grid-template-areas: 'subtitle' 'body'
          ` : `grid-template-areas: 'subtitle body' '. .'
          `
      }
      `)
    }
  `}
`;

export const CopyLeft = styled.div`
  grid-area: subtitle;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Subtitle = styled.div<{ isFirstSlide: boolean }>`
  grid-area: subtitle;
  padding-bottom: ${layout.headerFooterPadding.mobile}rem;
  z-index: 1;

  span {
    color: ${colors.roti};
  }

  .SplitText__wrapper {
    padding: 0 40rem;
    margin-left: -40rem;
    width: 100%;
  }

  ${props => mediaTablet(`
    width: ${props.isFirstSlide ? '50%' : '100%'};
    margin-bottom: 30rem;
    padding-bottom: 0;
    z-index: 2;

    .SplitText__wrapper {
      margin-left: -40rem;
    }
  `)}
`;

export const BodyIntro = styled.div`
  padding-top: ${layout.headerFooterPadding.mobile}rem;
  padding-bottom: 40rem;

  ${mediaTablet(`
    padding-top: 0;
  `)}
`;

export const Body = styled.div<{ isFirstSlide?: boolean, hasTrivia?: boolean, hasTrack?: boolean }>`
  grid-area: body;
  padding-bottom: ${props => props.isFirstSlide ? 0 : '80rem'};
  z-index: 1;

  ${props => mediaTablet(`
    padding-bottom: 0;
    z-index: 2;

    ${(props.hasTrack &&
    !props.hasTrivia) || (!props.hasTrack &&
      !props.hasTrivia && !props.isFirstSlide) ?
    `
      ${storytellingContentPadding().tablet}
      ` : ''
  }

    ${props.isFirstSlide ?
    'width: 40%;' :
    props.hasTrivia && props.hasTrack ? `
      width: 80%;
      `: ''
  }
  `)
  }

  ${props => mediaDesktop(`
    padding-bottom: 0;
    z-index: 2;

    ${(props.hasTrack &&
      !props.hasTrivia) || (!props.hasTrack &&
        !props.hasTrivia && !props.isFirstSlide) ?
      `
        ${storytellingContentPadding().desktop}
      ` : ''
    }

    ${props.isFirstSlide ?
      'width: 40%;' :
      props.hasTrivia && props.hasTrack ? `
      width: 80%;
      `: ''
    }
  `)
  }
`;

export const Trivia = styled.div`
  position: relative;
  grid-area: trivia;
  z-index: 1;
  padding-bottom: 80rem;

  ${mediaTablet(`
    z-index: 2;
    padding-bottom: 40rem;
  `)}

  ${storytellingContentPadding().tablet}
  ${storytellingContentPadding().desktop}
`;

export const TriviaIntro = styled.div`
  padding-bottom: 40rem;

  ${mediaTablet(`
    padding-bottom: 10rem;
  `)}
`;

export const TriviaBody = styled.div``;

export const Track = styled.div<{ mediaIsPlaying: boolean, hasMarginTop: boolean }>`
  position: relative;
  grid-area: track;
  padding-bottom: 200rem;
  z-index: ${props => props.mediaIsPlaying ? 2 : 4};
  pointer-events: auto;

  ${props => mediaTablet(`
    padding-bottom: 0;
    padding-top: ${props.hasMarginTop ? '120rem' : '0'};
  `)}

  ${storytellingContentMargin().tablet}
  ${storytellingContentMargin().desktop}
`;

export const TriviaTrackWrapper = styled.div`
  grid-area: triviaTrack;
`;

export const InteractiveMediaWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: ${setVh(45)};
  margin-left: ${-1 * layout.headerFooterPadding.mobile}rem;
  z-index: 1;
  grid-area: interactiveMedia;
  pointer-events: auto;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  * {
    user-select: none;
  }

  ${mediaTablet(`
    ${fullscreen()}
    z-index: 3;
    margin-left: 0;
    pointer-events: none;
  `)}
`;

export const InteractiveMediaThumbnailWrapper = styled.div`
  ${fullscreen()}
  overflow: hidden;
  user-select: none;
  * {
    user-select: none;
  }

  ${mediaTablet(`
    width: 45vw;
    left: 50%;
    transform: translateX(-50%);
  `)}
`;

export const InteractiveMediaThumbnail = styled.div<{ image: string }>`
  ${fullscreen()}
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1.4);
  opacity: 0;
`;

export const InteractiveMediaMessageWrapper = styled.div`
  ${interactiveMediaMessageWrapper('20rem')}
`;
