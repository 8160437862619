import { useRef, useEffect, useState } from 'react';

import { useAudioStore } from 'store';
import { fillArray } from 'utils/basic-functions';

const useAnimation = () => {
  const [on, setOn] = useState(false); // for animation toggle
  const { isMuted } = useAudioStore();

  const bars = useRef(fillArray(7));
  const randomDelays = useRef(bars.current.map(() => Math.random() * 0.7));

  const animationVariants = {
    enabled: (index: number) => ({
      scaleY: 1.0,
      transition: {
        duration: 0.6,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: randomDelays.current[index],
        type: 'tween',
        ease: 'easeInOut'
      }
    }),
    disabled: { scaleY: 0.2 }
  }

  const getAnimationProps: any = (index: number) => {
    return {
      variants: animationVariants,
      initial: 'disabled',
      animate: on ? 'enabled' : 'disabled',
      custom: index
    }
  };

  useEffect(() => {
    setOn(!isMuted);
  }, [isMuted]);

  return { bars, getAnimationProps }
}

export default useAnimation;
