import React, { useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';

import useMotion, { MoveDirection } from './BackingImage.motion';
import * as Styled from './BackingImage.styles';

export interface BackingImageRef {
  maskIn?: () => gsap.core.Timeline;
  maskOut?: () => gsap.core.Timeline;
  move?: (direction?: MoveDirection) => gsap.core.Timeline;
}

export interface BackingImageProps {
  image: string;
  scale?: number;
  top?: string;
  left?: string;
}

const defaultProps: Partial<BackingImageProps> = {
  scale: 1,
  top: '0',
  left: '0',
};

const BackingImage = React.forwardRef<BackingImageRef, BackingImageProps>(
  (props: BackingImageProps, ref) => {
    const { image, scale, top, left } = props;

    const imageRef = useRef<HTMLDivElement>(null);
    const { maskIn, maskOut, move } = useMotion({ imageRef });

    useImperativeHandle(
      ref,
      (): BackingImageRef => ({
        maskIn,
        maskOut,
        move
      })
    );

    return (
      <Styled.Wrapper
        top={top}
        left={left}
        scale={scale}
      >
        <Styled.Image
          ref={imageRef}
          image={image}
        />
      </Styled.Wrapper>
    );
  }
);

BackingImage.displayName = 'BackingImage';
BackingImage.defaultProps = defaultProps;

export default BackingImage;
