import React, { useEffect, useMemo, useState } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';

import { useGlobalStore } from 'store';
import useWindowSize from 'u9/hooks/useWindowSize';
import GTM from 'u9/utils/gtm';
import { isAndroid, isInputFocused } from 'u9/utils/platform';
import { colors } from 'utils/styles/theme';
import { parseUrlQuery } from 'utils/url';

export interface HeadProps {
  title: string;
  description: string;
  ogType: string;
  ogImage: {
    url: string;
  };
}

const defaultViewport = 'width=device-width, initial-scale=1.0, shrink-to-fit=no, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover';

const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  ogType,
  ogImage
}) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [viewport, setViewport] = useState<string>(defaultViewport);
  const { setCountryType } = useGlobalStore(state => state);
  const router = useRouter();
  const windowSize = useWindowSize();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isAndroid() && !isInputFocused()) {
      setViewport(`height=${windowSize.height},width=${windowSize.width},initial-scale=1.0,maximum-scale=1.0,user-scalable=no`);
    }
  }, [windowSize]);

  useEffect(() => {
    /* eslint-disable */
    const onAccept = (event: CustomEvent) => {
      const shouldTrack = !!(event.detail as string[])?.includes('m00');
      if (GTM.isInitialized || !shouldTrack) {
        if (GTM.isInitialized) GTM.hasTrackingConsent = shouldTrack;
        return;
      }

      GTM.initialize(true, true);
      GTM.trackEvent('Global - Cookie', 'Click - Cookie', 'Cookie OK');
    };

    const onCountryDetected = function (e: Event) {
      setCountryType(window['markerFunc']());
    };

    if (isMounted && !parseUrlQuery().debugNoCookieBanner) {
      window.addEventListener('consent.onetrust', onAccept);
      const head = document.querySelector('head');
      head.addEventListener('countryFunc', onCountryDetected, false);

      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j['async'] = true;
        j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;

        // Add nonce for CSP
        j.nonce = head.nonce;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', process.env.GTM_ID);
    }

    /* eslint-enable */
    return () => {
      document?.querySelector('head')?.removeEventListener('countryFunc', onCountryDetected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const fullPath = useMemo(
    () => isMounted && window.location.href || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  const domain = useMemo(
    () => isMounted && window.location.host || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  return (
    <NextHead>
      <title>{title || ''}</title>
      <meta name="description" content={description || ''} />

      <meta name="theme-color" content={colors.blueRibbon} />
      <meta name="msapplication-TileColor" content={colors.blueRibbon} />
      <link rel="icon" href={'/favicon.ico'} />
      <link rel="manifest" href={'/manifest.json'} crossOrigin="use-credentials" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link rel="icon" type="image/png" sizes="32x32" href={'/favicon-32x32.png'} />
      <link rel="icon" type="image/png" sizes="16x16" href={'/favicon-16x16.png'} />
      <link rel="mask-icon" href={'/safari-pinned-tab.svg'} color={colors.blueRibbon} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={'/apple-icon-120x120.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={'/apple-icon-152x152.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={'/apple-icon-180x180.png'}
      />

      <meta property="og:url" content={fullPath} />
      <meta property="og:type" content={ogType || ''} />
      <meta property="og:title" content={title || ''} />
      <meta property="og:description" content={description || ''} />
      {ogImage?.url && <meta property="og:image" content={ogImage.url} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={fullPath} />
      <meta property="twitter:title" content={title || ''} />
      <meta property="twitter:description" content={description || ''} />
      {ogImage?.url && <meta name="twitter:image" content={ogImage.url} />}

      <meta name="viewport" content={viewport} />

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}`}
          height={0}
          width={0}
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </NextHead>
  );
};

export default Head;
