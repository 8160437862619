import styled from 'styled-components';
import { motion } from 'framer-motion';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';
import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${setVh(100)};
  overflow: hidden;
  z-index: ${layout.zIndex.overlays};
`;

export const ButtonCloseWrapper = styled.div<{ isStorytellingFirstPage: boolean; isSecondary: boolean }>`
  position: absolute;
  top: ${({ isStorytellingFirstPage }) => isStorytellingFirstPage ? 100 : 200}rem;
  right: ${layout.headerFooterPadding.mobile}rem;
  cursor: pointer;
  z-index: 10;
  transition: top 0.2s ${({ isStorytellingFirstPage }) => isStorytellingFirstPage ? 0.2 : 0.6}s ease-out;
  transform: translateY(-25%);

  ${({ isSecondary }) => mediaTablet(`
    top: ${layout.headerFooterPadding.tablet + (isSecondary ? 0 : 20)}rem;
    right: ${layout.headerFooterPadding.tablet}rem;
  `)}

  ${({ isSecondary }) => mediaDesktop(`
    top: ${layout.headerFooterPadding.desktop - (isSecondary ? 0 : 40)}rem;
    right: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;
