import React, { useEffect, useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';

import { TRANSITION_COOLDOWN } from 'components/ScrollLockContainer/ScrollLockContainer.gesture';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { ReactComponent as HexagonBackingSvg } from 'svgs/buttonHexaBacking.svg';
import { ReactComponent as ChevronSvg } from 'svgs/chevron.svg';
import { ReactComponent as HexagonSvg } from 'svgs/button-hexagon.svg';
import { useCopyStore, useHotspotOverlayStore } from 'store';
import { isMobileLayout } from 'utils/styles/responsive';

import { useMotion } from './Breadcrumbs.motion';

import * as Styled from './Breadcrumbs.styles';

export const validThemes = ['dark', 'light'] as const;

export interface BreadcrumbsRef {
  show: () => gsap.core.Timeline;
}

export interface BreadcrumbsProps {
  breadcrumbIndex?: number;
  breadcrumbs?: string[];
  children?: any;
  onPrev?: () => void;
  onNext?: () => void;
  theme?: typeof validThemes[number];
}

const defaultProps: Partial<BreadcrumbsProps> = {
  breadcrumbIndex: 0,
  breadcrumbs: [],
  children: null,
  onPrev: () => null,
  onNext: () => null,
  theme: validThemes[0],
};

const Breadcrumbs = React.forwardRef<BreadcrumbsRef, BreadcrumbsProps>(
  ({
    breadcrumbIndex,
    children,
    onPrev,
    onNext,
    theme,
    breadcrumbs,
  }: BreadcrumbsProps,
    ref) => {
    const {
      experience: {
        hotspotsOverlays: { scroll, prev, next },
      },
    } = useCopyStore(state => state.copy);
    const { slideIndex, setSlideIndex, setPreviousSlideIndex, isTransitioning, setTransitioning } = useHotspotOverlayStore();
    const transitionTimeout = useRef(null);

    const handleBreadcrumbClick = index => {
      if (!isTransitioning) {
        const oldIndex = slideIndex;
        setPreviousSlideIndex(oldIndex)
        setSlideIndex(index);
        setTransitioning(true);
        AudioManager.play(SOUNDS_DATA.buttonClick);
        transitionTimeout.current = setTimeout(() => {
          setTransitioning(false);
          transitionTimeout.current = null;
        }, TRANSITION_COOLDOWN)
      }
    };
    const handleOnPrev = () => {
      if (!isTransitioning) {
        setTransitioning(true);
        onPrev();
        transitionTimeout.current = setTimeout(() => {
          setTransitioning(false);
          transitionTimeout.current = null;
        }, TRANSITION_COOLDOWN)
      }
    };
    const handleOnNext = () => {
      if (!isTransitioning) {
        setTransitioning(true);
        onNext();
        transitionTimeout.current = setTimeout(() => {
          setTransitioning(false);
          transitionTimeout.current = null;
        }, TRANSITION_COOLDOWN)
      }
    };
    useEffect(() => {
      return () => {
        if (transitionTimeout.current) {
          clearTimeout(transitionTimeout.current);
          transitionTimeout.current = null;
        }
      }
    }, []);

    const {
      breadcrumbsRef,
      rightWrapperRef,
      scrollDashRef,
      scrollLabelRef,
      hexaBackground,
      hexaMiddleRef,
      triangleRef,
      triangleRotateRef,
      scrollDashWrapperRef,
      show
    } = useMotion();

    const breadcrumbComponents = breadcrumbs.map((crumb, index) => {
      const isActive = index === breadcrumbIndex;
      return (
        <Styled.BreadcrumbWrapper
          key={`Breadcrumb-${crumb}-${index}`}
          ref={el => {
            if (index !== 0) {
              breadcrumbsRef.current[index] = el;
            }
          }}
        >
          {isActive ? (
            <Styled.FilledHexagonWrapper
              onClick={() => handleBreadcrumbClick(index)}
            >
              <Styled.InnerWrapper ref={hexaBackground}>
                <Styled.HexaLeftSide />
                <Styled.HexaMiddleDiv ref={hexaMiddleRef} />
                <Styled.HexaRightSide />
                <Styled.TriangleWrapper
                  ref={triangleRef}
                  style={{ transform: `rotate(${triangleRotateRef.current}deg)` }}
                >
                  <Styled.AnimatedTriangle colorTheme={theme} />
                </Styled.TriangleWrapper>
              </Styled.InnerWrapper>
            </Styled.FilledHexagonWrapper>
          ) : (
            <Styled.BackingHexagonWrapper
              colorTheme={theme}
              onClick={() => handleBreadcrumbClick(index)}
            >
              <HexagonBackingSvg />
            </Styled.BackingHexagonWrapper>
          )}
          {isActive && (
            <Styled.BreadcrumbLabel
              colorTheme={theme}
              ref={el => {
                if (index === 0) {
                  breadcrumbsRef.current[index] = el;
                }
              }}
            >
              {crumb}
            </Styled.BreadcrumbLabel>
          )}
        </Styled.BreadcrumbWrapper>
      );
    });

    const mobileBreadcrumbComponents = (
      <Styled.MobileBreadcrumbs>
        <Styled.BreadcrumbNumber>
          {breadcrumbIndex + 1}/{breadcrumbs.length}
        </Styled.BreadcrumbNumber>
        <HexagonSvg />
        <Styled.MobileBreadcrumbLabel>
          {breadcrumbs[breadcrumbIndex]}
        </Styled.MobileBreadcrumbLabel>
      </Styled.MobileBreadcrumbs>
    );

    const showPrev = breadcrumbIndex > 0;
    const showNext = breadcrumbIndex < breadcrumbs.length - 1;

    useImperativeHandle(
      ref,
      (): BreadcrumbsRef => ({
        show
      })
    );

    return (
      <Styled.Wrapper>
        <Styled.Left>
          {isMobileLayout() ? mobileBreadcrumbComponents : breadcrumbComponents}
        </Styled.Left>
        {children && <Styled.Center>{children}</Styled.Center>}
        {isMobileLayout() ? (
          <Styled.MobileRight>
            {showPrev && (
              <Styled.PrevWrapper onClick={handleOnPrev}>
                <ChevronSvg />
                <Styled.Prev>{prev}</Styled.Prev>
              </Styled.PrevWrapper>
            )}
            {showPrev && showNext && <Styled.Divider />}
            {showNext && (
              <Styled.NextWrapper onClick={handleOnNext}>
                <Styled.Next>{next}</Styled.Next>
                <ChevronSvg />
              </Styled.NextWrapper>
            )}
          </Styled.MobileRight>
        ) : (
          <Styled.Right ref={rightWrapperRef}>
            <Styled.ScrollLabel colorTheme={theme} ref={scrollLabelRef}>
              {scroll}
            </Styled.ScrollLabel>
            <Styled.DashWrapper ref={scrollDashWrapperRef}>
              <Styled.Dash ref={scrollDashRef} />
            </Styled.DashWrapper>
          </Styled.Right>
        )}
      </Styled.Wrapper>
    );
  }
);

Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
