import { useRef, RefObject, useEffect } from 'react';
import gsap from 'gsap';

export interface MotionRefs {
  imageRef?: RefObject<HTMLDivElement>;
}

export enum MoveDirection {
  Up = 'Up',
  Down = 'Down',
  Left = 'Left',
  Right = 'Right',
}

const useMotion = (refs: MotionRefs) => {
  const maskInTl = useRef<gsap.core.Timeline>(null);
  const maskOutTl = useRef<gsap.core.Timeline>(null);
  const moveTl = useRef<gsap.core.Timeline>(null);

  const { imageRef } = refs;

  const maskIn = () => {
    if (maskInTl.current) maskInTl.current.kill();

    maskInTl.current = gsap.timeline({
      onComplete: () => maskInTl.current = null
    });

    maskInTl.current
      .to(
        imageRef.current,
        { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', duration: 1, ease: 'expo.inOut' }
      );

    return maskInTl.current;
  };

  const maskOut = () => {
    if (maskOutTl.current) maskOutTl.current.kill();

    maskOutTl.current = gsap.timeline({
      onComplete: () => maskOutTl.current = null
    });

    maskOutTl.current
      .to(
        imageRef.current,
        { clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)', duration: 0.8, ease: 'expo.inOut' }
      );

    return maskOutTl.current;
  };

  const move = (direction = MoveDirection.Down) => {
    if (moveTl.current) moveTl.current.kill();

    let movingProps = { y: '+=1500px', x: '0' };
    if (direction === MoveDirection.Right) {
      movingProps = { x: '+=1500px', y: '0' };
    } else if (direction === MoveDirection.Left) {
      movingProps = { x: '-=1500px', y: '0' };
    } else if (direction === MoveDirection.Up) {
      movingProps = { x: '0', y: '-=1500px' };
    }

    moveTl.current = gsap.timeline({
      onComplete: () => moveTl.current = null
    });

    moveTl.current
      .to(
        imageRef.current,
        { ...movingProps, duration: 400, ease: 'none' }
      );

    return moveTl.current;
  };

  useEffect(() => {
    return () => {
      if (maskInTl.current) maskInTl.current.kill();
      if (maskOutTl.current) maskOutTl.current.kill();
      if (moveTl.current) moveTl.current.kill();
    }
  }, []);

  return {
    maskIn,
    maskOut,
    move
  }
};

export default useMotion;
