import { useEffect } from 'react';

function useVideoPlayerEvents(
  videoElRef,
  callbacks
) {
  const { onLoaded, onEnded, onCanPlayThrough } = callbacks;

  /**
   * video event handlers
   */
  const handleVideoLoaded = (e: any) => {
    onLoaded && onLoaded(e);
  };

  const handleVideoEnded = (e: any) => {
    onEnded && onEnded(e);
  };

  const handleCanPlayThrough = (e: any) => {
    onCanPlayThrough && onCanPlayThrough(e);
  };

  useEffect(() => {
    /**
     * default event listeners
     */
    const eventListeners = [
      { name: 'loadedmetadata', handler: handleVideoLoaded },
      { name: 'canplaythrough', handler: handleCanPlayThrough },
      { name: 'ended', handler: handleVideoEnded }
    ];
    const videoEl = videoElRef.current;

    if (videoElRef && videoEl) {
      eventListeners.forEach(event => {
        const { name, handler } = event;
        videoElRef.current.addEventListener(name, handler);
      });
    }

    return () => {
      // cleanups
      if (videoEl) {
        eventListeners.forEach(event => {
          const { name, handler } = event;
          videoEl.removeEventListener(name, handler);
        });
        videoEl.pause();
        videoEl.removeAttribute('src');
        videoEl.load();
      }
    };
  }, [videoElRef]); // eslint-disable-line
}

export default useVideoPlayerEvents;
