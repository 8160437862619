import React, { useRef, useImperativeHandle } from 'react';
import gsap from 'gsap';

import SplitContent, { SplitContentRef, SplitContentFontScale } from 'components/SplitContent/SplitContent';
import LabeledPlayer, { LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import InteractiveMedia from 'components/InteractiveMedia/InteractiveMedia';
import InteractiveMediaMessage, { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';
import { FillType, MediaType } from 'components/InteractiveMedia/InteractiveMedia.types';
import { StorytellingPage } from 'store/copy.types';
import { Track } from 'store/playlists.types';
import { useHotspotOverlayStore } from 'store';
import useMotion, { StorytellingContentMotionType } from './StorytellingContent.motion';
import * as Styled from './StorytellingContent.styles';
import { isMobileLayout } from 'utils/styles/responsive';

export interface StorytellingContentRef {
  show?: (type: StorytellingContentMotionType, delay?: number) => gsap.core.Timeline;
  hide?: (type: StorytellingContentMotionType) => gsap.core.Timeline;
}

export interface StorytellingContentProps {
  story: StorytellingPage;
  track: Track;
  storyIndex: number;
  slideIndex: number;
  interactiveThumbnail: string;
  interactiveVideo: string;
  interactiveVideoUseClick: boolean;
  useSlideMotion: boolean;
}

const defaultProps: Partial<StorytellingContentProps> = {
  story: null,
  track: null,
  storyIndex: 0,
  slideIndex: 0,
  interactiveThumbnail: '',
  interactiveVideo: '',
  interactiveVideoUseClick: false,
  useSlideMotion: false
};

const StorytellingContent = React.forwardRef<StorytellingContentRef, StorytellingContentProps>(
  ({
    story,
    track,
    storyIndex,
    interactiveThumbnail,
    interactiveVideo,
    interactiveVideoUseClick
  }: StorytellingContentProps, ref) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const subtitleRef = useRef<SplitContentRef>(null);
    const bodyIntroRef = useRef<SplitContentRef>(null);
    const bodyRef = useRef<SplitContentRef>(null);
    const triviaIntroRef = useRef<SplitContentRef>(null);
    const triviaBodyRef = useRef<SplitContentRef>(null);
    const trackRef = useRef<LabeledPlayerRef>(null);
    const interactiveThumbnailRef = useRef<HTMLDivElement>(null);
    const interactiveMediaWrapperRef = useRef<HTMLDivElement>(null);
    const interactiveMediaMessageRef = useRef<InteractiveMediaMessageRef>(null);

    const mediaIsPlaying = useHotspotOverlayStore(state => state.mediaIsPlaying);

    const hasBodyIntro = !!story.bodyIntro;
    const hasTrivia = !!story.triviaIntro;
    const hasTrack = !!story.musicIntro;
    const hasBothTriviaTrack = hasTrivia && hasTrack;
    const isFirstSlide = storyIndex === 0;
    const showTriviaTrackWrapper = hasBothTriviaTrack && !isMobileLayout();

    const { show, hide } = useMotion(
      {
        wrapperRef,
        subtitleRef,
        bodyIntroRef,
        bodyRef,
        triviaIntroRef,
        triviaBodyRef,
        trackRef,
        interactiveThumbnailRef,
        interactiveMediaWrapperRef,
        interactiveMediaMessageRef
      },
      {
        storyIndex
      }
    );

    useImperativeHandle(
      ref,
      (): StorytellingContentRef => ({
        show,
        hide
      })
    )

    return (
      <Styled.Wrapper ref={wrapperRef}>
        <Styled.ContentWrapper
          hasBodyIntro={hasBodyIntro}
          hasTrivia={hasTrivia}
          hasTrack={hasTrack}
          hasBothTriviaTrack={hasBothTriviaTrack}
          isFirstSlide={isFirstSlide}
        >
          {
            interactiveThumbnail && !isMobileLayout() &&
            <Styled.InteractiveMediaThumbnailWrapper>
              <Styled.InteractiveMediaThumbnail
                ref={interactiveThumbnailRef}
                image={interactiveThumbnail}
              />
            </Styled.InteractiveMediaThumbnailWrapper>
          }
          <Styled.Subtitle isFirstSlide={isFirstSlide}>
            <SplitContent
              ref={subtitleRef}
              copy={story.subtitle}
              fontScale={SplitContentFontScale.Heading4}
            />
          </Styled.Subtitle>
          <Styled.Body
            isFirstSlide={isFirstSlide}
            hasTrivia={hasTrivia}
            hasTrack={hasTrack}
          >
            {
              hasBodyIntro &&
              <Styled.BodyIntro>
                <SplitContent
                  ref={bodyIntroRef}
                  copy={story.bodyIntro}
                  fontScale={SplitContentFontScale.LeadBody}
                />
              </Styled.BodyIntro>
            }
            <SplitContent
              ref={bodyRef}
              copy={story.body}
              fontScale={isFirstSlide ? SplitContentFontScale.LeadBody : SplitContentFontScale.Body}
            />
          </Styled.Body>
          {
            showTriviaTrackWrapper &&
            <Styled.TriviaTrackWrapper>
              <Styled.Trivia>
                <Styled.TriviaIntro>
                  <SplitContent
                    ref={triviaIntroRef}
                    copy={story.triviaIntro}
                    fontScale={SplitContentFontScale.LeadBody}
                  />
                </Styled.TriviaIntro>
                <Styled.TriviaBody>
                  <SplitContent
                    ref={triviaBodyRef}
                    copy={story.trivia}
                    fontScale={SplitContentFontScale.Body}
                  />
                </Styled.TriviaBody>
              </Styled.Trivia>
              <Styled.Track mediaIsPlaying={mediaIsPlaying} hasMarginTop={hasBothTriviaTrack}>
                <LabeledPlayer
                  ref={trackRef}
                  label={story.musicIntro}
                  track={track}
                  pageIndex={storyIndex}
                  hasBigLabel
                />
              </Styled.Track>
            </Styled.TriviaTrackWrapper>
          }
          {hasTrivia && !showTriviaTrackWrapper && (
            <Styled.Trivia>
              <Styled.TriviaIntro>
                <SplitContent
                  ref={triviaIntroRef}
                  copy={story.triviaIntro}
                  fontScale={SplitContentFontScale.LeadBody}
                />
              </Styled.TriviaIntro>
              <Styled.TriviaBody>
                <SplitContent
                  ref={triviaBodyRef}
                  copy={story.trivia}
                  fontScale={SplitContentFontScale.Body}
                />
              </Styled.TriviaBody>
            </Styled.Trivia>
          )}
          {hasTrack && !showTriviaTrackWrapper && (
            <Styled.Track mediaIsPlaying={mediaIsPlaying} hasMarginTop={hasBothTriviaTrack}>
              <LabeledPlayer
                ref={trackRef}
                label={story.musicIntro}
                track={track}
                pageIndex={storyIndex}
                hasBigLabel
              />
            </Styled.Track>
          )}
          {
            interactiveVideo && (
              <Styled.InteractiveMediaWrapper ref={interactiveMediaWrapperRef}>
                {
                  interactiveThumbnail && isMobileLayout() &&
                  <Styled.InteractiveMediaThumbnailWrapper>
                    <Styled.InteractiveMediaThumbnail
                      ref={interactiveThumbnailRef}
                      image={interactiveThumbnail}
                    />
                  </Styled.InteractiveMediaThumbnailWrapper>
                }
                {
                  isMobileLayout() &&
                  <Styled.InteractiveMediaMessageWrapper>
                    <InteractiveMediaMessage
                      ref={interactiveMediaMessageRef}
                      useClick={interactiveVideoUseClick}
                    />
                  </Styled.InteractiveMediaMessageWrapper>
                }
                <InteractiveMedia
                  type={MediaType.Video}
                  videoProps={{
                    videoSrc: interactiveVideo,
                    fill: isMobileLayout() ? FillType.Cover : FillType.Contain,
                    useClick: interactiveVideoUseClick,
                    hasAudio: interactiveVideoUseClick
                  }}
                />
              </Styled.InteractiveMediaWrapper>
            )
          }
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    );
  }
)

StorytellingContent.defaultProps = defaultProps;

export default StorytellingContent;
