import { rgba } from 'polished';
import styled from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

const progressBarMargin = 60;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  height: ${layout.playPauseSize}rem;
  margin-top: ${-1 * layout.playPauseSize}rem;
  top: ${2 * layout.playPauseSize}rem;
  padding: 0 ${layout.headerFooterPadding.mobile}rem;

  ${mediaTablet(`
    height: 100%;
    flex: 0.75;
    padding: 0;
    top: 0;
    margin-top: 0;
  `)}

  ${mediaDesktop(`
    flex: 0.5;
  `)}
`;

export const PlayPauseWrapper = styled.div`
  margin-right: ${progressBarMargin}rem;
`;

export const ProgressBar = styled.div`
  flex: 1;
  height: 8rem;
  background: ${rgba(colors.white, 0.2)};
  cursor: pointer;
  position: relative;
  transform: translateZ(0);

  > div {
    width: 100%;
    height: 100%;
    background: ${colors.roti};
    transform: scaleX(0) translateZ(0);
    transform-origin: left center;
    cursor: pointer;
    transition: transform 0.15s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 400%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  margin-left: ${progressBarMargin}rem;
  ${({ theme: { locale } }) => setTypography('link1', locale)}
  transform: translateZ(0);

  span {
    width: 80rem;

    &:nth-of-type(2) {
      opacity: 0.2;
    }
  }

  div {
    background: ${rgba(colors.white, 0.2)};
    width: 1px;
    height: 1em;
    margin: 0.125em 30rem 0;
    left: 0.125em;
    position: relative;
  }
`;

export const Fullscreen = styled.div`
  height: 40rem;
  cursor: pointer;
  margin-left: 40rem;

  ${hover(`
    opacity: ${layout.hoverOpacity};
  `)}

  svg {
    height: 100%;
  }
`;
