import { useCallback, useEffect, useMemo, useRef } from 'react';
import gsap from 'gsap';

import { useHotspotOverlayStore } from 'store';
import { isTablet } from 'u9/utils/platform';

export const useMotion = () => {
  const timelineRef = useRef<gsap.core.Timeline>(null);
  const dashTimelineRef = useRef<gsap.core.Timeline>(null);

  const breadcrumbsRef = useRef<HTMLDivElement[]>([]);
  const rightWrapperRef = useRef<HTMLDivElement>(null);
  const scrollLabelRef = useRef<HTMLDivElement>(null);
  const scrollDashRef = useRef<HTMLDivElement>(null);
  const hexaBackground = useRef<HTMLDivElement>(null);
  const hexaMiddleRef = useRef<HTMLDivElement>(null);
  const triangleRef = useRef<HTMLDivElement>(null);
  const scrollDashWrapperRef = useRef<HTMLDivElement>(null);
  const triangleRotateRef = useRef(-120);
  const triangleLoopInterval = useRef<number>(null);

  const { slideIndex, pagesCount } = useHotspotOverlayStore();

  const startTriangleLoop = () => {
    triangleLoopInterval.current = window.setInterval(() => {
      if (triangleRef.current) {
        triangleRotateRef.current = (triangleRotateRef.current + (360 / 6)) % 360;
        triangleRef.current.style.transform = `rotate(${triangleRotateRef.current}deg)`;
      }
      // Use the same duration as in the spinHexagonIcon mixin
    }, 4000 / 6);
  };

  const show = () => {
    if (timelineRef.current) timelineRef.current.kill();

    timelineRef.current = gsap.timeline({
      onComplete: () => {
        timelineRef.current = null;
      },
    });

    const startingWidth = isTablet() ? 55 : 80;

    if (breadcrumbsRef.current?.length) {
      timelineRef.current
        .set(breadcrumbsRef.current, { visibility: 'hidden' })
        .set(scrollLabelRef.current, { visibility: 'hidden' })
        .fromTo(
          breadcrumbsRef.current.slice(0, 1),
          { visibility: 'hidden', x: 125 },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.4,
            stagger: 0.1,
            ease: 'power3.out',
          },
          0.2
        )
        .fromTo(
          scrollLabelRef.current,
          {
            visibility: 'hidden',
            x: 100,
          },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.4,
            ease: 'power2.inOut',
          },
          0.5
        )
        .fromTo(
          hexaBackground.current,
          {
            'min-width': `${startingWidth}rem`,
          },
          {
            'min-width': `${startingWidth * 4}rem`,
            duration: 0.5,
            ease: 'power3.inOut',
          },
          0
        )
        .fromTo(
          hexaMiddleRef.current,
          { flex: 0.7 },
          { flex: 0.7, duration: 0.5, ease: 'power3.inOut' },
          0.2
        )
        .addLabel('close', 0.7)
        .fromTo(
          hexaBackground.current,
          { 'min-width': `${startingWidth * 4}rem` },
          {
            'min-width': `${startingWidth}rem`,
            duration: 0.4,
            ease: 'power3.out',
          },
          'close+=0.2'
        )
        .fromTo(
          hexaMiddleRef.current,
          { flex: 0.7 },
          { flex: 0.4, duration: 0.2, ease: 'power3.out' },
          'close+=0.2'
        )
        .fromTo(
          breadcrumbsRef.current.slice(1),
          { visibility: 'hidden', x: 125 },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.6,
            stagger: 0.1,
            ease: 'power3.out',
          },
          'close+=0.2'
        )
        .set(scrollDashRef.current, { x: 0 }, 'close')
        .fromTo(
          scrollDashWrapperRef.current,
          { visibility: 'hidden', rotate: '-90deg' },
          {
            visibility: 'visible',
            rotate: 0,
            duration: 0.4,
            ease: 'power3.inOut',
          },
          '>'
        )
        .add(slideDash(), '> 0.2')
        .call(startTriangleLoop, null, '<-1.5');
    }

    return timelineRef.current;
  };

  const isLastPage = useMemo(() => slideIndex === pagesCount - 1, [slideIndex, pagesCount]);

  useEffect(() => {
    return () => {
      if (triangleLoopInterval.current) window.clearInterval(triangleLoopInterval.current);
      if (timelineRef.current) timelineRef.current.kill();
      if (dashTimelineRef.current) dashTimelineRef.current.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slideDash = useCallback(() => {
    if (dashTimelineRef.current) dashTimelineRef.current.kill();

    dashTimelineRef.current = gsap.timeline({
      onComplete: () => {
        dashTimelineRef.current = null;
      }
    });

    if (isLastPage) {
      dashTimelineRef.current.to(
        scrollDashRef.current,
        { x: '0%', duration: 0.4, ease: 'power2.out' }
      );

      return dashTimelineRef.current;
    }

    dashTimelineRef.current.fromTo(
      scrollDashRef.current,
      { x: 0 },
      { x: '100%', duration: 0.75, ease: 'linear' }
    )
      .fromTo(
        scrollDashRef.current,
        { x: '-100%' },
        { x: '100%', repeat: Infinity, duration: 1.5, ease: 'linear' },
        '>'
      );

    return dashTimelineRef.current;
  }, [isLastPage]);

  useEffect(() => {
    // Don't start checking for page updates until the entrance has finished
    if (triangleLoopInterval.current !== null) slideDash();
  }, [isLastPage, slideDash]);

  return {
    breadcrumbsRef,
    rightWrapperRef,
    scrollLabelRef,
    scrollDashRef,
    hexaBackground,
    hexaMiddleRef,
    triangleRef,
    triangleRotateRef,
    scrollDashWrapperRef,
    show
  };
};
