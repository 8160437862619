import styled from 'styled-components';
import { motion } from 'framer-motion';
import { setVh } from 'utils/styles/mixins';
import { layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: ${setVh(100)};
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${layout.zIndex.overlays};
  overflow: hidden;
`;
