import { useRef, RefObject, useEffect } from 'react';
import gsap from 'gsap';

import { useHotspotOverlayStore } from 'store';
import { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';
import { ButtonRef } from 'components/Button/Button';
import { OverlayTitleRef } from '../OverlayTitle/OverlayTitle';
import { SplitContentRef } from 'components/SplitContent/SplitContent';
import { PlaylistsRef } from 'components/Playlists/Playlists';
import { isMobileLayout } from 'utils/styles/responsive';

export interface MotionRefs {
  wrapperRef?: RefObject<HTMLDivElement>;
  closeRef?: RefObject<ButtonCloseRef>;
  contentLeftRef?: RefObject<HTMLDivElement>;
  overlayTitleRef?: RefObject<OverlayTitleRef>;
  headingRef?: RefObject<SplitContentRef>;
  bodyRef?: RefObject<SplitContentRef>;
  playlistsRef?: RefObject<PlaylistsRef>;
  actionButtonRefs?: RefObject<ButtonRef[]>;
}

const useMotion = (refs: MotionRefs) => {
  const animateInTl = useRef<gsap.core.Timeline>(null);
  const animateOutTl = useRef<gsap.core.Timeline>(null);
  const headingTimeout = useRef<number>(null);
  const contentTimeout = useRef<number>(null);

  const {
    wrapperRef,
    closeRef,
    contentLeftRef,
    overlayTitleRef,
    headingRef,
    bodyRef,
    playlistsRef,
    actionButtonRefs
  } = refs;

  const setBlackOverlayTransition = useHotspotOverlayStore(state => state.setBlackOverlayTransition);

  const animateIn = (withSliding = true) => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    if (isMobileLayout()) {
      if (withSliding) {
        animateInTl.current
          .set(wrapperRef.current, { autoAlpha: 1 })
          .call(() => setBlackOverlayTransition('in'), null, 0.3)
          .fromTo(
            wrapperRef.current,
            { y: '100%' },
            { y: 0, duration: 1.2, ease: 'power4.inOut' },
            0
          )
          .fromTo(
            contentLeftRef.current,
            { y: '100%' },
            { y: 0, duration: 1, ease: 'power4.inOut' },
            0.2
          )
      } else {
        animateInTl.current
          .set([wrapperRef.current, contentLeftRef.current], { y: 0 })
          .fromTo(
            wrapperRef.current,
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 1, ease: 'power4.inOut' },
            0
          );
      }
    } else {
      if (withSliding) {
        animateInTl.current
          .set(wrapperRef.current, { autoAlpha: 1 })
          .call(() => setBlackOverlayTransition('in'), null, 0.3)
          .fromTo(
            wrapperRef.current,
            { x: '100%' },
            { x: 0, duration: 1.2, ease: 'power4.inOut' },
            0
          )
          .fromTo(
            contentLeftRef.current,
            { x: '100%' },
            { x: 0, duration: 1, ease: 'power4.inOut' },
            0.2
          )
      } else {
        animateInTl.current
          .fromTo(
            wrapperRef.current,
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 0.5, ease: 'power4.inOut' },
            0
          );
      }
    }

    actionButtonRefs.current.forEach((button, index) => {
      if (button) {
        animateInTl.current.add(button.show(), 0.55 * (index + 1))
      }
    });

    animateInTl.current
      .add(overlayTitleRef.current.show(), 0.8)
      .add(closeRef.current.show(), 1.2)

    if (headingTimeout.current) window.clearTimeout(headingTimeout.current);
    headingTimeout.current = window.setTimeout(() => {
      headingTimeout.current = null;
      animateInTl.current
        .call(() => {
          headingRef.current.slideInLines();
        }, null, 0);
    }, 500);

    if (contentTimeout.current) window.clearTimeout(contentTimeout.current);
    contentTimeout.current = window.setTimeout(() => {
      contentTimeout.current = null;
      animateInTl.current
        .call(() => {
          bodyRef.current.slideInGroup();
          playlistsRef.current.animateIn();
        }, null, 0);
    }, 700);
  };

  const animateOut = (cb = () => null) => {
    if (animateOutTl.current) animateOutTl.current.kill();

    animateOutTl.current = gsap.timeline({
      onComplete: () => {
        cb();
        animateOutTl.current = null;
      }
    });

    animateOutTl.current
      .call(() => setBlackOverlayTransition('out'), null, 0)
      .add(closeRef.current.show().reversed(true), 0)

    if (isMobileLayout()) {
      animateOutTl.current
        .fromTo(
          wrapperRef.current,
          { y: 0 },
          { y: '100%', duration: 0.9, ease: 'expo.inOut' },
          0
        )
        .fromTo(
          contentLeftRef.current,
          { y: 0 },
          { y: '100%', duration: 1.3, ease: 'expo.inOut' },
          0.2
        );
    } else {
      animateOutTl.current
        .fromTo(
          wrapperRef.current,
          { x: 0 },
          { x: '100%', duration: 1.3, ease: 'expo.inOut' },
          0
        )
        .fromTo(
          contentLeftRef.current,
          { x: 0 },
          { x: '100%', duration: 1, ease: 'expo.inOut' },
          0
        );
    }

    animateOutTl.current.add(playlistsRef.current.animateOut());
  };

  useEffect(() => {
    return () => {
      if (animateInTl.current) animateInTl.current.kill();
      if (animateOutTl.current) animateOutTl.current.kill();
      if (headingTimeout.current) window.clearTimeout(headingTimeout.current);
      if (contentTimeout.current) window.clearTimeout(contentTimeout.current);
    };
  }, []);

  return {
    animateIn,
    animateOut
  }
};

export default useMotion;
