export const fonts = `
  /*
  * Circular Std
  */
  @font-face {
    font-family: 'CircularStd';
    font-weight: 900;
    font-style: italic;
    font-display: block;
    src: url('/fonts/CircularStd/CircularStd-BlackItalic.woff2') format('woff2'),
      url('/fonts/CircularStd/CircularStd-BlackItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/CircularStd/CircularStd-Bold.woff2') format('woff2'),
      url('/fonts/CircularStd/CircularStd-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/CircularStd/CircularStd-Medium.woff2') format('woff2'),
      url('/fonts/CircularStd/CircularStd-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url('/fonts/CircularStd/CircularStd-Book.woff2') format('woff2'),
      url('/fonts/CircularStd/CircularStd-Book.woff') format('woff');
  }

  /*
  * Presto
  */
  @font-face {
    font-family: 'Presto';
    font-style: normal;
    font-weight: 400;
    font-stretch: expanded;
    font-display: block;
    src: url('/fonts/Presto/PrestoExtended.woff2') format('woff2'),
      url('/fonts/Presto/PrestoExtended.woff') format('woff');
  }

  /*
  * Do Hyeon -- Korean only
  */
  @font-face {
    font-family: 'DoHyeon';
    font-display: block;
    src: url('/fonts/DoHyeon/DoHyeon-Regular.woff2') format('woff2'),
      url('/fonts/DoHyeon/DoHyeon-Regular.woff') format('woff');
  }
`;

export default fonts;
