import { useEffect } from 'react';

import { useAudioStore } from 'store';
import { SOUNDS_DATA, SoundType } from 'services/audioManager/audioManager.data';
import AudioManager, { DEFAULT_FADE_DURATION } from 'services/audioManager/audioManager.service';

export interface UseAudioProps {
  hasAudio?: boolean;
  held?: boolean;
}

const useAudio = ({
  hasAudio,
  held
}: UseAudioProps) => {
  const { currentLoopId } = useAudioStore(state => state);

  useEffect(() => {
    if (hasAudio) {
      if (currentLoopId) AudioManager.setVolume(SOUNDS_DATA[currentLoopId], held ? 0 : null, held ? 0 : DEFAULT_FADE_DURATION);
      AudioManager.stopType(SoundType.BGM);
    }

    if (held) AudioManager.play(hasAudio ? SOUNDS_DATA.buttonClick : SOUNDS_DATA.behindBurnLong);
    else if (!hasAudio) AudioManager.stop(SOUNDS_DATA.behindBurnLong, 0.2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [held]);
};

export default useAudio;
