import { RefObject } from 'react';
import gsap from 'gsap';

const animateInMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });
  timeline
    .to(
      mainContentRef.current,
      { top: '45%', height: '55%', duration: 0.6, ease: 'expo.inOut' }
    );

  return timeline;
};

const slideInMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });
  timeline
    .to(
      mainContentRef.current,
      { top: 0, height: '100%', duration: 0.6, ease: 'expo.inOut' }
    );

  return timeline;
};

const slideOutMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });

  timeline
    .to(
      mainContentRef.current,
      { top: '45%', height: '55%', duration: 0.6, ease: 'expo.inOut' }
    );

  return timeline;
};

const showEntryImage = (entryImageRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });
  timeline
    .to(
      entryImageRef.current,
      { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.6, ease: 'expo.inOut' }
    );

  return timeline;
};

export default {
  animateInMainContent,
  slideInMainContent,
  slideOutMainContent,
  showEntryImage
};
