import React from 'react';

import { useHotspotOverlayStore } from 'store';
import * as Styled from './BlackOverlay.styles';

export interface BlackOverlayProps { }

const defaultProps: Partial<BlackOverlayProps> = {};

const BlackOverlay = () => {
  const blackOverlayTransition = useHotspotOverlayStore(state => state.blackOverlayTransition);

  const fadeMotion = {
    initial: { opacity: 0 },
    animate: {
      opacity: blackOverlayTransition === 'in' ? 0.7 : 0
    },
    transition: { duration: 0.5 }
  }

  return (
    <Styled.Wrapper
      {...fadeMotion}
    />
  );
};

BlackOverlay.defaultProps = defaultProps;

export default BlackOverlay;
