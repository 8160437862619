import { RefObject } from 'react';
import gsap from 'gsap';

const animateInMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });
  timeline
    .to(
      mainContentRef.current,
      { left: '50%', duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

const slideInMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });
  timeline
    .to(
      mainContentRef.current,
      { left: 0, duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

const slideOutMainContent = (mainContentRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });

  timeline
    .to(
      mainContentRef.current,
      { left: '50%', duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

const showEntryImage = (entryImageRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });

  timeline
    .fromTo(
      entryImageRef.current,
      { opacity: 0, scale: 2, },
      { opacity: 1, scale: 1, duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

const shrinkBreadcrumbWrapper = (breadcrumbWrapperRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });

  timeline
    .to(
      breadcrumbWrapperRef.current,
      { width: '50vw', duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

const expandBreadcrumbWrapper = (breadcrumbWrapperRef: RefObject<HTMLDivElement>) => {
  let timeline = gsap.timeline({
    onComplete: () => {
      timeline = null;
    }
  });

  timeline
    .to(
      breadcrumbWrapperRef.current,
      { width: '100%', duration: 1, ease: 'expo.inOut' }
    );

  return timeline;
};

export default {
  animateInMainContent,
  slideInMainContent,
  slideOutMainContent,
  shrinkBreadcrumbWrapper,
  expandBreadcrumbWrapper,
  showEntryImage
};
