import React, { useRef } from 'react';

import { useAudioStore } from 'store';

import useVideoPlayerEvents from './VideoPlayer.events';
import * as Styled from './VideoPlayer.styles';

interface VideoOptions {
  autoPlay: boolean;
  volume: number;
  muted: boolean;
  playsInline: boolean;
  loop: boolean;
  disableRemotePlayback: boolean;
}

export interface VideoPlayerProps {
  src: string;
  options?: Partial<VideoOptions>;
  onLoaded?: (e: any) => void,
  onEnded?: (e: any) => void,
  onCanPlayThrough?: (e: any) => void
}

const defaultProps = {
  src: '',
  options: {},
  onLoaded: () => null,
  onEnded: () => null,
  onCanPlayThrough: () => null
};

const VideoPlayer = ({
  src,
  options,
  onLoaded,
  onEnded,
  onCanPlayThrough
}: VideoPlayerProps) => {
  const { isMuted } = useAudioStore();
  const videoRef = useRef<HTMLVideoElement>(null);

  useVideoPlayerEvents(
    videoRef,
    {
      onLoaded,
      onEnded,
      onCanPlayThrough
    }
  );

  const videoOptions: VideoOptions = {
    autoPlay: false,
    volume: 1,
    playsInline: true,
    loop: true,
    disableRemotePlayback: true,
    ...options,
    muted: isMuted
  };

  return (
    <Styled.Wrapper
      ref={videoRef}
      src={`${src}#t=0.001`} // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
      {...videoOptions}
    />
  )
}

VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;
