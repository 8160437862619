import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { AnimatePresence } from 'framer-motion';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import InteractiveMediaMessage from '../InteractiveMediaMessage/InteractiveMediaMessage';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager, { DEFAULT_FADE_DURATION } from 'services/audioManager/audioManager.service';
import { useAudioStore } from 'store';
import { useIntersectionObserver } from 'u9/hooks';
import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import { FillType } from './../InteractiveMedia.types';
import useGesture from '../InteractiveMedia.gesture';
import useAudio from './InteractiveVideo.audio';
import useAnalytics from './InteractiveVideo.analytics';

import * as Styled from './InteractiveVideo.styles';

export interface InteractiveVideoRef { }

export interface InteractiveVideoProps {
  fill?: FillType;
  videoSrc?: string;
  useClick?: boolean;
  hasAudio?: boolean;
  withMessage?: boolean;
}

const defaultProps: Partial<InteractiveVideoProps> = {
  fill: FillType.Cover,
  videoSrc: null,
  useClick: false,
  hasAudio: false,
  withMessage: false,
};

const InteractiveVideo = React.forwardRef<InteractiveVideoRef, InteractiveVideoProps>(
  ({
    fill,
    videoSrc,
    useClick,
    hasAudio,
    withMessage
  }: InteractiveVideoProps, ref) => {
    const wrapperElRef = useRef(null);
    const interactionOverlayRef = useRef<HTMLDivElement>(null);
    const { held, setHeld } = useGesture(interactionOverlayRef, useClick);

    const [wrapperRef, isInViewport] = useIntersectionObserver();
    const { currentLoopId } = useAudioStore(state => state);

    const onOutsideClick = (event: MouseEvent | TouchEvent) => {
      if (wrapperElRef.current && !wrapperElRef.current.contains(event.target)) {
        setHeld(false);
      }
    };

    useAnalytics(held);
    useAudio({ hasAudio, held });

    useEffect(() => {
      wrapperRef(wrapperElRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperElRef.current]);

    useEffect(() => {
      if (!isInViewport) setHeld(false);
    }, [setHeld, isInViewport]);

    useEffect(() => {
      addEventListeners(window, 'click touchend', onOutsideClick);

      return () => {
        removeEventListeners(window, 'click touchend', onOutsideClick);

        // Reset the current loop's default volume
        if (currentLoopId) AudioManager.setVolume(SOUNDS_DATA[currentLoopId], null, DEFAULT_FADE_DURATION);
      }
    });

    useImperativeHandle(
      ref,
      (): InteractiveVideoRef => ({})
    );

    const fadeMotion = {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.2 }
    };

    return (
      <Styled.Wrapper ref={wrapperElRef}>
        {
          withMessage &&
          <Styled.InteractiveMediaMessageWrapper>
            <InteractiveMediaMessage
              useClick={useClick}
            />
          </Styled.InteractiveMediaMessageWrapper>
        }
        <AnimatePresence>
          {
            held &&
            <Styled.VideoOverlay {...fadeMotion}>
              <Styled.VideoOverlayBackground />
              <Styled.VideoOverlayWrapper isContain={fill === 'contain'}>
                <VideoPlayer
                  src={videoSrc}
                  options={{ autoPlay: true, loop: true, muted: !hasAudio }}
                />
              </Styled.VideoOverlayWrapper>
            </Styled.VideoOverlay>
          }
        </AnimatePresence>
        <Styled.InteractionOverlay
          ref={interactionOverlayRef}
          isContain={fill === 'contain'}
        />
      </Styled.Wrapper>
    );
  }
);

InteractiveVideo.displayName = 'InteractiveVideo';
InteractiveVideo.defaultProps = defaultProps;

export default InteractiveVideo;
