export const copyToClipboard = (string: string) => {
  const el = document.createElement('textarea');
  el.value = string;
  document.body.appendChild(el);

  el.select();
  el.setSelectionRange(0, el.value.length - 1);
  navigator.clipboard.writeText(el.value);

  document.body.removeChild(el);
};
