import React, { useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useCopyStore, useGlobalStore } from 'store';

import Button, { ButtonRef } from 'components/Button/Button';
import ButtonClose, { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';
import useAuth from 'hooks/useAuth';
import { login } from 'utils/api';
import { ColorNames } from 'utils/styles/theme';
import OverlayTitle, { OverlayTitleRef } from './../OverlayTitle/OverlayTitle';

import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import * as Styled from './AuthPromptOverlay.styles';

export const validTypes = ['connect', 'personalized'] as const;

const bezierEase = [0.5, 0, .14, 1];
const backgroundMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 0.6, transition: { duration: 0.2 } },
  exit: { opacity: 0, transition: { duration: 0.2, delay: 0.1 }, },
};

const whiteBgMotionProps = {
  initial: { height: '0%', y: -100 },
  animate: { height: '100%', y: 0, transition: { duration: 0.66, ease: bezierEase } },
  exit: { height: '0%', y: -100, transition: { duration: 0.66, ease: bezierEase, delay: 0.1 } },
};

export interface AuthPromptOverlayProps {
  isVisible?: boolean;
  type?: typeof validTypes[number];
}

const AuthPromptOverlay = ({
  isVisible,
  type = 'connect',
}: AuthPromptOverlayProps) => {
  const accessToken = useAuth();
  const { copy } = useCopyStore(state => state);
  const { toggleAuthPromptVisible } = useGlobalStore(state => state);
  const headingRef = useRef(null);
  const headerRef = useRef(null);
  const btnWrapperRef = useRef(null);
  const titleRef = useRef<OverlayTitleRef>(null);
  const bodyRef = useRef(null);
  const closeRef = useRef<ButtonCloseRef>(null);
  const ctaRef = useRef<ButtonRef>(null);
  const timelineRef = useRef<gsap.core.Timeline>(null);

  useEffect(() => {
    if (timelineRef.current) timelineRef.current.kill();
    timelineRef.current = gsap.timeline({});

    if (isVisible) {
      const mySplitText = new SplitText(headingRef.current, { type: 'lines' });
      const lines = mySplitText.lines; //an array of all the divs that wrap each character
      const size = lines.length;

      // Header animation
      timelineRef.current.add(titleRef.current.show(), 10 / 30)
        //Close btn animation
        .add(closeRef.current.show(), 17 / 30)
      // Heading animation
      lines.forEach((el, i) => {
        timelineRef.current.fromTo(
          el,
          {
            y: 50,
            autoAlpha: 0
          },
          {
            y: '0rem',
            duration: 9 / 30,
            autoAlpha: 1,
            ease: 'power3.out'
          },
          17 / 30 + i * 2 / 30
        )
      })
      // Body animation
      timelineRef.current
        .fromTo(bodyRef.current,
          {
            y: 50,
            autoAlpha: 0
          },
          {
            y: '0rem',
            duration: 9 / 30,
            autoAlpha: 1,
            ease: 'power3.out',
          },
          17 / 30 + size * 2 / 30
        )
        .add(ctaRef.current.show(), 17 / 30 + (size + 1) * 2 / 30)
    } else {
      if (headerRef.current && btnWrapperRef.current && bodyRef.current && headingRef.current && closeRef.current) {
        timelineRef.current
          .add(closeRef.current.show().reversed(true))
          .addLabel('remove', `-=${6 / 30}`)
          .to(headerRef.current, { opacity: 0, duration: 6 / 30 }, 'remove')
          .to(btnWrapperRef.current, { opacity: 0, duration: 6 / 30 }, 'remove')
          .to(bodyRef.current, { opacity: 0, duration: 6 / 30 }, 'remove')
          .to(headingRef.current, { opacity: 0, duration: 6 / 30 }, 'remove')
      }
    }
  }, [isVisible]);

  useEffect(() => {
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
        timelineRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (accessToken && isVisible) toggleAuthPromptVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  if (!copy.experience) return null;

  const authPromptCopy = copy.experience.authPrompt[type];

  const onCtaClick = () => {
    login();
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Styled.Wrapper>
          <Styled.BlackOverlay {...backgroundMotionProps} />
          <Styled.PanelWrapper>
            <Styled.WhiteBackground {...whiteBgMotionProps} />
            <Styled.Header ref={headerRef}>
              <OverlayTitle
                title={authPromptCopy.subHeading}
                color={ColorNames.black}
                hasWorldsIcon={false}
                ref={titleRef}
              />
              <ButtonClose
                onClick={toggleAuthPromptVisible}
                wrapped={true}
                ref={closeRef}
              />
            </Styled.Header>
            <Styled.Heading
              ref={headingRef}
              dangerouslySetInnerHTML={{ __html: authPromptCopy.title }}
            />
            <Styled.Body ref={bodyRef}>{authPromptCopy.description}</Styled.Body>
            <Styled.ButtonWrapper ref={btnWrapperRef}>
              <Button
                label={authPromptCopy.cta}
                color={ColorNames.mountainMeadow}
                isBig={true}
                onClick={onCtaClick}
                showFrom='right'
                ref={ctaRef}
              />
            </Styled.ButtonWrapper>
          </Styled.PanelWrapper>
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default AuthPromptOverlay;
