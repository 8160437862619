import styled from 'styled-components';
import { motion } from 'framer-motion';

import { hover } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';

const aspectRatio = 115 / 80;
const heightDesktop = layout.audioToggleHeight.desktop;
const heightMobile = layout.audioToggleHeight.mobile;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  cursor: pointer;
  width: calc(${heightMobile}rem * ${aspectRatio});

  ${hover(`
    opacity: ${layout.hoverOpacity};
  `)}

  ${mediaTablet(`
    width: calc(${heightDesktop}rem * ${aspectRatio});
  `)}
`;

export const Bar = styled(motion.span)`
  display: inline-block;
  width: 5%;
  height: 100%;
  border-radius: 100rem;
  background-color: ${colors.white};
`;
