import styled from 'styled-components';

import { fullscreen, setVh } from 'utils/styles/mixins';
import { mediaTablet, mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  ${mediaTablet(`
    ${fullscreen()}
  `)}
`;

export const DarkBackground = styled.div`
  ${fullscreen()}
  background-color: ${colors.black};
  opacity: 0;
`;

export const EntryImage = styled.div<{ image: string }>`
  width: 100vw;
  height: ${setVh(45)};
  clip-path: inset(0% 0% 100% 0%);
  background-image: url(${props => props.image});
  background-size: cover;

  ${mediaTablet(`
    ${fullscreen()}
    width: 50vw;
    opacity: 0;
    transform: scale(1.4);
    clip-path: unset;
  `)}
`;

export const MainContent = styled.div`
  ${fullscreen()}
  top: 100%;
  background-color: ${colors.blueRibbon};

  ${mediaTablet(`
    top: unset;
    left: 100%;
    padding: 220rem;
  `)}
`;

export const OverlayTitleWrapper = styled.div`
  position: absolute;
  top: 200rem;
  left: ${layout.headerFooterPadding.mobile}rem;
  width: 100%;
  z-index: 3;

  ${mediaTablet(`
    top: ${layout.headerFooterPadding.tablet + 10}rem;
    left: 220rem;
  `)}
`;

export const BreadcrumbWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  ${mediaTablet(`
    width: 50vw;
    padding-left: 220rem;
    padding-right: 120rem;
    padding-bottom: ${layout.headerFooterPadding.tablet}rem;
    z-index: 3;
  `)}
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${mediaTablet(`
    width: 100rem;
    height: ${setVh(100)};
    background-color: ${colors.roti};
  `)}
`;

export const InteractiveMediaMessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 60rem;
  transform: translateX(-50%);

  ${mediaTablet(`
    bottom: ${layout.headerFooterPadding.tablet - 12}rem;
  `)}

  ${mediaDesktop(`
    bottom: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;
