import { useRef, useEffect } from 'react';
import { useClubhouseStore } from 'store';
import gtm from 'u9/utils/gtm';

const useGtm = (storytellingIndex: number) => {
  const isFirstRender = useRef(true);

  const { currentClubhouse, hasBeenOnboarded, currentHotspot } = useClubhouseStore(state => state);
  const clubhouseName = currentClubhouse === 'seoul' ? 'LCK' : currentClubhouse === 'berlin' ? 'LEC' : 'LPL';

  useEffect(() => {
    if (!isFirstRender.current) {
      if (storytellingIndex === 0) {
        if (!hasBeenOnboarded) {
          gtm.trackEvent(`${clubhouseName} Storytelling : Act 1 (FT user)`, 'Scroll', `${clubhouseName} Storytelling Act 1 (FT User) - scroll`);
        }
        else {
          gtm.trackEvent(`${clubhouseName} Storytelling Act 1 - overlay`, 'Scroll', `${clubhouseName} Storytelling Act 1 - scroll`);
        }
      } else {
        gtm.trackEvent(`${clubhouseName} Storytelling Act ${storytellingIndex + 1} - overlay`, 'Scroll', `${clubhouseName} Storytelling Act ${storytellingIndex + 1} - scroll`);
      }
    } else {
      isFirstRender.current = false;
    }
  }, [clubhouseName, currentHotspot, hasBeenOnboarded, storytellingIndex]);
};

export default useGtm;
