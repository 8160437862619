import styled from 'styled-components';

import { ColorNames, colors } from 'utils/styles/theme';
import { interactiveMediaMessage } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ color: ColorNames }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${(props) => colors[props.color]};
  user-select: none;
  overflow: hidden;
  opacity: 0;

  ${mediaTablet(`
    justify-content: center;
    text-align: center;
  `)}
`;

export const SvgWrapper = styled.div`
  svg {
    width: 100rem;
  }

  ${mediaTablet(`
    svg {
      width: 60rem;
    }
  `)}
`;

export const Message = styled.p`
  ${interactiveMediaMessage()}
  clip-path: inset(0% 0% 0% 100%);
`;
