import { useClubhouseStore } from 'store';
import preloaderService from 'u9/services/preloader.service';
import { isStorybook } from 'u9/utils/platform';
import { HOTSPOT_ASSETS } from 'utils/config';

const useChampionOverlayAssets = () => {
  const { currentClubhouse } = useClubhouseStore();
  const clubhouse = isStorybook() ? 'seoul' : currentClubhouse;

  const getChampImage = () => {
    return preloaderService.get(HOTSPOT_ASSETS.CHAMP_IMAGES[clubhouse]);
  }

  return { getChampImage };
};

export default useChampionOverlayAssets;
