import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { SoundData, defaultBGMData, SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { useCopyStore, useGlobalStore, usePlaylistsStore } from 'store';
import passiveEvent from 'u9/utils/passiveEvent';
import {
  getFavoritePlaylistData,
  getFavoritePlaylistName,
  getUserProfile,
  refreshToken,
  setClientToken,
  TOKEN_TYPE,
} from 'utils/api';
import { AuthData } from 'utils/api.types';
import { ANTHEM_TRACK } from 'utils/config.assets';

const useAuth = () => {
  const [isMounted, setMounted] = useState(false);
  const {
    accessToken,
    setAccessToken,
    setRefreshToken,
    user,
    setUser
  } = useGlobalStore((state) => state);
  const { copy } = useCopyStore((state) => state);
  const { setFavoritedTracks, setInitialFavoriteCount, setLoading } = usePlaylistsStore((state) => state);
  const router = useRouter();
  const localeRef = useRef<string>(router.locale);

  const getUserData = async () => {
    const { data: { id, display_name, images, product } } = await getUserProfile();
    const favoritePlaylistTitle = getFavoritePlaylistName(copy.experience.personalizedPlaylist.overlay.logged.favoritePlaylistTitle, display_name);
    const favoritePlaylistData = await getFavoritePlaylistData(favoritePlaylistTitle, ANTHEM_TRACK.id);

    setUser({
      id,
      name: display_name,
      image: images[0] ? images[0].url : '',
      hasPremium: product === 'premium',
      favoritePlaylistData
    });

    if (favoritePlaylistData) {
      // Don't count the anthem
      setInitialFavoriteCount(favoritePlaylistData.tracks.length - 1);

      favoritePlaylistData.tracks.forEach((track) => {
        // If the track was provided with a preview url
        if (track.previewUrl) {
          // Create its sound data
          const previewSound: SoundData = {
            ...defaultBGMData,
            id: track.id,
            file: track.previewUrl
          };

          SOUNDS_DATA[track.id] = previewSound;
        }
      });

      setLoading(true);
      // Load the new sound previews
      AudioManager.init(null, () => {
        setFavoritedTracks(favoritePlaylistData.tracks);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    // Adapted from https://jsfiddle.net/JMPerez/62wafrm7
    const onMessage = async (event: MessageEvent) => {
      const hash: AuthData = typeof event.data === 'string' && JSON.parse(event.data);
      if (hash.token_type === TOKEN_TYPE) {
        setRefreshToken(hash.refresh_token);

        const { data: { access_token } } = await refreshToken(hash.refresh_token);
        setAccessToken(access_token);
      }
    };

    window.addEventListener('message', onMessage, passiveEvent);

    return () => {
      if (isMounted) window.removeEventListener('message', onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (accessToken) {
      setClientToken(accessToken);
      if (!user && copy.experience) getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, user, copy.experience]);

  useEffect(() => {
    // Refresh the user's data on locale change
    if (localeRef.current !== router.locale && user && copy.experience) getUserData();
    localeRef.current = router.locale;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);

  return accessToken;
};

export default useAuth;
