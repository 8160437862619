import gsap from 'gsap';

const EASING = 'power4.inOut';
const MASKING_DURATION = 0.9;
const SLIDING_DURATION = 0.9;
const AUTO_ALPHA_DURATION = 0.9;
const AUTO_ALPHA_POSITION = SLIDING_DURATION / 5;

export const maskIn = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const wrapper = lineElements[0].parentElement.parentElement;
  const lineWrappers = lineElements.map(line => line.parentElement);
  const maskInTl = gsap.timeline();

  maskInTl
    .set(wrapper, { overflow: 'hidden', x: 0, autoAlpha: 1 })
    .set(lineWrappers, { x: 0, autoAlpha: 1 })
    .fromTo(
      lineElements,
      { y: '110%' },
      { y: '0%', duration: MASKING_DURATION, ease: EASING, stagger: 0.1 }
    );

  return maskInTl;
};

export const maskOut = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const wrapper = lineElements[0].parentElement.parentElement;
  const maskOutTl = gsap.timeline();

  maskOutTl
    .set(wrapper, { overflow: 'hidden', x: 0, autoAlpha: 1 })
    .fromTo(
      lineElements,
      { y: '0%' },
      { y: '-110%', duration: MASKING_DURATION, ease: EASING, stagger: 0.1 }
    );

  return maskOutTl;
};

export const slideInGroup = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const wrapper = lineElements[0].parentElement.parentElement;
  const slideInGroupTl = gsap.timeline();

  slideInGroupTl
    .set(lineElements, { y: 0 })
    .fromTo(
      wrapper,
      { x: '110%' },
      { x: '0%', duration: SLIDING_DURATION, ease: EASING }
    )
    .fromTo(
      wrapper,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: AUTO_ALPHA_DURATION, ease: EASING },
      AUTO_ALPHA_POSITION
    )

  return slideInGroupTl;
};

export const slideOutGroup = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const wrapper = lineElements[0].parentElement.parentElement;
  const slideOutGroupTl = gsap.timeline({});

  slideOutGroupTl
    .set(lineElements, { y: 0 })
    .fromTo(
      wrapper,
      { x: '0%', autoAlpha: 1 },
      { x: '-110%', autoAlpha: 0, duration: SLIDING_DURATION, ease: EASING }
    );

  return slideOutGroupTl;
};

export const slideInLines = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const lineWrappers = lineElements.map(line => line.parentElement);
  const slideInGroupTl = gsap.timeline();

  slideInGroupTl
    .set(lineElements, { y: 0 })
    .fromTo(
      lineWrappers,
      { x: '110%' },
      { x: '0%', duration: SLIDING_DURATION, ease: EASING, stagger: 0.1 }
    )
    .fromTo(
      lineWrappers,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: AUTO_ALPHA_DURATION, ease: EASING, stagger: 0.1 },
      AUTO_ALPHA_POSITION
    )

  return slideInGroupTl;
};

export const slideOutLines = (lineElements: Node[]) => {
  if (lineElements.length === 0) return;

  const lineWrappers = lineElements.map(line => line.parentElement);
  const slideOutGroupTl = gsap.timeline({});

  slideOutGroupTl
    .set(lineElements, { y: 0 })
    .fromTo(
      lineWrappers,
      { x: '0%', autoAlpha: 1 },
      { x: '-110%', autoAlpha: 0, duration: SLIDING_DURATION, ease: EASING, stagger: 0.1 }
    );

  return slideOutGroupTl;
};
