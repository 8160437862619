import React, { useImperativeHandle, useMemo, useRef } from 'react';

import LabeledPlayer, { LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import SplitContent, { SplitContentFontScale, SplitContentRef } from 'components/SplitContent/SplitContent';
import OverlayTitle, { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import Button, { ButtonRef } from 'components/Button/Button';
import { ReactComponent as HexagonChevron } from 'svgs/hexagon-chevron.svg';
import { ColorNames } from 'utils/styles/theme';
import InteractiveMedia from 'components/InteractiveMedia/InteractiveMedia';
import useGtm from './SecondarySlide.gtm';
import { isMobileLayout } from 'utils/styles/responsive';
import { HotspotTypes, HotspotOverlayTypes } from 'store/clubhouse.types';
import { ContentWrapper, MediaWrapper } from './SecondarySlide.types';
import { useCopyStore } from 'store';
import useMotion from './SecondarySlide.motion';
import * as Styled from './SecondarySlide.styles';

export interface SecondarySlideRef {
  animateIn: () => gsap.core.Timeline;
  transitionIn: () => gsap.core.Timeline;
  transitionOut: () => gsap.core.Timeline;
}
export interface SecondarySlideProps {
  mainContent?: ContentWrapper;
  mediaContent?: MediaWrapper;
  isSlider?: boolean;
  type: HotspotTypes.champion | HotspotTypes.skill | HotspotTypes.pro;
  pageIndex?: number;
}

const defaultProps: Partial<SecondarySlideProps> = {
  mainContent: null,
  mediaContent: null,
  isSlider: false,
  pageIndex: 0
};

const SecondarySlide = React.forwardRef<SecondarySlideRef, SecondarySlideProps>(
  ({
    mainContent,
    mediaContent,
    isSlider,
    type,
    pageIndex
  }: SecondarySlideProps,
    ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const overlayTitleRef = useRef<OverlayTitleRef>(null);
    const subtitleRef = useRef<SplitContentRef>(null);
    const bodyRef = useRef<SplitContentRef>(null);
    const proContentWrapperRef = useRef<HTMLDivElement>(null);
    const proContentRef = useRef<HTMLDivElement>(null);
    const mediaWrapperRef = useRef<HTMLDivElement>(null);
    const trackPlayerRef = useRef<LabeledPlayerRef>(null);
    const dividerRefs = useRef<HTMLDivElement[]>([]);
    const staticMediaWrapperRef = useRef<HTMLDivElement>();
    const taglineLabelRef = useRef<SplitContentRef>(null);
    const taglineBodyRef = useRef<SplitContentRef>(null);
    const triviaLabelRef = useRef<SplitContentRef>(null);
    const triviaBodyRef = useRef<SplitContentRef>(null);
    const downloadButtonRef = useRef<ButtonRef>(null);

    const { copy } = useCopyStore();

    const { handleProfileClick } = useGtm();

    const { animateIn, transitionIn, transitionOut } = useMotion({
      wrapperRef,
      overlayTitleRef,
      subtitleRef,
      bodyRef,
      proContentWrapperRef,
      proContentRef,
      mediaWrapperRef,
      trackPlayerRef,
      dividerRefs,
      taglineLabelRef,
      taglineBodyRef,
      triviaLabelRef,
      triviaBodyRef,
      staticMediaWrapperRef,
      downloadButtonRef
    });

    const overlayTitleIcon = useMemo(() => {
      switch (type) {
        case HotspotTypes.pro:
          return HotspotOverlayTypes.Pro;
        case HotspotTypes.champion:
          return HotspotOverlayTypes.Champion;
        default:
          return HotspotOverlayTypes.Skill;
      }
    }, [type]);

    useImperativeHandle(
      ref,
      (): SecondarySlideRef => ({
        animateIn,
        transitionIn,
        transitionOut
      })
    )

    if (!copy.experience) return null;
    const {
      experience: { hotspotsOverlays: { download: downloadCopy } },
    } = copy;

    if (!downloadCopy) return null;

    const mediaContentWrapper = (
      mediaContent ?
        <Styled.MediaWrapper ref={mediaWrapperRef}>
          {
            mediaContent.staticMedia
              ? <Styled.MediaWrapperBackground ref={staticMediaWrapperRef} background={mediaContent.staticMedia} /> :
              mediaContent.interactiveMedia
                ? <InteractiveMedia {...mediaContent.interactiveMedia} /> : null
          }
          <Styled.MediaContentActions>
            {
              mediaContent.downloadable &&
              <Styled.Downloadable
                href={mediaContent.downloadable}
                download
              >
                <Button
                  ref={downloadButtonRef}
                  label={downloadCopy}
                  icon="download"
                  color={ColorNames.roti}
                  onClick={() => null}
                  isBig
                  showFrom="right"
                />
              </Styled.Downloadable>
            }
            {
              !isMobileLayout() &&
              mediaContent.fullProfile &&
              <Styled.FullProfile
                href={mediaContent.fullProfile.link}
                target="_blank"
              >
                <Styled.FullProfileLabel onClick={handleProfileClick}>
                  {mediaContent.fullProfile.label}
                </Styled.FullProfileLabel>
                <HexagonChevron />
              </Styled.FullProfile>
            }
          </Styled.MediaContentActions>
        </Styled.MediaWrapper> : null
    );

    return (
      <Styled.Wrapper
        ref={wrapperRef}
        extraPaddingBottom={isSlider}
      >
        {
          mainContent &&
          <Styled.ContentWrapper>
            {
              (!isSlider || isMobileLayout()) &&
              <OverlayTitle
                ref={overlayTitleRef}
                hasWorldsIcon={false}
                overlayType={overlayTitleIcon}
                title={mainContent.title}
                color={ColorNames.black}
              />
            }
            <Styled.Subtitle extraMarginTop={type === HotspotTypes.pro}>
              <SplitContent
                ref={subtitleRef}
                copy={mainContent.subtitle}
                fontScale={SplitContentFontScale.Heading4}
              />
            </Styled.Subtitle>
            {
              mainContent.body &&
              <>
                <Styled.Body>
                  <SplitContent
                    ref={bodyRef}
                    copy={mainContent.body}
                    fontScale={SplitContentFontScale.LeadBody}
                  />
                </Styled.Body>
                {!isMobileLayout() && <Styled.Divider ref={ref => dividerRefs.current[dividerRefs.current.length] = ref} />}
              </>
            }
            {
              mainContent.tagline &&
              <Styled.Tagline>
                <Styled.SectionLabel>
                  <SplitContent
                    ref={taglineLabelRef}
                    copy={mainContent.tagline}
                    fontScale={SplitContentFontScale.Subtitle2}
                  />
                </Styled.SectionLabel>
                <Styled.TaglineBody>
                  <SplitContent
                    ref={taglineBodyRef}
                    copy={mainContent.taglineBody}
                    fontScale={SplitContentFontScale.LeadBody3}
                  />
                </Styled.TaglineBody>
                {!isMobileLayout() && <Styled.Divider ref={ref => dividerRefs.current[dividerRefs.current.length] = ref} />}
              </Styled.Tagline>
            }
            {
              isMobileLayout() &&
              mediaContent &&
              mediaContentWrapper
            }
            {
              mainContent.triviaIntro &&
              <Styled.Trivia>
                <Styled.SectionLabel>
                  <SplitContent
                    ref={triviaLabelRef}
                    copy={mainContent.triviaIntro}
                    fontScale={SplitContentFontScale.Subtitle2}
                  />
                </Styled.SectionLabel>
                <Styled.TriviaBody>
                  <SplitContent
                    ref={triviaBodyRef}
                    copy={mainContent.trivia}
                    fontScale={SplitContentFontScale.LeadBody3}
                  />
                </Styled.TriviaBody>
                <Styled.Divider ref={ref => dividerRefs.current[dividerRefs.current.length] = ref} />
              </Styled.Trivia>
            }
            {
              mainContent.proContent &&
              <>
                <Styled.ProContentWrapper ref={proContentWrapperRef}>
                  <Styled.ProContent ref={proContentRef}>
                    <Styled.ProContentInnerWrapper >
                      <Styled.SectionLabel>
                        {mainContent.proContent.profileLabel}
                      </Styled.SectionLabel>
                      <Styled.ProContentProfile>
                        <Styled.ProContentProfileText
                          dangerouslySetInnerHTML={{
                            __html: mainContent.proContent.profile.name
                          }}
                        />
                        <Styled.ProContentProfileText>
                          {mainContent.proContent.profile.team}
                        </Styled.ProContentProfileText>
                        <Styled.ProContentProfileText>
                          {mainContent.proContent.profile.country}
                        </Styled.ProContentProfileText>
                        <Styled.ProContentProfileText>
                          {mainContent.proContent.profile.role}
                        </Styled.ProContentProfileText>
                      </Styled.ProContentProfile>
                      {isMobileLayout() && <Styled.Divider ref={ref => dividerRefs.current[dividerRefs.current.length] = ref} />}
                    </Styled.ProContentInnerWrapper>
                    <Styled.ProContentInnerWrapper>
                      <Styled.SectionLabel>
                        {mainContent.proContent.champsLabel}
                      </Styled.SectionLabel>
                      <Styled.ProContentChamps>
                        {
                          mainContent.proContent.champs.map((champ, index) => (
                            <Styled.ProContentChamp
                              key={`ProContentChamp-${index}`}
                              background={champ}
                            />
                          ))
                        }
                      </Styled.ProContentChamps>
                    </Styled.ProContentInnerWrapper>
                  </Styled.ProContent>
                </Styled.ProContentWrapper>
                <Styled.Divider
                  ref={ref => dividerRefs.current[dividerRefs.current.length] = ref}
                  extraMarginTop={30}
                />
              </>
            }
            {
              mainContent.spotifyTrack &&
              <Styled.SpotifyTrackWrapper
                hasPaddingTop={(type === HotspotTypes.skill || type === HotspotTypes.pro)}
                hasExtraPaddingBottom={isSlider}
              >
                <LabeledPlayer
                  ref={trackPlayerRef}
                  label={mainContent.musicIntro}
                  track={mainContent.spotifyTrack}
                  premiumPrompt={ColorNames.black}
                  theme={ColorNames.blueRibbon}
                  pageIndex={pageIndex}
                />
              </Styled.SpotifyTrackWrapper>
            }
            {
              isMobileLayout() &&
              mediaContent.fullProfile &&
              <Styled.FullProfileMobileWrapper>
                <Styled.FullProfile
                  href={mediaContent.fullProfile.link}
                  target="_blank"
                >
                  <Styled.FullProfileLabel onClick={handleProfileClick}>
                    {mediaContent.fullProfile.label}
                  </Styled.FullProfileLabel>
                  <HexagonChevron />
                </Styled.FullProfile>
              </Styled.FullProfileMobileWrapper>
            }
          </Styled.ContentWrapper>
        }
        {
          !isMobileLayout() &&
          mediaContent &&
          type !== HotspotTypes.pro &&
          mediaContentWrapper
        }
      </Styled.Wrapper>
    );
  }
);

SecondarySlide.defaultProps = defaultProps;

export default SecondarySlide;
