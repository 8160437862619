import { useRef, useEffect, RefObject } from 'react';
import gsap from 'gsap';

import { LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import { SplitContentRef } from 'components/SplitContent/SplitContent';
import { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';

export enum StorytellingContentMotionType {
  Masking = 'Masking',
  Sliding = 'Sliding'
}

export interface MotionRefs {
  wrapperRef: RefObject<HTMLDivElement>;
  subtitleRef: RefObject<SplitContentRef>;
  bodyIntroRef: RefObject<SplitContentRef>;
  bodyRef: RefObject<SplitContentRef>;
  triviaIntroRef: RefObject<SplitContentRef>;
  triviaBodyRef: RefObject<SplitContentRef>;
  trackRef: RefObject<LabeledPlayerRef>;
  interactiveThumbnailRef: RefObject<HTMLDivElement>;
  interactiveMediaWrapperRef: RefObject<HTMLDivElement>;
  interactiveMediaMessageRef: RefObject<InteractiveMediaMessageRef>;
}

export interface MotionProps {
  storyIndex?: number;
}

const useMotion = ({
  wrapperRef,
  subtitleRef,
  bodyIntroRef,
  bodyRef,
  triviaIntroRef,
  triviaBodyRef,
  trackRef,
  interactiveThumbnailRef,
  interactiveMediaWrapperRef,
  interactiveMediaMessageRef
}: MotionRefs,
  {
    storyIndex // eslint-disable-line
  }: MotionProps) => {
  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  const show = (type = StorytellingContentMotionType.Masking, delay = 0) => {
    if (inTl.current) inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => inTl.current = null,
      delay
    })

    inTl.current
      .set(wrapperRef.current, { display: 'block' })
      .fromTo(
        wrapperRef.current,
        { autoAlpha: 0, },
        { autoAlpha: 1, pointerEvents: 'auto', duration: 0.2, ease: 'power4.inOut' }
      )

    const isMasking = type === StorytellingContentMotionType.Masking;

    inTl.current
      .add(isMasking ? subtitleRef.current.maskIn() : subtitleRef.current.slideInLines(), 0)

    if (bodyIntroRef.current) {
      inTl.current
        .add(isMasking ? bodyIntroRef.current.maskIn() : bodyIntroRef.current.slideInGroup(), '-=0.9')
    }

    inTl.current
      .add(isMasking ? bodyRef.current.maskIn() : bodyRef.current.slideInGroup(), '-=0.9')

    if (triviaIntroRef.current) {
      inTl.current
        .add(isMasking ? triviaIntroRef.current.maskIn() : triviaIntroRef.current.slideInGroup(), isMasking ? '-=0.6' : '-=1.2')
        .add(isMasking ? triviaBodyRef.current.maskIn() : triviaBodyRef.current.slideInGroup(), isMasking ? '-=0.8' : '-=1')
    }

    if (trackRef.current) {
      inTl.current
        .add(isMasking ? trackRef.current.maskIn() : trackRef.current.slideIn(), isMasking ? '-=0.5' : '-=0.8');
    }

    // Interactive Media
    interactiveMediaWrapperRef.current && inTl.current
      .to(
        interactiveMediaWrapperRef.current,
        { autoAlpha: 1, duration: 0.8, ease: 'power4.inOut' },
        0.4
      );

    interactiveThumbnailRef.current && inTl.current
      .fromTo(
        interactiveThumbnailRef.current,
        { opacity: 0, scale: 1.4 },
        { opacity: 0.4, scale: 1, duration: 0.5, ease: 'power4.inOut' },
        0.6
      );

    if (interactiveMediaMessageRef.current) {
      inTl.current
        .add(interactiveMediaMessageRef.current.show(), 1.5);
    }

    return inTl.current;
  };

  const hide = (type = StorytellingContentMotionType.Masking) => {
    if (outTl.current) outTl.current.kill();
    outTl.current = gsap.timeline({
      onComplete: () => outTl.current = null
    });

    const isMasking = type === StorytellingContentMotionType.Masking;

    outTl.current
      .add(isMasking ? subtitleRef.current.maskOut() : subtitleRef.current.slideOutLines(), 0)

    if (bodyIntroRef.current) {
      outTl.current
        .add(isMasking ? bodyIntroRef.current.maskOut() : bodyIntroRef.current.slideOutGroup(), '-=0.9')
    }

    outTl.current
      .add(isMasking ? bodyRef.current.maskOut() : bodyRef.current.slideOutGroup(), '-=0.9')

    if (triviaIntroRef.current) {
      outTl.current
        .add(isMasking ? triviaIntroRef.current.maskOut() : triviaIntroRef.current.slideOutGroup(), '-=1')
        .add(isMasking ? triviaBodyRef.current.maskOut() : triviaBodyRef.current.slideOutGroup(), '-=1.2')
    }

    if (trackRef.current) {
      outTl.current
        .add(isMasking ? trackRef.current.maskOut() : trackRef.current.slideOut(), isMasking ? '-=1.2' : '-=1.5');
    }

    if (interactiveMediaMessageRef.current) {
      outTl.current
        .add(interactiveMediaMessageRef.current.hide(), 0);
    }

    interactiveMediaWrapperRef.current && outTl.current
      .to(
        interactiveMediaWrapperRef.current,
        { autoAlpha: 0, duration: 1, ease: 'power4.inOut' },
        0.4
      );

    outTl.current
      .set(wrapperRef.current, { display: 'none' })
      .fromTo(
        wrapperRef.current,
        { autoAlpha: 1 },
        { autoAlpha: 0, pointerEvents: 'none', duration: 0.5, ease: 'power4.inOut' }
      )

    if (interactiveThumbnailRef.current) {
      // using 'call' with a delay
      // so it can overlap with the next slide's animation
      outTl.current
        .call(() => {
          gsap.fromTo(
            interactiveThumbnailRef.current,
            { opacity: 0.4 },
            { opacity: 0, duration: 0.5, delay: 0.6, ease: 'power4.inOut' }
          )
        }, null, 0.4);
    }

    return outTl.current;
  };

  useEffect(() => {
    return () => {
      if (inTl.current) inTl.current.kill();
      if (outTl.current) outTl.current.kill();
    }
  }, []);

  return {
    show,
    hide
  }
};

export default useMotion;
