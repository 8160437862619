import { useClubhouseStore } from 'store';
import { HOTSPOT_ASSETS } from 'utils/config';
import { isStorybook } from 'u9/utils/platform';
import preloaderService from 'u9/services/preloader.service';

const useStorytellingAssets = () => {
  const { currentHotspot, currentClubhouse } = useClubhouseStore();
  const clubhouse = isStorybook() ? 'seoul' : currentClubhouse;
  const hotspot = isStorybook() ? 'storytelling1' : currentHotspot.type;
  const entryPage = 'page1';
  const mainPages = ['page2', 'page3', 'page4'];

  const entryImage = preloaderService.get(HOTSPOT_ASSETS.STORYTELLING_IMAGES[clubhouse][hotspot][entryPage]);
  const interactiveThumbnails = mainPages.map(page =>
    preloaderService.get(HOTSPOT_ASSETS.STORYTELLING_IMAGES[clubhouse][hotspot][page])
  );
  const interactiveVideos = mainPages.map(page =>
    preloaderService.get(HOTSPOT_ASSETS.STORYTELLING_VIDEOS[clubhouse][hotspot][page])
  );

  return {
    entryImage,
    interactiveThumbnails,
    interactiveVideos
  };
};

export default useStorytellingAssets;
