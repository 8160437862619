import { useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import { RibbonRef } from 'components/Ribbon/Ribbon';
import { isMobileLayout } from 'utils/styles/responsive';

export const useMotion = selfRibbonSlide => {
  const timelineRef = useRef<gsap.core.Timeline>(null);
  const ribbonTimelineRef = useRef<gsap.core.Timeline>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const ribbonWrapperRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  const centerStartRef = useRef<HTMLDivElement>(null);
  const centerEndRef = useRef<HTMLDivElement>(null);
  const startFillerRef = useRef<HTMLDivElement>(null);
  const endFillerRef = useRef<HTMLDivElement>(null);
  const ribbonRef = useRef<RibbonRef>(null);

  const centerStartSlideRef = useRef<HTMLDivElement>(null);
  const centerEndSlideRef = useRef<HTMLDivElement>(null);

  const [billboardsHidden, setBillboardsHidden] = useState(false);

  const animateOutDesktop = useCallback(
    (onStartSlideOut, playlistTransition) => {
      const rotateTimeline = gsap
        .timeline()
        .fromTo(
          [centerEndRef.current],
          { rotate: 0 },
          { rotate: '-90deg', duration: 1.2, ease: 'power4.inOut' }
        );

      timelineRef.current
        .fromTo(
          [centerStartRef.current, ribbonWrapperRef.current],
          { rotate: 0 },
          { rotate: '-90deg', duration: 1.2, ease: 'power4.inOut' }
        )
        .fromTo(
          [centerStartRef.current, centerEndRef.current],
          {
            scaleY: 0,
          },
          {
            scaleY: window.innerWidth * 0.85,
            duration: 0.5,
            ease: 'power2.in',
          },
          0.1
        );

      if (playlistTransition) return timelineRef.current;

      timelineRef.current
        .fromTo(
          centerEndRef.current,
          {
            x: '-25vw',
          },
          {
            x: '60vw',
            duration: 0.65,
          },
          0.6
        )
        .call(() => rotateTimeline.progress(1).kill(), null, 0.6)
        .set(
          [centerEndRef.current],
          { scaleY: window.innerWidth * 0.85, rotate: '-90deg' },
          0.6
        )
        .call(onStartSlideOut, null, 0.2)
        .call(() => setBillboardsHidden(true), null, 0.6)
        .set(
          [startFillerRef.current, endFillerRef.current],
          {
            opacity: 0,
          },
          0.1
        );

      return timelineRef.current;
    },
    []
  );

  const animateOutMobile = useCallback(
    (onStartSlideOut, playlistTransition) => {
      if (!playlistTransition) {
        timelineRef.current
          .fromTo(
            wrapperRef.current,
            { x: 0 },
            { overflow: 'hidden', x: '-100%' },
            1
          )
          .call(onStartSlideOut, null, 0)
          .call(() => setBillboardsHidden(true), null, 1);
      } else {
        timelineRef.current.fromTo(
          wrapperRef.current,
          { opacity: 1 },
          { opacity: 0, duration: 0.5 },
          0.5
        );
      }

      return timelineRef.current;
    },
    []
  );

  const animateOut = useCallback(
    (onStartSlideOut, playlistTransition) => {
      if (timelineRef.current) timelineRef.current.kill();

      timelineRef.current = gsap.timeline({
        onComplete: () => {
          timelineRef.current = null;
        },
      });

      if (!isMobileLayout()) {
        animateOutDesktop(onStartSlideOut, playlistTransition);
      } else {
        animateOutMobile(onStartSlideOut, playlistTransition);
      }

      return timelineRef.current;
    },
    [animateOutDesktop, animateOutMobile]
  );

  const ribbonSlide = useCallback(() => {
    if (ribbonTimelineRef.current) ribbonTimelineRef.current.kill();

    ribbonTimelineRef.current = gsap.timeline({
      onComplete: () => {
        timelineRef.current = null;
      },
    });

    ribbonTimelineRef.current.fromTo(
      ribbonRef.current.innerWrapperRef.current,
      { x: 0 },
      { x: '-1000vw', duration: 1000 }
    );
  }, []);

  useEffect(() => {
    return () => {
      if (timelineRef.current) timelineRef.current.kill();
      if (ribbonTimelineRef.current) ribbonTimelineRef.current.kill();
    };
  }, []);

  useEffect(() => {
    if (selfRibbonSlide) {
      ribbonSlide();
    }
  }, [ribbonSlide, selfRibbonSlide]);

  return {
    animateOut,
    wrapperRef,
    ribbonRef,
    centerRef,
    centerStartRef,
    centerEndRef,
    billboardsHidden,
    startFillerRef,
    endFillerRef,
    centerStartSlideRef,
    centerEndSlideRef,
    ribbonWrapperRef,
  };
};
