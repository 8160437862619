import { useClubhouseStore } from 'store';
import { HotspotTypes } from 'store/clubhouse.types';
import { HOTSPOT_ASSETS } from 'utils/config';
import { isStorybook } from 'u9/utils/platform';
import PreloaderService from 'u9/services/preloader.service';

const useProOverlayAssets = () => {
  const { currentHotspot, currentClubhouse } = useClubhouseStore();
  const clubhouse = isStorybook() ? 'seoul' : currentClubhouse;
  const hotspot = isStorybook() ? 'pro' : currentHotspot.type;

  let proImages = {};
  if (hotspot === HotspotTypes.pro) {
    proImages = HOTSPOT_ASSETS.PRO_IMAGES[clubhouse];
  }

  const getChampThumbnails = (proInfo) => {
    return proInfo.champs.map((champ) => PreloaderService.get(HOTSPOT_ASSETS.CHAMP_THUMBNAILS[champ]));
  };

  return { proImages, getChampThumbnails };
};

export default useProOverlayAssets;
