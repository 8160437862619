import { maskIn, maskOut, slideIn, slideOut, MotionRefs, MotionProps } from './LabeledPlayer.motion.timelines';

const useMotion = (refs: MotionRefs, props: MotionProps) => {
  return {
    maskIn: () => maskIn(refs),
    maskOut: () => maskOut(refs, props),
    slideIn: () => slideIn(refs),
    slideOut: () => slideOut(refs, props),
  }
};

export default useMotion;
