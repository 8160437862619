import styled from 'styled-components';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ top: string, left: string, scale: number }>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: 320rem;
  height: 320rem;
  transform: scale(${({ scale }) => scale});

  ${mediaTablet(`
    width: 460rem;
    height: 460rem;
  `)}
`;

export const Image = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
`
