import React, { useEffect, useCallback, useRef } from 'react';
import gsap from 'gsap';

import { RIBBON_ID } from 'components/Ribbon/Ribbon';
import { useGlobalStore } from 'store';
import { BlackBarPositionState, BlackBarTransitionState } from 'store/global.types';
import useWindowSize from 'u9/hooks/useWindowSize';

import * as Styled from './TransitionBars.styles';

const TransitionBars = () => {
  const { blackBarTransition, onTransitionEnd, barStart, barEnd } = useGlobalStore(state => state);
  const size = useWindowSize();

  const topPosRef = useRef();
  const botPosRef = useRef();
  const bgRef = useRef();
  const timeline = useRef<gsap.core.Timeline>(null);

  const getRibbon = () => {
    return document.getElementById(RIBBON_ID);
  }

  const afterAnimateIn = useCallback(() => {
    onTransitionEnd();
  }, [onTransitionEnd]);

  useEffect(() => {
    let y1 = [];
    let y2 = [];
    let h1 = 0;
    let h2 = 0;
    const ease = 'Power4.easeIn';
    if (blackBarTransition === BlackBarTransitionState.In) {
      h1 = 30;
      h2 = (size.height / 2 + 10)
    }
    if (blackBarTransition === BlackBarTransitionState.Out) {
      h1 = (size.height / 2 + 10);
      h2 = barEnd === BlackBarPositionState.Ribbon ? 30 : 0;
    }
    const ribbonEl = getRibbon();
    const ribbonRect = ribbonEl?.getBoundingClientRect() ?? { top: size.height / 2, height: 0 };
    switch (barStart) {
      case BlackBarPositionState.Ribbon: {
        const ribbonPos = ribbonRect.top + ribbonRect.height / 2
        y1 = ([ribbonPos, ribbonPos])
        break;
      }
      case BlackBarPositionState.Center: {
        y1 = ([size.height / 2, size.height / 2])
        break;
      }
      case BlackBarPositionState.Cover: {
        y1 = ([size.height / 4, size.height * 3 / 4])
        break;
      }
      case BlackBarPositionState.Edge: {
        y1 = ([0, size.height])
        break;
      }
    }
    switch (barEnd) {
      case BlackBarPositionState.Ribbon: {
        const ribbonPos = ribbonRect.top + ribbonRect.height / 2
        y2 = ([ribbonPos, ribbonPos])
        break;
      }
      case BlackBarPositionState.Center: {
        y2 = ([size.height / 2, size.height / 2])
        break;
      }
      case BlackBarPositionState.Cover: {
        y2 = ([size.height / 4, size.height * 3 / 4])
        break;
      }
      case BlackBarPositionState.Edge: {
        y2 = ([0, size.height])
        break;
      }
    }

    const dur = 0.6;
    if (timeline.current) timeline.current.kill();
    timeline.current = gsap.timeline({
      onComplete: () => { timeline.current = null; }
    });
    timeline.current
      .fromTo(bgRef.current,
        {
          opacity: 0
        },
        {
          opacity: blackBarTransition === BlackBarTransitionState.None ? 0 : 0.3,
          duration: 0.2,
          ease: 'none'
        },
        0
      )
      .fromTo(topPosRef.current,
        {
          y: y1[0],
          scaleY: h1,
          opacity: 1,
          delay: 0.15,
        },
        {
          y: y2[0],
          scaleY: h2,
          duration: dur,
          ease
        },
        0
      )
      .fromTo(botPosRef.current,
        {
          y: y1[1],
          scaleY: h1,
          opacity: 1,
          delay: 0.15,
        },
        {
          y: y2[1],
          scaleY: h2,
          duration: dur,
          ease
        },
        0
      )
      .to(bgRef.current,
        {
          opacity: 0,
          duration: 0.3,
          ease: 'none',
          onComplete: () => {
            afterAnimateIn();
          }
        },
        '-=0.2'
      )
      .to([botPosRef.current, topPosRef.current],
        {
          opacity: blackBarTransition === BlackBarTransitionState.Out ? 0 : 1,
          duration: 0.2,
          ease: 'none',
        },
        '-=0.2'
      )

    return () => {
      if (timeline.current) timeline.current.kill();
    };
  }, [blackBarTransition, barEnd, barStart, size, afterAnimateIn])

  return (
    <Styled.Wrapper>
      <Styled.Bg ref={bgRef} />
      <Styled.BarWrapper ref={topPosRef} />
      <Styled.BarWrapper ref={botPosRef} />
    </Styled.Wrapper>
  );
};

export default TransitionBars;
