import React, { useRef, useImperativeHandle } from 'react';
import gsap from 'gsap';

import Player, { validPremiumStates, validThemes } from 'components/Player/Player';
import Tooltip from 'components/Tooltip/Tooltip';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';
import { Track } from 'store/playlists.types';
import { useIntersectionObserver } from 'u9/hooks';

import useTooltip from './LabeledPlayer.tooltip';
import useMotion from './LabeledPlayer.motion';
import * as Styled from './LabeledPlayer.styles';

export enum LabeledPlayerLabelAlignment {
  Left = 'Left',
  Center = 'Center'
}

export interface LabeledPlayerRef {
  maskIn: () => gsap.core.Timeline;
  maskOut: () => gsap.core.Timeline;
  slideIn: () => gsap.core.Timeline;
  slideOut: () => gsap.core.Timeline;
}

export interface LabeledPlayerProps {
  label: string;
  labelAlignment?: LabeledPlayerLabelAlignment;
  track: Track;
  theme?: typeof validThemes[number];
  premiumPrompt?: typeof validPremiumStates[number];
  pageIndex?: number;
  hasBigLabel?: boolean;
}

const defaultProps: Partial<LabeledPlayerProps> = {
  label: '',
  labelAlignment: LabeledPlayerLabelAlignment.Left,
  track: null,
  theme: ColorNames.roti,
  premiumPrompt: ColorNames.white,
  pageIndex: 0,
  hasBigLabel: false
};

const LabeledPlayer = React.forwardRef<LabeledPlayerRef, LabeledPlayerProps>(
  (
    props: LabeledPlayerProps,
    ref
  ) => {
    const { label, labelAlignment, track, theme, premiumPrompt, pageIndex } = props;

    const labelWrapperRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);
    const playerWrapperRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<HTMLDivElement>(null);

    const [tooltipRef, isTooltipInViewport] = useIntersectionObserver();

    const {
      isTooltipVisible,
      tooltipCopy,
      hideTooltip
    } = useTooltip(isTooltipInViewport, pageIndex);

    const { maskIn, maskOut, slideIn, slideOut } = useMotion({
      labelWrapperRef,
      labelRef,
      playerWrapperRef,
      playerRef,
      tooltipRef
    }, { hideTooltip });

    useImperativeHandle(
      ref,
      (): LabeledPlayerRef => ({
        maskIn,
        maskOut,
        slideIn,
        slideOut
      })
    );

    return (
      <Styled.Wrapper onClick={hideTooltip}>
        <Styled.LabelWrapper ref={labelWrapperRef}>
          <Styled.Label ref={labelRef} labelAlignment={labelAlignment} isBig={props.hasBigLabel}>
            {label}
          </Styled.Label>
        </Styled.LabelWrapper>
        <Styled.PlayerWrapper ref={playerWrapperRef} labelAlignment={labelAlignment}>
          <Styled.Player ref={playerRef}>
            <Player
              track={track}
              premiumPrompt={premiumPrompt}
              theme={theme}
              centered={labelAlignment === LabeledPlayerLabelAlignment.Center}
            />
          </Styled.Player>
        </Styled.PlayerWrapper>
        <Styled.TooltipWrapper ref={tooltipRef}>
          <Tooltip
            isVisible={isTooltipVisible}
            label={tooltipCopy}
            xPosition={'center'}
            yPosition={isMobileLayout() ? 'bottom' : 'top'}
            isOnboarding
          />
        </Styled.TooltipWrapper>
      </Styled.Wrapper>
    );
  }
);

LabeledPlayer.displayName = 'LabeledPlayer';
LabeledPlayer.defaultProps = defaultProps;

export default LabeledPlayer;
