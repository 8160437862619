import styled from 'styled-components';
import { mediaTablet, mediaDesktop } from 'utils/styles/responsive';
import { fullscreen, setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
`;

export const BreadcrumbWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding-right: 0;
  pointer-events: none;
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  ${mediaTablet(`
    padding-left: ${80 + layout.ribbon.width.desktop}rem;
    bottom: ${layout.headerFooterPadding.desktop}rem;
    width: 50%;
    opacity: 1;
    visibility: visible;
  `)}

  ${mediaDesktop(`
    padding-left: ${200 + layout.ribbon.width.desktop}rem;
  `)}
`;

export const OverlayTitleWrapper = styled.div`
  position: fixed;
  top: ${layout.headerFooterPadding.tablet}rem;
  padding-right: 0;
  padding-left: ${80 + layout.ribbon.width.desktop}rem;
  bottom: ${layout.headerFooterPadding.desktop}rem;
  width: 50%;
  pointer-events: none;
  z-index: 10;

  ${mediaDesktop(`
    padding-left: ${200 + layout.ribbon.width.desktop}rem;
  `)}
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const ContentWrapper = styled.div`
  background-color: ${colors.white};
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;

  ${mediaTablet(`
    ${fullscreen()}
    width: 100vw;
    height: ${setVh(100)};
    transform: translate(100%, 0);
    overflow: hidden;
    opacity: 1;
    visibility: visible;
  `)}
`;

export const MediaContentWrapper = styled.div<{ background: string }>`
  position: absolute;
  top: 0;
  left: 55vw;
  width: 45vw;
  height: ${setVh(100)};
  background-image: url(${props => props.background});
  background-size: cover;
  overflow: hidden;
`;

export const MediaWrapper = styled.div`
  ${fullscreen()}
  pointer-events: none;
`;

export const InteractiveMediaWrapper = styled.div`
  ${fullscreen()}
  z-index: 2;
`;

export const MediaContentActions = styled.div`
  position: absolute;
  top: 60rem;
  left: 60rem;
  display: flex;
  align-items: center;
  pointer-events: auto;
`;

export const Downloadable = styled.a`
 margin-right: 40rem;
`;

export const WhiteBackground = styled.div`
  ${fullscreen()}
  height: ${setVh(100)};
  background-color: ${colors.white};
  opacity: 0;
  visibility: hidden;
`;

export const InteractiveMediaMessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 60rem;
  transform: translateX(-50%);

  ${mediaTablet(`
    bottom: ${layout.headerFooterPadding.tablet - 12}rem;
  `)}

  ${mediaDesktop(`
    bottom: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;
