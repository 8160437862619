import React, { useRef } from 'react';
import { camelCase } from 'lodash';

import ProBg from 'assets/images/ui/pro-bg.jpg';
import InteractiveImages, { InteractiveImagesRef } from 'components/InteractiveImages/InteractiveImages';
import { MediaType } from 'components/InteractiveMedia/InteractiveMedia.types';
import Breadcrumbs, { BreadcrumbsRef } from 'components/Breadcrumbs/Breadcrumbs';
import OverlayTitle, { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import Ribbon from 'components/Ribbon/Ribbon';
import useScrollLockGesture from 'components/ScrollLockContainer/ScrollLockContainer.gesture';

import { useHotspotOverlayStore } from 'store';
import { HotspotTypes } from 'store/clubhouse.types';
import { HotspotOverlayTypes } from 'store/clubhouse.types';
import PreloaderService from 'u9/services/preloader.service';
import { HOTSPOT_ASSETS } from 'utils/config';
import { ColorNames, layout } from 'utils/styles/theme';
import { isMobileLayout } from 'utils/styles/responsive';

import useHotspotOverlayCopy from './../../HotspotOverlay.copy';
import SecondarySlide, { SecondarySlideRef } from './../SecondarySlide/SecondarySlide';
import useProOverlayAssets from './Pro.assets';
import useProCopy from './Pro.copy';
import useMotion from './Pro.motion';

import * as Styled from './Pro.styles';

export interface ProProps { }

const defaultProps: Partial<ProProps> = {};

const Pro: React.FC<ProProps> = () => {
  const { slideIndex, setSlideIndex, previousSlideIndex, setPreviousSlideIndex } = useHotspotOverlayStore();
  const { pros, tracks } = useProCopy();
  const { proImages, getChampThumbnails } = useProOverlayAssets();
  const { currentRibbon, currentClubhouse } = useHotspotOverlayCopy();
  const breadcrumbs = [];

  const wrapperRef = useRef<HTMLDivElement>(null);
  const whiteBackgroundRef = useRef<HTMLDivElement>(null);
  const breadcrumbWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const mediaContentWrapperRef = useRef<HTMLDivElement>(null);
  const secondarySlideRefs = useRef<SecondarySlideRef[]>([]);
  const overlayTitleRef = useRef<OverlayTitleRef>(null);
  const breadcrumbsRef = useRef<BreadcrumbsRef>(null);
  const interactiveImagesRef = useRef<InteractiveImagesRef>(null);

  useMotion(
    {
      whiteBackgroundRef,
      contentWrapperRef,
      secondarySlideRefs,
      mediaContentWrapperRef,
      overlayTitleRef,
      breadcrumbsRef,
      breadcrumbWrapperRef,
      interactiveImagesRef
    },
    {
      slideIndex
    }
  );

  // eslint-disable-next-line
  const proSlides = pros.map((pro, index) => {
    const champThumbnails = getChampThumbnails(pro);
    const downloadable = HOTSPOT_ASSETS.DOWNLOADABLES.pro[camelCase(pro.subtitle)];
    breadcrumbs.push(pro.subtitle);

    return (
      <SecondarySlide
        ref={ref => secondarySlideRefs.current[index] = ref}
        key={`Pro-${pro.subtitle}`}
        type={HotspotTypes.pro}
        isSlider={true}
        pageIndex={index}
        mainContent={{
          title: pros[0].title,
          subtitle: pro.subtitle,
          body: pro.body,
          proContent: {
            profile: {
              name: pro.profile.name,
              team: pro.profile.team,
              country: pro.profile.country,
              role: pro.profile.role
            },
            champs: champThumbnails,
            champsLabel: pro.champsProLabel,
            profileLabel: pro.profileProLabel,
          },
          musicIntro: pro.musicIntro,
          spotifyTrack: tracks[index]
        }}
        mediaContent={{
          downloadable,
          interactiveMedia: {
            type: MediaType.Image,
            imageProps: {
              mainImage: PreloaderService.get(proImages[camelCase(pro.subtitle)].main),
              revealedImage: PreloaderService.get(proImages[camelCase(pro.subtitle)].revealed)
            }
          }
        }}
      />
    );
  });

  useScrollLockGesture({
    targetRef: wrapperRef,
    slidesLength: proSlides.length
  });

  const breadcrumbHandlers = isMobileLayout() ?
    {
      onPrev: () => {
        setPreviousSlideIndex(slideIndex);
        slideIndex > 0 && setSlideIndex(slideIndex - 1);
      },
      onNext: () => {
        setPreviousSlideIndex(slideIndex);
        slideIndex < pros.length - 1 && setSlideIndex(slideIndex + 1);
      }
    } : {};

  const mediaWrapper = () => {
    const images = pros.map(pro => {
      return {
        main: proImages[camelCase(pro.subtitle)].main,
        revealed: proImages[camelCase(pro.subtitle)].revealed,
        downloadable: HOTSPOT_ASSETS.DOWNLOADABLES.pro[camelCase(pro.subtitle)]
      };
    });

    return (
      <InteractiveImages
        ref={interactiveImagesRef}
        images={images}
        mainDownloadable={images[0].downloadable}
        currentIndex={slideIndex}
        previousIndex={previousSlideIndex}
      />
    );
  }

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {isMobileLayout() && <Styled.WhiteBackground ref={whiteBackgroundRef} />}
      <Styled.ContentWrapper ref={contentWrapperRef}>
        {proSlides}
        <Styled.RibbonWrapper>
          <Ribbon
            copy={currentRibbon}
            alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
            icon={currentClubhouse}
            iconSize={layout.iconSizes[currentClubhouse].ribbon}
          />
        </Styled.RibbonWrapper>
        {
          !isMobileLayout() &&
          <Styled.MediaContentWrapper
            ref={mediaContentWrapperRef}
            background={PreloaderService.get(ProBg)}
          >
            {mediaWrapper()}
          </Styled.MediaContentWrapper>
        }
        {
          !isMobileLayout() && (
            <>
              <Styled.OverlayTitleWrapper>
                <OverlayTitle
                  ref={overlayTitleRef}
                  hasWorldsIcon={false}
                  overlayType={HotspotOverlayTypes.Pro}
                  title={pros[0] ? pros[0].title : ''}
                  color={ColorNames.black}
                />
              </Styled.OverlayTitleWrapper>
              <Styled.BreadcrumbWrapper>
                <Breadcrumbs
                  ref={breadcrumbsRef}
                  breadcrumbIndex={slideIndex}
                  breadcrumbs={breadcrumbs}
                  theme="light"
                  {...breadcrumbHandlers}
                />
              </Styled.BreadcrumbWrapper>
            </>
          )
        }
      </Styled.ContentWrapper>
      {
        isMobileLayout() &&
        <Styled.BreadcrumbWrapper ref={breadcrumbWrapperRef}>
          <Breadcrumbs
            ref={breadcrumbsRef}
            breadcrumbIndex={slideIndex}
            breadcrumbs={breadcrumbs}
            theme="light"
            {...breadcrumbHandlers}
          />
        </Styled.BreadcrumbWrapper>
      }
    </Styled.Wrapper>
  );
};

Pro.defaultProps = defaultProps;

export default Pro;
