import React, { useImperativeHandle, useRef } from 'react';

import { isTouchDevice } from 'u9/utils/platform';
import { ColorNames } from 'utils/styles/theme';
import { ReactComponent as HexagonCursorSvg } from 'svgs/hexagon-cursor.svg';
import { ReactComponent as HexagonPlaySvg } from 'svgs/hexagon-play.svg';
import { useCopyStore } from 'store';

import useMotion from './InteractiveMediaMessage.motion';
import * as Styled from './InteractiveMediaMessage.styles';

export interface InteractiveMediaMessageRef {
  show: () => gsap.core.Timeline;
  hide: () => gsap.core.Timeline;
}

export interface InteractiveMediaMessageProps {
  useClick?: boolean;
  message?: string;
  color?: ColorNames;
}

const defaultProps: Partial<InteractiveMediaMessageProps> = {
  useClick: false,
  message: null,
  color: ColorNames.white
};

const InteractiveMediaMessage = React.forwardRef<InteractiveMediaMessageRef, InteractiveMediaMessageProps>(
  ({
    useClick,
    message,
    color
  }: InteractiveMediaMessageProps, ref) => {
    const { experience: { hotspotsOverlays: { view } } } = useCopyStore(state => state.copy);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef<HTMLParagraphElement>(null);

    const { show, hide } = useMotion({
      wrapperRef,
      svgRef,
      messageRef
    });

    useImperativeHandle(
      ref,
      (): InteractiveMediaMessageRef => ({
        show,
        hide
      })
    );

    const messageCopy = message ||
      (useClick ? view.desktopClick :
        isTouchDevice() ?
          view.mobile :
          view.desktop
      )

    return (
      <Styled.Wrapper
        ref={wrapperRef}
        color={color}
      >
        <Styled.SvgWrapper ref={svgRef}>
          {
            useClick
              ? <HexagonPlaySvg />
              : <HexagonCursorSvg />}
        </Styled.SvgWrapper>
        <Styled.Message ref={messageRef}>
          {messageCopy}
        </Styled.Message>
      </Styled.Wrapper>
    );
  }
);

InteractiveMediaMessage.displayName = 'InteractiveMediaMessage';
InteractiveMediaMessage.defaultProps = defaultProps;

export default InteractiveMediaMessage;
