import React from 'react';

import AudioToggle from 'components/AudioToggle/AudioToggle';
import { ReactComponent as ShareSvg } from 'svgs/share.svg';
import { useCopyStore } from 'store';

import * as Styled from './FooterMobile.styles';

const FooterMobile = () => {
  const { copy } = useCopyStore(state => state);

  const handleShare = async () => {
    if (navigator.share) {
      const data = {
        url: window.location.href,
        text: copy.head.description,
        title: copy.head.title,
      };
      try {
        await navigator.share(data);
      } catch (error) {
        if (process.env.IS_DEBUG) console.error('Share error:', error);
        return;
      }
    }
  };

  return (
    <Styled.Wrapper>
      <div>
        <AudioToggle />
      </div>
      <ShareSvg onClick={handleShare} />
    </Styled.Wrapper>
  );
};

export default FooterMobile;
