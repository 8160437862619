import { useEffect, useRef, useState, RefObject, useMemo } from 'react';
import gsap from 'gsap';

import { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import { BreadcrumbsRef } from 'components/Breadcrumbs/Breadcrumbs';
import { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';
import { StorytellingContentRef } from '../StorytellingContent/StorytellingContent';
import desktopMotions from './StorytellingLayout.motion.desktop';
import mobileMotions from './StorytellingLayout.motion.mobile';
import { isMobileLayout } from 'utils/styles/responsive';
import { StorytellingContentMotionType } from '../StorytellingContent/StorytellingContent.motion';
import { useHotspotOverlayStore } from 'store';

export interface MotionRefs {
  overlayTitleRef: RefObject<OverlayTitleRef>;
  breadcrumbsRef: RefObject<BreadcrumbsRef>;
  breadcrumbsWrapperRef: RefObject<HTMLDivElement>;
  entryImageRef: RefObject<HTMLDivElement>;
  mainContentRef: RefObject<HTMLDivElement>;
  ribbonWrapperRef: RefObject<HTMLDivElement>;
  contentRefs: RefObject<StorytellingContentRef[]>;
  interactiveMediaMessageRef: RefObject<InteractiveMediaMessageRef>;
}

const useMotion = (refs: MotionRefs) => {
  const {
    overlayTitleRef,
    breadcrumbsRef,
    breadcrumbsWrapperRef,
    entryImageRef,
    mainContentRef,
    ribbonWrapperRef,
    contentRefs,
    interactiveMediaMessageRef,
  } = refs;

  const [contentIndex, setContentIndex] = useState<number>(0);
  const previousContentIndex = useRef<number>(0);
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const inTl = useRef<gsap.core.Timeline>(null);
  const transitionInTl = useRef<gsap.core.Timeline>(null);
  const transitionOutTl = useRef<gsap.core.Timeline>(null);

  const { slideIndex, previousSlideIndex } = useHotspotOverlayStore(state => ({
    slideIndex: state.slideIndex,
    previousSlideIndex: state.previousSlideIndex,
  }));

  const betweenFirstAndSecondSlides = useMemo(() => (
    (slideIndex === 0 && previousSlideIndex === 1) ||
    (slideIndex === 1 && previousSlideIndex === 0)
  ), [slideIndex, previousSlideIndex]);

  const {
    animateInMainContent,
    slideInMainContent,
    slideOutMainContent,
    showEntryImage
  } = isMobileLayout() ? mobileMotions : desktopMotions;

  const { shrinkBreadcrumbWrapper, expandBreadcrumbWrapper } = desktopMotions;

  const transitionOut = () => {
    if (transitionOutTl.current) transitionOutTl.current = null;

    transitionOutTl.current = gsap.timeline({
      onComplete: () => {
        transitionOutTl.current = null;
      }
    });

    previousContentIndex.current = contentIndex;

    if (slideIndex > 0) {
      // going from 0 to greater

      if (betweenFirstAndSecondSlides && !isMobileLayout()) {
        transitionOutTl.current
          .add(contentRefs.current[previousContentIndex.current].hide(StorytellingContentMotionType.Sliding), 0)
      } else {
        transitionOutTl.current
          .add(contentRefs.current[previousContentIndex.current].hide(StorytellingContentMotionType.Masking), 0)
      }

      transitionOutTl.current
        .add(slideInMainContent(mainContentRef), 0.2)
        .add(() => setContentIndex(slideIndex), 0.5)

      if (!isMobileLayout()) {
        transitionOutTl.current
          .add(expandBreadcrumbWrapper(breadcrumbsWrapperRef), 0.2)
      }
    } else {
      // going from greater to 0

      if (betweenFirstAndSecondSlides && !isMobileLayout()) {
        transitionOutTl.current
          .add(contentRefs.current[previousContentIndex.current].hide(StorytellingContentMotionType.Sliding), 0)
      } else {
        transitionOutTl.current
          .add(contentRefs.current[previousContentIndex.current].hide(StorytellingContentMotionType.Masking), 0)
      }

      transitionOutTl.current
        .add(slideOutMainContent(mainContentRef), 0)
        .add(() => setContentIndex(slideIndex), 0.5)

      if (!isMobileLayout()) {
        transitionOutTl.current
          .add(shrinkBreadcrumbWrapper(breadcrumbsWrapperRef), 0);
      }
    }

    return transitionOutTl.current;
  }

  const transitionIn = () => {
    if (transitionInTl.current) transitionInTl.current = null;

    transitionInTl.current = gsap.timeline({
      onComplete: () => {
        transitionInTl.current = null;
      }
    });

    if (isMobileLayout() && slideIndex !== 0) {
      transitionInTl.current
        .to(
          ribbonWrapperRef.current,
          { top: 0, duration: 0.8, ease: 'power4.inOut' },
          0
        )
    }

    if (betweenFirstAndSecondSlides && !isMobileLayout()) {
      transitionInTl.current
        .add(contentRefs.current[contentIndex].show(StorytellingContentMotionType.Sliding), 0);
    } else {
      transitionInTl.current
        .add(contentRefs.current[contentIndex].show(StorytellingContentMotionType.Masking), 0);
    }

    if (slideIndex > 0 && interactiveMediaMessageRef.current) {
      transitionInTl.current
        .add(interactiveMediaMessageRef.current.show(), 0);
    } else if (slideIndex === 0 && interactiveMediaMessageRef.current) {
      transitionInTl.current
        .add(interactiveMediaMessageRef.current.hide(), 0);
    }

    return transitionInTl.current;
  }

  const animateIn = () => {
    if (inTl.current) inTl.current.kill();

    inTl.current = gsap.timeline({
      onComplete: () => {
        inTl.current = null;
      }
    });

    inTl.current
      .add(breadcrumbsRef.current.show(), 0)
      .add(overlayTitleRef.current.show(), 0)
      .add(animateInMainContent(mainContentRef), 0)

    setTimeout(() => {
      inTl.current
        .call(() => contentRefs.current[0].show(StorytellingContentMotionType.Sliding), null, 0.3)
    }, 500);

    if (entryImageRef.current) {
      inTl.current
        .add(showEntryImage(entryImageRef), 0);
    }
  };

  useEffect(() => {
    !isInitial && transitionOut();
    // eslint-disable-next-line
  }, [slideIndex]);

  useEffect(() => {
    // initial animation in
    if (contentIndex === 0 && previousContentIndex.current === 0) {
      animateIn();
      setIsInitial(false);
    } else {
      transitionIn();
    }
    // eslint-disable-next-line
  }, [contentIndex, previousContentIndex]);

  useEffect(() => {
    return () => {
      if (inTl.current) inTl.current.kill();
      if (transitionInTl.current) transitionInTl.current.kill();
      if (transitionOutTl.current) transitionOutTl.current.kill();
    };
  }, []);

  return {
    contentIndex
  };
};

export default useMotion;
