import SplitText from 'gsap/dist/SplitText';

export const splitCopy = (text: Element) => {
  const splitTextWrapper = new SplitText(
    text,
    {
      type: 'lines',
      linesClass: 'SplitText__wrapper'
    }
  );

  return new SplitText(
    splitTextWrapper.lines,
    {
      type: 'lines',
      linesClass: 'SplitText__line'
    }
  );
};
