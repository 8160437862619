import styled from 'styled-components';
import { ColorNames, colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const RibbonWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  z-index: 100;
`;

export type BillboardWrapperPropTypes = {
  backgroundColor?: ColorNames;
  backgroundImage?: string;
};
export const BillboardWrapper = styled.div<BillboardWrapperPropTypes>`
  background-color: ${({ backgroundColor }) =>
    colors[backgroundColor] || colors[ColorNames.white]};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const StaticCenter = styled.div`
  position: relative;
`;

export const StartFiller = styled.div`
  position: absolute;
  content: ' ';
  background: ${colors.blueRibbon};
  width: 100%;
  bottom: 50%;
  height: 50%;
  transform: scaleX(1.5);
`;

export const EndFiller = styled.div`
  position: absolute;
  content: ' ';
  background: ${colors.white};
  width: 100%;
  top: 50%;
  height: 50%;
  transform: scaleX(1.5);
`;

export const Center = styled.div`
  position: relative;
  z-index: 8;
`;

export const CenterStart = styled.div`
  background: ${colors.blueRibbon};
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 100%;
  z-index: 6;
  transform: scaleX(1.5);
  transform-origin: center bottom;
`;

export const CenterEnd = styled.div`
  background: ${colors.white};
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  z-index: 5;
  transform: scaleX(1.5);
  transform-origin: center top;
`;
