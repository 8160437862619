import React, { useEffect, useMemo, useState } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import FPSStats from 'react-fps-stats';
import * as Sentry from '@sentry/react';
import 'intersection-observer';

import MainLayout from 'components/layouts/MainLayout/MainLayout';
import NonFunctionals from 'containers/NonFunctionals/NonFunctionals';
import useAudioLoops from 'hooks/useAudioLoops';
import { setupStoreDevTools, useAudioStore, useClubhouseStore, useGlobalStore } from 'store';
import Head from 'u9/components/Head/Head';
import StandaloneVersion from 'u9/components/versioning/StandaloneVersion/StandaloneVersion';
import Version from 'u9/components/versioning/Version/Version';
import { usePageVisibility } from 'u9/hooks';
import GTM from 'u9/utils/gtm';
import { isBrowser } from 'u9/utils/platform';
import { ROUTES } from 'utils/routes';
import { getBeenOnboarded, getMuted } from 'utils/localStorage';
import { parseUrlQuery } from 'utils/url';

import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/global-styles';
import theme from 'utils/styles/theme';

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
  });
}

// Use ?debugFPS={any} to show a FPS counter
// Use ?debugNoHomeRedirect={any} to bypass the redirect to the homepage if the user hasn't visited it yet
// Use ?debugNoCookieBanner={any} to disable Spotify's CMP banner

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);
  const { isMuted, isUserMuted, setMuted } = useAudioStore();
  const { isReady } = useGlobalStore(state => state);
  const { setBeenOnboarded, setFirstScroll, setSeenSpotifyPlayer, setOnboardingStep } = useClubhouseStore(state => state);

  const isPageVisible = usePageVisibility();
  useAudioLoops();

  const shouldRedirect = useMemo(
    () => (
      (!([ROUTES.HOME, ROUTES.AUTH, ROUTES.NOT_FOUND] as string[]).includes(router.pathname) && !isReady) &&
      !(process.env.IS_DEBUG && isBrowser() && parseUrlQuery().debugNoHomeRedirect)
    ),
    [isReady, router.pathname]
  );

  const pageComponent = useMemo(() => <Component key={router.route} router={router} {...pageProps} />, [pageProps, router]);

  useEffect(() => {
    setupStoreDevTools();
    setMuted(getMuted(), getMuted());

    const hasBeenOnboarded = getBeenOnboarded();
    if (hasBeenOnboarded) {
      setBeenOnboarded(true);
      setFirstScroll(true);
      setSeenSpotifyPlayer(true);
      setOnboardingStep(+Infinity);
    }

    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.pathname !== ROUTES.AUTH) GTM.trackPage();
  }, [router.pathname]);

  useEffect(() => {
    if (shouldRedirect) router.push(ROUTES.HOME);
  }, [router, shouldRedirect]);

  useEffect(() => {
    // Don't change the mute state automatically if the user has deliberately muted the sound
    if (isMounted && !(isUserMuted && isMuted)) setMuted(!isPageVisible);
  }, [isMounted, isMuted, isPageVisible, isUserMuted, setMuted]);

  if (router.pathname === ROUTES.AUTH) return <>{pageComponent}</>;

  return (
    <ThemeProvider theme={{ ...theme, locale: router.locale }}>
      <GlobalStyles />
      <style dangerouslySetInnerHTML={{ __html: customFonts }} />
      <Head {...pageProps.head} />
      {!shouldRedirect ? (
        <>
          <NonFunctionals {...pageProps}>
            <MainLayout>
              <AnimatePresence exitBeforeEnter={true} initial={false}>
                {pageComponent}
              </AnimatePresence>
            </MainLayout>
          </NonFunctionals>
        </>
      ) : null}

      {process.env.IS_DEBUG && <Version />}
      {isMounted
        ? process.env.IS_DEBUG &&
        window.location.hash === '#version' && <StandaloneVersion />
        : null}

      {isMounted && process.env.IS_DEBUG && parseUrlQuery().debugFPS && (
        <div style={{ opacity: 0.75, pointerEvents: 'none', zIndex: 99999, position: 'fixed' }}>
          <FPSStats />
        </div>
      )}
    </ThemeProvider>
  );
};

export default App;
