import { useState, useEffect, useRef, useCallback } from 'react';
import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import { isDesktop, isTouchDevice } from 'u9/utils/platform';
import { useHotspotOverlayStore } from 'store';

const HOLD_TIMEOUT = 500; // ms

const useGesture = (targetEl = null, useClick = false) => {
  const holdTimeout = useRef(null);
  const [held, setHeld] = useState(false);

  const setMediaIsPlaying = useHotspotOverlayStore(state => state.setMediaIsPlaying);

  const handleHoldDown = () => {
    if (holdTimeout.current) clearTimeout(holdTimeout.current);
    holdTimeout.current = setTimeout(() => {
      setHeld(true);
      holdTimeout.current = null;
    }, HOLD_TIMEOUT);
  };

  const handleHoldUp = () => {
    if (holdTimeout.current) clearTimeout(holdTimeout.current);
    setHeld(false);
  };

  const handleClick = useCallback(() => {
    setHeld(!held);
  }, [held]);

  const preventContext = (e) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    const target = targetEl.current;
    target.addEventListener('contextmenu', preventContext);

    const clickEvents = [isDesktop() ? 'click' : '', isTouchDevice() ? 'touchstart' : ''].filter(Boolean).join(' ');
    if (useClick) {
      addEventListeners(target, clickEvents, handleClick)
    } else {
      addEventListeners(target, 'touchstart mousedown', handleHoldDown)
      addEventListeners(target, 'touchend mouseup', handleHoldUp)
    }

    return () => {
      if (holdTimeout.current) clearTimeout(holdTimeout.current);
      target.removeEventListener('contextmenu', preventContext);

      if (useClick) {
        removeEventListeners(target, clickEvents, handleClick)
      } else {
        removeEventListeners(target, 'touchstart mousedown', handleHoldDown)
        removeEventListeners(target, 'touchend mouseup', handleHoldUp)
      }
    };
  }, [targetEl, handleClick, useClick]);

  useEffect(() => {
    setMediaIsPlaying(held);

    // eslint-disable-next-line
  }, [held]);

  return {
    held,
    setHeld
  };
};

export default useGesture;
