import styled from 'styled-components';
import { ColorNames, colors } from 'utils/styles/theme';
import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

export const Left = styled.div<{ color?: ColorNames }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  user-select: none;

  svg {
    width: 50rem;
    color: ${({ color }) => (color ? colors[color] : 'currentColor')};
  }

  img {
    width: 80rem;
    border-radius: 50%;
  }

  > div:not(:last-child) {
    margin-right: 20rem;
  }
`;

export const Dash = styled.div<{ color: ColorNames }>`
  display: inline-block;
  width: 110rem;
  height: 5rem;
  background-color: ${props => colors[props.color]};
  user-select: none;
  margin: 0 40rem;
  transform-origin: center right;
`;

export const Label = styled.p<{ color?: ColorNames }>`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)};
  color: ${({ color }) => (color ? colors[color] : 'currentColor')};
  margin-top: 0.125em;
  user-select: none;

  ${({ theme: { locale } }) =>
    mediaTablet(`
    ${setTypography('subtitle2', locale)};
  `)}
`;

export const OverlayIconWrapper = styled.div<{ color?: ColorNames }>`
  margin-right: 30rem;

  svg {
    color: ${({ color }) => (color ? colors[color] : colors.black)};
    height: 50rem;
  }
`;

export const SpotifyIcon = styled.div``;

export const WorldsIcon = styled.div``;

export const SecondWorldsIcon = styled.div``;

export const LabelWrapper = styled.div``;
