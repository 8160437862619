import { useCopyStore, useClubhouseStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { placeholderExperienceCopy } from 'store/copy.data';

const useHotspotOverlayCopy = () => {
  let currentClubhouse = useClubhouseStore(state => state.currentClubhouse);

  // Storybook fallback
  if (isStorybook()) currentClubhouse = 'seoul';

  const { landingPage: { clubhouses } } = useCopyStore(
    state => isStorybook() ? placeholderExperienceCopy : state.copy.experience
  );

  const clubhouseKey = Object.keys(clubhouses).filter(c => c === currentClubhouse)[0];

  const currentRibbon = isStorybook() ?
    placeholderExperienceCopy.landingPage.ribbon :
    clubhouses[clubhouseKey].ribbon;

  return {
    currentClubhouse,
    currentRibbon
  }
};

export default useHotspotOverlayCopy;
