import { useRef, RefObject } from 'react';

import { ButtonRef } from 'components/Button/Button';
import { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import { LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import { SplitContentRef } from 'components/SplitContent/SplitContent';
import gsap from 'gsap';
import { isMobileLayout } from 'utils/styles/responsive';

export interface MotionRef {
  wrapperRef: RefObject<HTMLDivElement>;
  overlayTitleRef: RefObject<OverlayTitleRef>;
  subtitleRef: RefObject<SplitContentRef>;
  bodyRef: RefObject<SplitContentRef>;
  proContentWrapperRef: RefObject<HTMLDivElement>;
  proContentRef: RefObject<HTMLDivElement>;
  mediaWrapperRef: RefObject<HTMLDivElement>;
  trackPlayerRef: RefObject<LabeledPlayerRef>;
  dividerRefs: RefObject<HTMLDivElement[]>;
  taglineLabelRef: RefObject<SplitContentRef>;
  taglineBodyRef: RefObject<SplitContentRef>;
  triviaLabelRef: RefObject<SplitContentRef>;
  triviaBodyRef: RefObject<SplitContentRef>;
  staticMediaWrapperRef: RefObject<HTMLDivElement>;
  downloadButtonRef: RefObject<ButtonRef>;
}

const useMotion = (refs: MotionRef) => {
  const animateInTl = useRef<gsap.core.Timeline>(null);
  const transitionInTl = useRef<gsap.core.Timeline>(null);
  const transitionOutTl = useRef<gsap.core.Timeline>(null);

  const {
    wrapperRef,
    overlayTitleRef,
    subtitleRef,
    bodyRef,
    proContentRef,
    mediaWrapperRef,
    proContentWrapperRef,
    trackPlayerRef,
    dividerRefs,
    taglineLabelRef,
    taglineBodyRef,
    triviaLabelRef,
    triviaBodyRef,
    staticMediaWrapperRef,
    downloadButtonRef
  } = refs;

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    animateInTl.current
      .fromTo(
        wrapperRef.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.4, ease: 'power4.inOut' },
      )
      .add(subtitleRef.current.slideInGroup(), 0.5);

    bodyRef.current &&
      animateInTl.current
        .add(bodyRef.current.slideInGroup(), 0.7)

    proContentRef.current && animateInTl.current.fromTo(
      proContentRef.current,
      { autoAlpha: 0, x: '100%' },
      { autoAlpha: 1, x: 0, duration: 0.8, ease: 'power4.inOut' },
      1
    )

    overlayTitleRef.current &&
      animateInTl.current.add(overlayTitleRef.current.show(), 0);

    taglineLabelRef.current &&
      animateInTl.current.add(taglineLabelRef.current.slideInGroup(), 0.8)

    taglineBodyRef.current &&
      animateInTl.current.add(taglineBodyRef.current.slideInGroup(), 0.8)

    triviaLabelRef.current &&
      animateInTl.current.add(triviaLabelRef.current.slideInGroup(), 0.8)

    triviaBodyRef.current &&
      animateInTl.current.add(triviaBodyRef.current.slideInGroup(), 0.8)

    trackPlayerRef.current &&
      animateInTl.current.add(trackPlayerRef.current.slideIn(), 0.8)

    staticMediaWrapperRef.current &&
      animateInTl.current
        .fromTo(
          staticMediaWrapperRef.current,
          { clipPath: 'inset(0% 0% 0% 100%)' },
          { clipPath: 'inset(0% 0% 0% 0%)', duration: 0.9, ease: 'expo.inOut' },
          0.8
        )

    // this only fires for Pro overlay on mobile
    mediaWrapperRef.current &&
      animateInTl.current
        .fromTo(
          mediaWrapperRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.9, ease: 'expo.inOut' },
          0.8
        )

    downloadButtonRef.current &&
      animateInTl.current.add(downloadButtonRef.current.show(), 1.5);

    if (dividerRefs.current.length) {
      dividerRefs.current.forEach(divider => {
        divider && animateInTl.current
          .fromTo(
            divider,
            { scaleX: 0 },
            { scaleX: 1, duration: 2, ease: 'power4.inOut' },
            0
          )
      })
    }

    return animateInTl.current;
  };

  const transitionIn = () => {
    if (transitionInTl.current) transitionInTl.current.kill();

    transitionInTl.current = gsap.timeline({
      onComplete: () => transitionInTl.current = null
    });

    const proContentMotionProps = isMobileLayout() ?
      {
        from: { x: '100%' },
        to: { x: 0 }
      } :
      {
        from: { y: '100%' },
        to: { y: 0 }
      };

    transitionInTl.current
      .set(proContentWrapperRef.current, { overflow: 'hidden' })
      .to(
        wrapperRef.current,
        { autoAlpha: 1, duration: 0.2, ease: 'none' },
      )
      .add(isMobileLayout() ? subtitleRef.current.slideInGroup() : subtitleRef.current.maskIn(), 0.3)
      .add(isMobileLayout() ? bodyRef.current.slideInGroup() : bodyRef.current.maskIn(), 0.5)
      .fromTo(
        proContentRef.current,
        { ...proContentMotionProps.from },
        { ...proContentMotionProps.to, duration: 0.8, ease: 'power4.inOut' },
        0.7
      )
      .add(isMobileLayout() ? trackPlayerRef.current.slideIn() : trackPlayerRef.current.maskIn(), 0.9)

    // this only fires for Pro overlay on mobile
    mediaWrapperRef.current &&
      transitionInTl.current
        .fromTo(
          mediaWrapperRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.9, ease: 'expo.inOut' },
          0.7
        )

    if (dividerRefs.current.length) {
      dividerRefs.current.forEach(divider => {
        divider && transitionInTl.current
          .fromTo(
            divider,
            { scaleX: 0 },
            { scaleX: 1, duration: 0.8, ease: 'power4.inOut' },
            0
          )
      })
    }

    return transitionInTl.current;
  };

  const transitionOut = () => {
    if (transitionOutTl.current) transitionOutTl.current.kill();

    transitionOutTl.current = gsap.timeline({
      onComplete: () => transitionOutTl.current = null
    });

    const proContentMotionProps = isMobileLayout() ?
      {
        from: { x: 0 },
        to: { x: '-110%' }
      } :
      {
        from: { y: 0 },
        to: { y: '-110%' }
      };

    transitionOutTl.current
      .set(proContentWrapperRef.current, { overflow: 'hidden' })
      .add(isMobileLayout() ? bodyRef.current.slideOutGroup() : bodyRef.current.maskOut(), 0)
      .add(isMobileLayout() ? subtitleRef.current.slideOutGroup() : subtitleRef.current.maskOut(), 0)
      .fromTo(
        proContentRef.current,
        { ...proContentMotionProps.from },
        { ...proContentMotionProps.to, duration: 0.8, ease: 'power4.inOut' },
        0
      )
      .add(isMobileLayout() ? trackPlayerRef.current.slideOut() : trackPlayerRef.current.maskOut(), 0)
      .to(
        wrapperRef.current,
        { autoAlpha: 0, duration: 0.2, ease: 'power4.inOut' },
      );

    // this only fires for Pro overlay on mobile
    mediaWrapperRef.current &&
      transitionOutTl.current
        .fromTo(
          mediaWrapperRef.current,
          { opacity: 1 },
          { opacity: 0, duration: 0.9, ease: 'expo.inOut' },
          0
        )

    if (dividerRefs.current.length) {
      dividerRefs.current.forEach(divider => {
        divider && transitionOutTl.current
          .fromTo(
            divider,
            { scaleX: 1 },
            { scaleX: 0, duration: 0.8, ease: 'power4.inOut' },
            0
          )
      })
    }

    return transitionOutTl.current;
  }

  return {
    animateIn,
    transitionIn,
    transitionOut
  }
};

export default useMotion;
