import React from 'react';

import Pro from './Pro/Pro';
import Champion from './Champion/Champion';
import Skill from './Skill/Skill';
import { HotspotTypes } from 'store/clubhouse.types';
import * as Styled from './Secondary.styles';

export const validTypes = [HotspotTypes.pro, HotspotTypes.champion, HotspotTypes.skill];

export interface SecondaryProps {
  type?: typeof validTypes[number];
}

const defaultProps: Partial<SecondaryProps> = {
  type: HotspotTypes.pro
};

const Secondary: React.FC<SecondaryProps> = ({
  type
}: SecondaryProps) => {
  const isPro = type === HotspotTypes.pro;
  const isChampion = type === HotspotTypes.champion;
  const isSkill = type === HotspotTypes.skill;

  return (
    <Styled.Wrapper>
      {isPro && <Pro />}
      {isChampion && <Champion />}
      {isSkill && <Skill />}
    </Styled.Wrapper>
  );
};

Secondary.defaultProps = defaultProps;

export default Secondary;
