import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export const useMotion = () => {
  const timelineRef = useRef<gsap.core.Timeline>(null);

  const spotifyIconRef = useRef<HTMLDivElement>(null);
  const worldsIconRef = useRef<HTMLDivElement>(null);
  const secondWorldsIconRef = useRef<HTMLDivElement>(null);
  const dashRef = useRef<HTMLDivElement>(null);
  const overlayIconRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const show = () => {
    if (timelineRef.current) timelineRef.current.kill();

    timelineRef.current = gsap.timeline({
      onComplete: () => {
        timelineRef.current = null;
      },
    });

    const items = [
      spotifyIconRef.current,
      worldsIconRef.current,
      secondWorldsIconRef.current,
      dashRef.current,
      overlayIconRef.current,
      labelRef.current,
    ].filter(Boolean);

    if (items.length) {
      timelineRef.current
        .set(items, { visibility: 'hidden' })
        .fromTo(
          items,
          { visibility: 'hidden', x: 125 },
          {
            visibility: 'visible',
            x: 0,
            duration: 0.6,
            stagger: 0.1,
            ease: 'power3.inOut',
          },
          0.3
        )
        .fromTo(
          dashRef.current,
          { scaleX: 0 },
          { scaleX: 1, duration: 0.6, ease: 'power3.out' },
          0.6
        );
    }

    return timelineRef.current;
  };

  useEffect(() => {
    return () => {
      if (timelineRef.current) timelineRef.current.kill();
    };
  }, []);

  return {
    spotifyIconRef,
    worldsIconRef,
    secondWorldsIconRef,
    dashRef,
    overlayIconRef,
    labelRef,
    show
  };
};
