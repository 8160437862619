import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fullscreen } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  ${fullscreen('fixed')}
  background-color: ${colors.black};
  pointer-events: none;
  user-select: none;
  z-index: ${layout.zIndex.header};
  opacity: 0;
`;
