import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';
import {
  SplitContentFontScale,
  SplitContentAlignment,
  SplitContentMotionType
} from './SplitContent';

export const Wrapper = styled.div<{
  fontScale: SplitContentFontScale,
  alignment: SplitContentAlignment,
  motionType: SplitContentMotionType
}>`
  ${({ theme: { locale }, fontScale }) => setTypography(fontScale, locale)};
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  margin: 0 ${-1 * layout.headerFooterPadding.mobile}rem;

  user-select: none;
  * {
    user-select: none;
  }

  ${props => props.motionType === SplitContentMotionType.SlidingGroup ? `
    transform: translateX(300%);
    opacity: 0;
    visibility: hidden;
  ` : ''}

  ${mediaTablet(`
    padding: 0 40rem;
    margin: 0 -40rem;
  `)}

  .SplitText__wrapper {
    overflow: hidden;

    ${props => props.motionType === SplitContentMotionType.SlidingLines ? `
      transform: translateX(300%);
      opacity: 0;
      visibility: hidden;
    ` : ''}
  }

  .SplitText__line {
    position: relative;
  }
`;
