import { useCopyStore, useClubhouseStore, usePlaylistsStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { placeholderHotspotTracks } from 'store/playlists.data';

const useEasterEggCopy = () => {
  let currentClubhouse = useClubhouseStore(state => state.currentClubhouse);
  const { hotspotTracks } = usePlaylistsStore();

  // Storybook fallback
  if (isStorybook()) {
    currentClubhouse = 'seoul';
  }

  const clubhouseCopy = useCopyStore(state => state.copy.clubhouses[currentClubhouse]);
  if (!clubhouseCopy) return null;
  const easterEgg = clubhouseCopy.hotspotsOverlays.easterEgg;

  const track = isStorybook() ?
    placeholderHotspotTracks.easterEgg :
    hotspotTracks[currentClubhouse].easterEgg;

  return {
    easterEgg,
    track,
    currentClubhouse
  };
};

export default useEasterEggCopy;
