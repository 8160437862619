import { useCopyStore, useClubhouseStore, usePlaylistsStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { placeholderHotspotTracks } from 'store/playlists.data';

const useSkillCopy = () => {
  let currentClubhouse = useClubhouseStore(state => state.currentClubhouse);
  const { hotspotTracks } = usePlaylistsStore();

  // Storybook fallback
  if (isStorybook()) {
    currentClubhouse = 'seoul';
  }

  const clubhouseCopy = useCopyStore(state => state.copy.clubhouses[currentClubhouse]);
  if (!clubhouseCopy) return null;
  const featuredSkill = clubhouseCopy.hotspotsOverlays.skill;

  const track = isStorybook() ?
    placeholderHotspotTracks.skill :
    hotspotTracks[currentClubhouse].skill;

  return {
    featuredSkill,
    track,
    currentClubhouse
  };
};

export default useSkillCopy;
