export const fillArray = (length = 0) => {
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(undefined);
  }

  return result;
};
/**
 * @export
 * @function Clamp.
 * @description Keeps a number between two values.
 * @param {Number} number - Number to clamp.
 * @param {Number} minimum - The smallest possible value.
 * @param {Number} maximum - The largest possible value.
 * @return {Number} The clamped array
 */
export const clamp = (number, minimum, maximum) =>
  Math.min(Math.max(number, minimum), maximum);
