import styled from 'styled-components';
import { mediaTablet } from 'utils/styles/responsive';
import { setTypography } from 'utils/styles/mixins';
import { layout } from 'utils/styles/theme';
import { LabeledPlayerLabelAlignment } from './LabeledPlayer';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  overflow: hidden;
`;

export const Label = styled.div<{ labelAlignment: LabeledPlayerLabelAlignment; isBig: boolean }>`
  ${({ theme: { locale }, isBig }) => setTypography(isBig ? 'leadBody' : 'subtitle2', locale)};
  ${props => props.labelAlignment === LabeledPlayerLabelAlignment.Center ? `
    text-align: center;
  ` : ''}
  padding-bottom: 40rem;

  ${mediaTablet(`
    padding-bottom: 20rem;
  `)}
`;

export const PlayerWrapper = styled.div<{ labelAlignment: LabeledPlayerLabelAlignment }>`
  padding-bottom: 100rem;
  overflow: hidden;
  ${props => props.labelAlignment === LabeledPlayerLabelAlignment.Center ? `
    display: flex;
    justify-content: center;
  ` : ''}
`;

export const Player = styled.div`
  width: 100%;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(-120%);
  padding-top: ${1 * layout.tooltipSize}rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${mediaTablet(`
    transform: translateY(70%);
  `)}
`;
