import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';

import DuelingBillboards from 'components/DuelingBillboards/DuelingBillboards';
import { defaultBGMData, SoundData, SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { StorytellingTrackInfo, Track, TrackInfo } from 'store/playlists.types';
import { useClubhouseStore, useCopyStore, usePlaylistsStore, useGlobalStore } from 'store';
import { HotspotTypes } from 'store/clubhouse.types';
import { BlackBarPositionState, BlackBarTransitionState } from 'store/global.types';
import { usePreloadDirectory } from 'u9/hooks';
import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import { getApiTrack, getTrackFromApiTrack } from 'utils/api';
import { ApiTrack } from 'utils/api.types';
import { HOTSPOTS_TRACK_INFO } from 'utils/config.assets';
import { getFormattedPercentage } from 'utils/numbers';
import { layout } from 'utils/styles/theme';

import * as Styled from './ClubhouseLoadingOverlay.styles';

export interface ClubhouseLoadingOverlayProps {
  clubhouse: '' | ClubhousePageProps['city'];
  isVisible: boolean;
}

const defaultProps: Partial<ClubhouseLoadingOverlayProps> = {
  clubhouse: '',
  isVisible: false
};

const preloadDirectories: Record<ClubhousePageProps['city'], string[]> = {
  seoul: ['assets/images/clubhouses/seoul'],
  berlin: ['assets/images/clubhouses/berlin'],
  shanghai: ['assets/images/clubhouses/shanghai']
};

const ClubhouseLoadingOverlay: React.FC<ClubhouseLoadingOverlayProps> = ({ clubhouse, isVisible }) => {
  const [audioProgress, setAudioProgress] = useState<number>(0);
  const [transitionFinished, setTransitionFinished] = useState<boolean>(false);
  const loadProgress = usePreloadDirectory(preloadDirectories[clubhouse] || []);
  const { copy } = useCopyStore(state => state);
  const {
    setIsLoading,
    isLoading,
    setReady,
    setClubhousePreloaded,
    hasClubhousePreloaded,
    hasCanvasLoaded
  } = useClubhouseStore((state) => state);
  const router = useRouter();

  const { hotspotTracks, setClubhouseHotspotTracks } = usePlaylistsStore((state) => state);

  const isLoadingTracks = useRef<boolean>(false);

  const totalProgress = useMemo(() => (loadProgress + audioProgress) / 2, [loadProgress, audioProgress]);
  const { setBlackBarTransition, blackBarTransition } = useGlobalStore(state => state);

  useEffect(() => {
    if (totalProgress >= 100 && hasCanvasLoaded) {
      if (isLoading) {
        setIsLoading(false);
        setReady(true);

        if (!hasClubhousePreloaded[clubhouse]) setClubhousePreloaded(clubhouse as ClubhousePageProps['city'], true);

        setBlackBarTransition(BlackBarTransitionState.In, BlackBarPositionState.Center, BlackBarPositionState.Cover, () => {
          setTransitionFinished(true);

          setBlackBarTransition(BlackBarTransitionState.Out, BlackBarPositionState.Cover, BlackBarPositionState.Edge, () => {
            setBlackBarTransition(BlackBarTransitionState.None, BlackBarPositionState.Cover, BlackBarPositionState.Edge)
          });
        });
      }
    } else {
      if (blackBarTransition !== BlackBarTransitionState.Out && isVisible && !hasClubhousePreloaded[clubhouse]) {
        setBlackBarTransition(BlackBarTransitionState.Out, BlackBarPositionState.Cover, BlackBarPositionState.Center)
      }
    }
  }, [
    hasCanvasLoaded,
    isLoading,
    isVisible,
    totalProgress,
    setIsLoading,
    blackBarTransition,
    setBlackBarTransition,
    setReady,
    setClubhousePreloaded,
    hasClubhousePreloaded,
    clubhouse
  ]);

  useEffect(() => {
    if (!clubhouse) {
      isLoadingTracks.current = false;
      setReady(false);
      setTransitionFinished(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubhouse]);

  useEffect(() => {
    if (clubhouse && copy.clubhouses && !isLoadingTracks.current) {
      isLoadingTracks.current = true;

      // Get hotspot tracks
      const hotspotTrackInfo = HOTSPOTS_TRACK_INFO[clubhouse];
      const tracks = { ...hotspotTracks[clubhouse] };
      const trackPromises: Promise<AxiosResponse<ApiTrack>>[] = [];

      const previewSounds: Record<string, SoundData> = {};
      const addPreviewSoundData = (track: Track) => {
        previewSounds[track.id] = {
          ...defaultBGMData,
          id: track.id,
          file: track.previewUrl
        };
      };

      const storytellingType = HotspotTypes.storytelling1.replace(/\d+/g, '');
      Object.entries(hotspotTrackInfo).forEach(async ([hotspotType, trackInfos]) => {
        const isStorytelling = hotspotType.replace(/\d+/g, '') === storytellingType;

        if (isStorytelling) {
          (trackInfos as StorytellingTrackInfo[]).forEach((storytellingTrackInfos, index) => {
            Object.entries(storytellingTrackInfos).forEach(async ([pageKey, trackInfo]: [string, TrackInfo]) => {
              if (trackInfo) {
                const request = getApiTrack(trackInfo.id);
                trackPromises.push(request);

                const { data: apiTrack } = await request;
                const track = getTrackFromApiTrack({
                  ...apiTrack,
                  // The CMS hardcoded url is used as fallback
                  preview_url: apiTrack.preview_url || trackInfo.previewUrl
                });
                tracks[hotspotType][index][pageKey] = track;

                if (track.previewUrl) addPreviewSoundData(track);
              }
            });
          });
        } else if (hotspotType === HotspotTypes.pro) {
          (trackInfos as TrackInfo[]).forEach(async (trackInfo, index) => {
            if (trackInfo) {
              const request = getApiTrack(trackInfo.id);
              trackPromises.push(request);

              const { data: apiTrack } = await request;
              const track = getTrackFromApiTrack({
                ...apiTrack,
                preview_url: apiTrack.preview_url || trackInfo.previewUrl
              });
              tracks[hotspotType][index] = track;

              if (track.previewUrl) addPreviewSoundData(track);
            }
          });
        } else if ([HotspotTypes.champion, HotspotTypes.skill, HotspotTypes.easterEgg].includes(hotspotType as HotspotTypes)) {
          const hotspotTrackInfo = trackInfos as TrackInfo;
          if (hotspotTrackInfo) {
            const request = getApiTrack(hotspotTrackInfo.id);
            trackPromises.push(request);

            const { data: apiTrack } = await request;
            const track = getTrackFromApiTrack({
              ...apiTrack,
              preview_url: apiTrack.preview_url || hotspotTrackInfo.previewUrl
            });
            tracks[hotspotType] = track;

            if (track.previewUrl) addPreviewSoundData(track);
          }
        }
      });

      Promise.all(trackPromises).then(() => {
        setClubhouseHotspotTracks(clubhouse, tracks);

        Object.entries(previewSounds).forEach(([id, sound]) => {
          SOUNDS_DATA[id] = sound;
        });

        AudioManager.init((progress) => setAudioProgress(Math.floor(progress * 100)), () => setAudioProgress(100));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubhouse, copy.clubhouses]);

  return (
    <AnimatePresence>
      {((isLoading || !transitionFinished) && isVisible) &&
        <Styled.Wrapper>
          <DuelingBillboards
            topCopy={copy.experience.loading.billboard}
            bottomCopy={getFormattedPercentage(Math.floor(totalProgress) / 100, router.locale)}
            ribbonCopy={copy.experience.landingPage.ribbon}
            ribbonIcon={clubhouse}
            ribbonIconSize={layout.iconSizes[clubhouse].ribbon}
            selfRibbonSlide
            animateInLettersTop
          />
        </Styled.Wrapper>
      }
    </AnimatePresence>
  );
};

ClubhouseLoadingOverlay.defaultProps = defaultProps;

export default ClubhouseLoadingOverlay;
