import React, { useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';

import OverlayTitle, { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import Ribbon from 'components/Ribbon/Ribbon';
import LabeledPlayer, { LabeledPlayerLabelAlignment, LabeledPlayerRef } from 'components/LabeledPlayer/LabeledPlayer';
import SplitContent, { SplitContentFontScale, SplitContentAlignment, SplitContentRef } from 'components/SplitContent/SplitContent';
import BackingImage, { BackingImageRef } from 'components/BackingImage/BackingImage';
import PreloaderService from 'u9/services/preloader.service';
import { EasterEggHotspotOverlay } from 'store/copy.types';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames, layout } from 'utils/styles/theme';
import { HotspotOverlayTypes } from 'store/clubhouse.types';

import useHotspotOverlayCopy from './../HotspotOverlay.copy';
import useEasterEggAssets from './EasterEgg.assets';
import useEasterEggCopy from './EasterEgg.copy';
import useMotion from './EasterEgg.motion';
import * as Styled from './EasterEgg.styles';

export interface EasterEggRef {
  animateOut: (cb?: () => void) => gsap.core.Timeline;
  animateIn: (cb?: () => void) => gsap.core.Timeline;
}

export interface EasterEggProps { }

const defaultProps: Partial<EasterEggProps> = {};

const EasterEgg = React.forwardRef<EasterEggRef, EasterEggProps>(
  (props: EasterEggProps, ref) => {
    const { currentRibbon } = useHotspotOverlayCopy();
    const copy = useEasterEggCopy();
    const easterEgg: EasterEggHotspotOverlay = copy.easterEgg;
    const track = copy.track;
    const currentClubhouse = copy.currentClubhouse;

    const { easterEggImages } = useEasterEggAssets(currentClubhouse);

    const contentWrapperRef = useRef<HTMLDivElement>(null);
    const ribbonWrapperRef = useRef<HTMLDivElement>(null);
    const backingImageRefs = useRef<BackingImageRef[]>([]);
    const overlayTitleRef = useRef<OverlayTitleRef>(null);
    const subtitleRef = useRef<SplitContentRef>(null);
    const bodyRef = useRef<SplitContentRef>(null);
    const teaserRef = useRef<SplitContentRef>(null);
    const trackRef = useRef<LabeledPlayerRef>(null);

    const imageComponents = easterEggImages.map((image, index) => (
      <BackingImage
        key={`BackingImage-${index}`}
        ref={ref => backingImageRefs.current[index] = ref}
        image={PreloaderService.get(image.src)}
        top={image.top}
        left={image.left}
        scale={image.scale}
      />
    ));

    const { animateIn, animateOut } = useMotion({
      contentWrapperRef,
      ribbonWrapperRef,
      overlayTitleRef,
      backingImageRefs,
      subtitleRef,
      bodyRef,
      teaserRef,
      trackRef
    });

    useImperativeHandle(
      ref,
      (): EasterEggRef => ({
        animateIn,
        animateOut
      })
    );

    return (
      <Styled.Wrapper>
        <Styled.RibbonWrapper ref={ribbonWrapperRef}>
          <Ribbon
            copy={currentRibbon}
            alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
            icon={currentClubhouse}
            iconSize={layout.iconSizes[currentClubhouse].ribbon}
          />
        </Styled.RibbonWrapper>
        <Styled.ContentWrapper ref={contentWrapperRef}>
          <Styled.BackingImageWrapper>
            {imageComponents}
          </Styled.BackingImageWrapper>
          <Styled.ContentWrapperBackground />
          <Styled.ContentInner>
            <OverlayTitle
              ref={overlayTitleRef}
              hasWorldsIcon={false}
              overlayType={HotspotOverlayTypes.EasterEgg}
              title={easterEgg.title}
              color={ColorNames.white}
              dashColor={ColorNames.black}
            />
            <Styled.Subtitle>
              <SplitContent
                ref={subtitleRef}
                copy={easterEgg.subtitle}
                fontScale={SplitContentFontScale.Heading4}
                alignment={SplitContentAlignment.Center}
              />
            </Styled.Subtitle>
            <Styled.Body>
              <SplitContent
                ref={bodyRef}
                copy={easterEgg.body}
                fontScale={SplitContentFontScale.LeadBody}
                alignment={SplitContentAlignment.Center}
              />
            </Styled.Body>
            <Styled.Teaser>
              <SplitContent
                ref={teaserRef}
                copy={easterEgg.teaser}
                fontScale={SplitContentFontScale.Body}
                alignment={SplitContentAlignment.Center}
              />
            </Styled.Teaser>
            <Styled.MusicWrapper>
              <LabeledPlayer
                ref={trackRef}
                label={easterEgg.musicIntro}
                labelAlignment={LabeledPlayerLabelAlignment.Center}
                track={track}
                premiumPrompt={ColorNames.white}
                theme={ColorNames.blueRibbon}
                hasBigLabel
              />
            </Styled.MusicWrapper>
          </Styled.ContentInner>
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    );
  }
);

EasterEgg.defaultProps = defaultProps;

export default EasterEgg;
