import { useRef, RefObject, useEffect } from 'react';
import gsap from 'gsap';

import { SplitContentRef } from 'components/SplitContent/SplitContent';

export interface MotionRefs {
  wrapperRef?: RefObject<HTMLDivElement>;
  anthemTrackWrapperRef?: RefObject<HTMLDivElement>;
  divideLineRefs?: RefObject<HTMLDivElement[]>;
  favoritesTitleRef?: RefObject<SplitContentRef>;
  recommendsTitleRef?: RefObject<SplitContentRef>;
  recommendsDescRef?: RefObject<SplitContentRef>;
  favoriteTrackRefs?: RefObject<HTMLDivElement[]>;
  recommendedTrackRefs?: RefObject<HTMLDivElement[]>;
  noFavoriteRef?: RefObject<HTMLDivElement>;
  noRecommendRef?: RefObject<HTMLDivElement>;
}

const useMotion = (ref: MotionRefs) => {
  const animateInTl = useRef<gsap.core.Timeline>(null);
  const animateOutTl = useRef<gsap.core.Timeline>(null);
  const favoriteTracksTl = useRef<gsap.core.Timeline>(null);

  const {
    wrapperRef,
    anthemTrackWrapperRef,
    divideLineRefs,
    favoritesTitleRef,
    recommendsTitleRef,
    recommendsDescRef,
    favoriteTrackRefs,
    recommendedTrackRefs,
    noFavoriteRef,
    noRecommendRef
  } = ref;

  const animateIn = () => {
    if (animateInTl.current) animateInTl.current.kill();

    animateInTl.current = gsap.timeline({
      onComplete: () => animateInTl.current = null
    });

    animateInTl.current
      .fromTo(
        [wrapperRef.current, anthemTrackWrapperRef.current],
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
      );

    animateInTl.current
      .fromTo(
        divideLineRefs.current[0],
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
        '-=0.5'
      )
      .add(favoritesTitleRef.current.maskIn(), '-=0.8')

    noFavoriteRef.current &&
      animateInTl.current
        .fromTo(
          noFavoriteRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
          '-=0.5'
        )

    animateInTl.current
      .fromTo(
        divideLineRefs.current[1],
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
        '-=0.5'
      )
      .add(recommendsTitleRef.current.maskIn(), '-=0.8')

    recommendsDescRef.current &&
      animateInTl.current
        .add(recommendsDescRef.current.maskIn(), '-=0.5')

    noRecommendRef.current &&
      animateInTl.current
        .fromTo(
          noRecommendRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.5, ease: 'power4.inOut' },
          '-=0.5'
        )

    recommendedTrackRefs.current.length > 0 && animateInTl.current
      .fromTo(
        recommendedTrackRefs.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.8, ease: 'power4.inOut', stagger: 0.15 },
        '-=0.5'
      )

    return animateInTl.current;
  };

  const animateOut = () => {
    if (animateOutTl.current) animateOutTl.current.kill();

    animateOutTl.current = gsap.timeline({
      onComplete: () => animateOutTl.current = null
    });

    favoriteTrackRefs.current.length > 0 && animateOutTl.current
      .fromTo(
        favoriteTrackRefs.current,
        { opacity: 1 },
        { opacity: 0, duration: 0.2, ease: 'power4.inOut', stagger: 0.15 },
        '-=0.5'
      )

    return animateOutTl.current;
  };

  const animateFavoriteTracks = () => {
    favoriteTracksTl.current && favoriteTracksTl.current.kill();

    favoriteTracksTl.current = gsap.timeline({
      onComplete: () => favoriteTracksTl.current = null
    })

    favoriteTracksTl.current
      .fromTo(
        favoriteTrackRefs.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.8, ease: 'power4.inOut', stagger: 0.15 }
      )

    return favoriteTracksTl.current;
  }

  useEffect(() => {
    return () => {
      if (animateInTl.current) animateInTl.current.kill();
      if (animateOutTl.current) animateOutTl.current.kill();
      if (favoriteTracksTl.current) favoriteTracksTl.current.kill();
    };
    // eslint-disable-next-line
  }, []);

  return {
    animateIn,
    animateOut,
    animateFavoriteTracks
  };
};

export default useMotion;
