import { useClubhouseStore } from 'store';
import gtm from 'u9/utils/gtm';

const useGtm = () => {
  const { currentClubhouse } = useClubhouseStore(state => state);
  const clubhouseName = currentClubhouse === 'seoul' ? 'LCK' : currentClubhouse === 'berlin' ? 'LEC' : 'LPL';
  const handleProfileClick = () => {
    gtm.trackEvent(`${clubhouseName} Featured Champion - overlay`, 'Click - see profile', `${clubhouseName} Featured Champion - see profile`);
  }

  return { handleProfileClick };
};

export default useGtm;
