import { useCallback, useEffect } from 'react';

import { useClubhouseStore } from 'store';
import gtm from 'u9/utils/gtm';
import { isMobileLayout } from 'utils/styles/responsive';

const useAnalytics = (held: boolean) => {
  const { currentClubhouse, currentHotspot, hasBeenOnboarded } = useClubhouseStore();
  const clubhouseName = currentClubhouse === 'seoul' ? 'LCK' : currentClubhouse === 'berlin' ? 'LEC' : 'LPL';

  const handleHoldAnalytics = useCallback(() => {
    if (currentHotspot) {
      switch (currentHotspot.type) {
        case 'pro':
          gtm.trackEvent(`${clubhouseName} Featured Pros - overlay`, 'Click+hold - view BTS', `${clubhouseName} Featured Pros overlay - view BTS`);
          break;
        case 'champion':
          gtm.trackEvent(`${clubhouseName} Featured Champion - overlay`, 'Click+hold - view BTS', `${clubhouseName} Featured Champion - view BTS`);
          break;
        case 'skill':
          isMobileLayout() ? gtm.trackEvent(`${clubhouseName} Featured Skill - Hotspot`, 'Click+hold - view BTS', `${clubhouseName} - Ft Skill HS Mobile - view BTS`) :
            gtm.trackEvent(`${clubhouseName} Featured Skill - Hotspot`, 'Click+hold - view BTS', `${clubhouseName} - Ft Skill HS - view BTS`);
          break;
        case 'easterEgg':
          isMobileLayout() ? gtm.trackEvent(`${clubhouseName} - Easter Egg - Hotspot`, 'Click+hold - view BTS', `${clubhouseName} - Easter Egg Mobile - view BTS`) :
            gtm.trackEvent(`${clubhouseName} - Easter Egg - Hotspot`, 'Click+hold - view BTS', `${clubhouseName} - Easter Egg - view BTS`);
          break;
        case 'storytelling1':
          hasBeenOnboarded ? gtm.trackEvent(`${clubhouseName} Storytelling Act 1 - overlay`, 'Click+hold - view BTS', `${clubhouseName} Storytelling Act 1 - view BTS`) :
            gtm.trackEvent(`${clubhouseName} Storytelling : Act 1 (FT user)`, 'Click+hold - view BTS', `${clubhouseName} Storytelling Act 1 (FT User) - view BTS`);
          break;
        case 'storytelling2':
          gtm.trackEvent(`${clubhouseName} Storytelling Act 2 - overlay`, 'Click+hold - view BTS', `${clubhouseName} Storytelling Act 2 - click+hold`);
          break;
        case 'storytelling3':
          gtm.trackEvent(`${clubhouseName} Storytelling Act 3 - overlay`, 'Click+hold - view BTS', `${clubhouseName} Storytelling Act 3 - click+hold`);
          break;
      }
    }
  }, [clubhouseName, currentHotspot, hasBeenOnboarded]);

  useEffect(() => {
    if (held) {
      handleHoldAnalytics();
    }
  }, [held, handleHoldAnalytics]);
};

export default useAnalytics;
