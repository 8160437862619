import React, {
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import Hls from 'hls.js';

import PlayPause from 'components/PlayPause/PlayPause';
import { useAudioStore, useGlobalStore } from 'store';
import { ReactComponent as SvgFullscreen } from 'svgs/fullscreen.svg';
import { usePageVisibility, useWindowSize } from 'u9/hooks';
import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import { ANTHEM_VIDEO } from 'utils/config.assets';
import { getParsedTimeString } from 'utils/time';

import * as Styled from './AnthemVideoOverlayControls.styles';

interface AnthemVideoOverlayControlsProps {
  video: MutableRefObject<HTMLVideoElement>;
  isStalled: boolean;
  setStalled: (state: boolean) => void;
}

const STALLED_TIMEOUT = 4000; // ms
const DEBUG_MP4 = process.env.IS_DEBUG && false;
const IS_DEBUG = process.env.IS_DEBUG && false;

const AnthemVideoOverlayControls = ({ video, isStalled, setStalled }: AnthemVideoOverlayControlsProps) => {
  const { setMuted } = useAudioStore();
  const { isWindowTooSmall, shouldRotateDevice } = useGlobalStore(state => state);
  const isPageVisible = usePageVisibility();
  const windowSize = useWindowSize();

  const [isReady, setReady] = useState<boolean>(false);
  const [isPaused, setPaused] = useState<boolean>(false);
  const [isUserPaused, setUserPaused] = useState<boolean>(false);
  const [hasEnded, setEnded] = useState<boolean>(false);
  const [isSeeking, setSeeking] = useState<boolean>(false);

  const hls = useRef<Hls>(null);
  const hasListeners = useRef<boolean>(false);
  const isReadyRef = useRef<boolean>(false);
  const isPausedRef = useRef<boolean>(isPaused);
  const isUserPausedRef = useRef<boolean>(isUserPaused);
  const hasEndedRef = useRef<boolean>(hasEnded);
  const isSeekingRef = useRef<boolean>(isSeeking);
  const isStalledRef = useRef<boolean>(isStalled);
  const stalledTimeout = useRef<number>(null);

  const progressBarRef = useRef<HTMLDivElement>(null);
  const timeWrapperRef = useRef<HTMLDivElement>(null);

  const onUpdate = useCallback(() => {
    if (!video.current || ((hasEndedRef.current || isPausedRef.current) && !isSeekingRef.current)) return;
    if (IS_DEBUG) console.log('onUpdate');

    if (progressBarRef.current) {
      const progress = video.current.currentTime / video.current.duration;
      const currentProgressBar = progressBarRef.current.firstElementChild as HTMLDivElement;
      currentProgressBar.style.transform = `scaleX(${progress}) translateZ(0)`;
    }

    if (timeWrapperRef.current) {
      const elapsedTime = timeWrapperRef.current.firstElementChild;
      elapsedTime.innerHTML = getParsedTimeString(video.current.currentTime);
    }

    if (stalledTimeout.current) window.clearTimeout(stalledTimeout.current);
    if (isStalledRef.current) {
      if (IS_DEBUG) console.log('onStalledEnd', isStalledRef.current);
      setStalled(false);
    }

    stalledTimeout.current = window.setTimeout(() => {
      stalledTimeout.current = null;
      if (IS_DEBUG) console.log('onStalled');
      setStalled(true);
    }, STALLED_TIMEOUT);
  }, [setStalled, video]);

  const onPlay = useCallback(() => {
    if (!video.current) return;
    if (IS_DEBUG) console.log('onPlay');

    setPaused(false);
    setEnded(false);
    setUserPaused(false);
  }, [video]);

  const onPause = useCallback(() => {
    if (!video.current) return;
    if (IS_DEBUG) console.log('onPause');

    clearStalledTimeout();
    setPaused(true);
  }, [video]);

  const onEnd = useCallback(() => {
    if (!video.current) return;
    if (IS_DEBUG) console.log('onEnd');

    clearStalledTimeout();
    setEnded(true);
  }, [video]);

  const onTogglePause = useCallback(async () => {
    if (!video.current || !isReadyRef.current) return;

    if (isPausedRef.current || hasEndedRef.current || isStalledRef.current) video.current.play();
    else video.current.pause();

    setUserPaused(video.current.paused);
  }, [video]);

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault();
      onTogglePause();
    }
  }, [onTogglePause]);

  const enterFullscreen = useCallback(() => {
    // Cross-device fullscreen: https://codepen.io/MSEdgeDev/pen/qZdVYy and video.js

    let shouldAddControls = true;

    // Standard, Mac OS, iOS
    if (video.current.requestFullscreen) video.current.requestFullscreen();
    else if (video.current['webkitRequestFullscreen']) video.current['webkitRequestFullscreen']();
    else if (video.current['webkitEnterFullscreen']) video.current['webkitEnterFullscreen']();
    else {
      shouldAddControls = false;
      if (process.env.IS_DEBUG) console.error('AnthemVideoOverlay -- fullscreen request error');
    }

    if (shouldAddControls) video.current.controls = true;
  }, [video]);

  const onExitFullscreen = useCallback((event: Event) => {
    if (
      event.type === 'webkitendfullscreen' ||
      event.type === 'webkitfullscreenchange' && !document['webkitIsFullScreen'] ||
      event.type === 'fullscreenchange' && !document.fullscreenElement
    ) {
      // Resync muted state
      setMuted(video.current.muted, true);

      video.current.controls = false;
    }
  }, [setMuted, video]);

  const addListeners = useCallback(() => {
    if (!video.current) return;

    video.current.addEventListener('play', onPlay);
    video.current.addEventListener('pause', onPause);
    video.current.addEventListener('ended', onEnd);
    video.current.addEventListener('timeupdate', onUpdate);
    video.current.addEventListener('seeking', onUpdate);
    video.current.addEventListener('fullscreenchange', onExitFullscreen);
    video.current.addEventListener('webkitendfullscreen', onExitFullscreen);
    addEventListeners(video.current, 'click touchend', onTogglePause);

    hasListeners.current = true;
  }, [onEnd, onExitFullscreen, onPause, onPlay, onTogglePause, onUpdate, video]);

  const removeListeners = useCallback(() => {
    if (!video.current) return;

    video.current.removeEventListener('play', onPlay);
    video.current.removeEventListener('pause', onPause);
    video.current.removeEventListener('ended', onEnd);
    video.current.removeEventListener('timeupdate', onUpdate);
    video.current.removeEventListener('seeking', onUpdate);
    video.current.removeEventListener('fullscreenchange', onExitFullscreen);
    video.current.removeEventListener('webkitendfullscreen', onExitFullscreen);
    removeEventListeners(video.current, 'click touchend', onTogglePause);

    hasListeners.current = false;
  }, [onEnd, onExitFullscreen, onPause, onPlay, onTogglePause, onUpdate, video]);

  const clearStalledTimeout = () => {
    if (stalledTimeout.current) {
      window.clearTimeout(stalledTimeout.current);
      stalledTimeout.current = null;
    }
  };

  const onStartSeeking = () => {
    setSeeking(true);
    if (video.current) video.current.pause();
  };

  const onProgressBarMove = useCallback((event: MouseEvent | TouchEvent, isClick = false) => {
    if (!isSeeking && !isClick) return;

    const progressBarBCR = progressBarRef.current.getBoundingClientRect();
    const xPos = (event as TouchEvent).touches
      ? (event as TouchEvent).touches[0].clientX
      : (event as MouseEvent).clientX;

    let progressRatio = (xPos - progressBarBCR.left) / (progressBarBCR.right - progressBarBCR.left);
    progressRatio = Math.min(Math.max(progressRatio, 0), 1);

    const newTime = progressRatio * video.current.duration;
    video.current.currentTime = newTime;
  }, [isSeeking, video]);

  useEffect(() => {
    if (video.current && (isWindowTooSmall || shouldRotateDevice)) video.current.pause();
  }, [isWindowTooSmall, shouldRotateDevice, video]);

  useEffect(() => {
    document.addEventListener('webkitfullscreenchange', onExitFullscreen);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      clearStalledTimeout();
      document.removeEventListener('webkitfullscreenchange', onExitFullscreen);
      window.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (video.current && !isReadyRef.current) {
      const onReady = () => {
        addListeners();
        setReady(true);
      };

      const onLoad = () => {
        video.current.removeEventListener('loadedmetadata', onLoad);
        onReady();
      };

      if (!DEBUG_MP4 && Hls.isSupported()) {
        hls.current = new Hls();

        hls.current.once(Hls.Events.MEDIA_ATTACHED, () => {
          hls.current.once(Hls.Events.MANIFEST_PARSED, (event, data) => {
            if (IS_DEBUG) console.log(
              'manifest loaded, found ' + data.levels.length + ' quality level'
            );

            video.current.addEventListener('loadedmetadata', onLoad);
          });

          hls.current.loadSource(ANTHEM_VIDEO.manifestUrl);
        });

        hls.current.attachMedia(video.current);
      } else {
        video.current.setAttribute('src', ANTHEM_VIDEO.mp4Url);
        video.current.addEventListener('loadedmetadata', onLoad);
        video.current.load();
      }
    }

    // Set the stalling overlay if it takes too much time getting started
    if (video.current.autoplay) onUpdate();
  }, [addListeners, onUpdate, video]);

  useEffect(() => {
    if (isReady) {
      const totalTime = timeWrapperRef.current.lastElementChild;
      totalTime.innerHTML = getParsedTimeString(video.current.duration);
    } else if (hasListeners.current) removeListeners();

    return () => {
      if (isReady && !hasListeners.current) removeListeners();
    }
  }, [isReady, removeListeners, video]);

  useEffect(() => {
    isReadyRef.current = isReady;
  }, [isReady]);

  useEffect(() => {
    isStalledRef.current = isStalled;
  }, [isStalled]);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    isUserPausedRef.current = isUserPaused;
  }, [isUserPaused]);

  useEffect(() => {
    hasEndedRef.current = hasEnded;
  }, [hasEnded]);

  useEffect(() => {
    isSeekingRef.current = isSeeking;
  }, [isSeeking]);

  useEffect(() => {
    if (isSeeking) {
      const stopSeeking = () => {
        removeEventListeners(window, 'mouseup touchend', stopSeeking);
        removeEventListeners(window, 'mousemove touchmove', onProgressBarMove);
        setSeeking(false);

        if (!isUserPausedRef.current && video.current) video.current.play();
      };

      addEventListeners(window, 'mouseup touchend', stopSeeking);
      addEventListeners(window, 'mousemove touchmove', onProgressBarMove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeeking, video]);

  useEffect(() => {
    if (!isPageVisible && video.current && !isPausedRef.current) video.current.pause();
  }, [windowSize, isPageVisible, video]);

  return (
    <Styled.Wrapper>
      <Styled.PlayPauseWrapper>
        <PlayPause onClick={onTogglePause} state={isPaused || hasEnded || isStalled ? 'play' : 'pause'} />
      </Styled.PlayPauseWrapper>

      <Styled.ProgressBar
        ref={progressBarRef}
        onMouseDown={onStartSeeking}
        onTouchStart={onStartSeeking}
        onClick={({ nativeEvent: event }: SyntheticEvent) => onProgressBarMove(event as MouseEvent, true)}
      >
        <div />
      </Styled.ProgressBar>

      <Styled.TimeWrapper ref={timeWrapperRef}>
        <span>{getParsedTimeString(0)}</span>
        <div />
        <span>{getParsedTimeString(0)}</span>
      </Styled.TimeWrapper>

      <Styled.Fullscreen>
        <SvgFullscreen onClick={enterFullscreen} />
      </Styled.Fullscreen>
    </Styled.Wrapper>
  );
};

export default AnthemVideoOverlayControls;
