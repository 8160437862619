import styled, { css } from 'styled-components';

import { colors, layout } from 'utils/styles/theme';
import { setTypography, spinHexagonIcon } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  color: ${colors.black};
  opacity: 0;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivideLine = styled.div<{ hasExtraTopPadding?: boolean }>`
  width: 100%;
  border-top: 1px solid ${colors.alto};
  margin: 120rem 0;

  ${({ hasExtraTopPadding }) => hasExtraTopPadding ? `
    margin-top: 180rem;
  ` : ''}
`;

export const NoContent = styled.div<{ isFavorites?: boolean; isLoading?: boolean }>`
  width: 100%;
  height: ${layout.player.mobileHeight}rem;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  padding: ${layout.player.padding.centered}rem ${layout.player.padding.centered + 10}rem;

  ${({ isFavorites, isLoading }) => isFavorites && !isLoading ? `
    svg {
      color: transparent;
      stroke: ${colors.black};
      stroke-width: 4rem;
    }
  ` : ''}

  ${({ isLoading }) => isLoading ? css`
    justify-content: center;
    align-items: center;

    svg {
      width: 90rem;
      ${spinHexagonIcon(true)}
    }
  ` : ''}

  ${mediaTablet(`
    height: ${layout.player.tabletHeight}rem;
    padding: ${layout.player.padding.centered - 10}rem ${layout.player.padding.centered}rem;

    svg {
      stroke-width: 3rem;
    }
  `)}

  ${mediaDesktop(`
    height: ${layout.player.height}rem;
  `)}

`;

export const PlayerWrapper = styled.div``;

export const FavoriteList = styled.div`
  opacity: 0;

  &:not(:last-child) {
    margin-bottom: 40rem;
  }

  ${mediaTablet(`
    &:not(:last-child) {
      margin-bottom: 54rem;
    }
  `)}
`;

export const NoContentTitle = styled.div`
  ${({ theme: { locale } }) => setTypography('leadBody2', locale)}
  ${({ theme: { locale } }) => mediaDesktop(`
    ${setTypography('leadBody', locale)}
  `)}
`;

export const NoContentSubtitle = styled.div`
  display: flex;
  ${({ theme: { locale } }) => setTypography('bodySmallBook', locale)};
  height: 1em;
  margin-top: 20rem;

  svg {
    height: 120%;
    margin-right: 20rem;
    flex-shrink: 0;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 54rem;
`;
export const Title = styled.div`
  max-width: 75%;
`;

export const Subtitle = styled.div`
  opacity: 0.5;
  margin-top: 40rem;

  ${mediaTablet(`
    margin-top: 20rem;
  `)}
`;
