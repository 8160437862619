import styled from 'styled-components';
import { fullscreen } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ isVideoType?: boolean }>`
  ${fullscreen()}

  ${props => mediaTablet(`
    pointer-events: ${props.isVideoType ? 'none' : 'none'};
  `)}
`;
