import styled from 'styled-components';

import { easeInOutCirc, hover } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

// Adapted from https://codepen.io/morgun/pen/mInAk
const color = colors.white;
const duration = 0.4;
const sin = 0.866;
const size = layout.playPauseSize;
const border = size / 4;

export const Wrapper = styled.div<{ state: 'play' | 'pause' }>`
  width: ${size}rem;
  height: ${size}rem;
  font-size: 0;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;

  &, > div, &:before {
    display: inline-block;
    vertical-align: middle;
    transition: border ${duration}s, width ${duration}s, height ${duration}s, margin ${duration}s;
    transition-timing-function: ${easeInOutCirc};
    box-sizing: content-box;
  }

  &:before {
    content: '';
    height: ${size}rem;
  }

  ${({ state }) => state === 'pause' ? `
    > div {
      margin: 0;
      border-left: ${0.2 * size}rem solid ${color};
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      height: ${size * sin}rem;

      &:nth-child(1) {
        border-right: ${0.175 * size}rem solid transparent;
      }
    }
  ` : ''}

  ${({ state }) => state === 'play' ? `
    > div {
      &:nth-child(1) {
        margin-left: ${size / 6}rem;
        border-left: ${(size * sin) / 2}rem solid ${color};
        border-top: ${border}rem solid transparent;
        border-bottom: ${border}rem solid transparent;
        border-right: 0 solid transparent;
        height: ${size - (2 * border)}rem;
      }

      &:nth-child(2) {
        margin: 0;
        border-left: ${(size * sin) / 2}rem solid ${color};
        border-top: ${border}rem solid transparent;
        border-bottom: ${border}rem solid transparent;
        height: 0;
      }
    }
  ` : ''}

  ${hover(`
    &, > div {
      opacity: ${layout.hoverOpacity};
    }
  `)}
`;
