import React, { useMemo, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import ButtonClose, { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';
import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import {
  useCardScreenStore,
  useClubhouseStore,
  useCopyStore,
  useGlobalStore,
  useHotspotOverlayStore,
  usePlaylistsStore,
} from 'store';
import { HotspotTypes } from 'store/clubhouse.types';
import {
  BlackBarPositionState,
  BlackBarTransitionState,
} from 'store/global.types';
import { PlaylistTypes } from 'store/playlists.types';
import gtm from 'u9/utils/gtm';
import { isStorybook } from 'u9/utils/platform';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';

import EasterEgg, { EasterEggRef } from './EasterEgg/EasterEgg';
import Storytelling from './Storytelling/Storytelling';
import Secondary from './Secondary/Secondary';

import useMotion from './HotspotOverlay.motion';
import * as Styled from './HotspotOverlay.styles';

export interface AuthPromptOverlayProps {
  isVisible?: boolean;
}

const TRANSITION_END_DELAY = 200; // ms

const HotspotOverlay = ({ isVisible = false, hotspotType = null }) => {
  const {
    hotspots,
    setCurrentHotspot,
    isHotspotOverlayVisible,
    setHotspotOverlayVisible,
    setClubhouseHotspots,
    hasBeenOnboarded,
    onboardingStep,
    setOnboardingStep,
  } = useClubhouseStore();

  const easterEggRef = useRef<EasterEggRef>(null);
  const closeRef = useRef<ButtonCloseRef>(null);

  let { currentClubhouse } = useClubhouseStore();
  if (isStorybook()) currentClubhouse = 'seoul';

  const slideIndex = useHotspotOverlayStore(state => state.slideIndex);
  const setSlideIndex = useHotspotOverlayStore(state => state.setSlideIndex);
  const setPreviousSlideIndex = useHotspotOverlayStore(state => state.setPreviousSlideIndex);
  const setPagesCount = useHotspotOverlayStore(state => state.setPagesCount);

  const { copy } = useCopyStore();
  const { setCurrentCardScreen } = useCardScreenStore(state => state);
  const { lastUnlockedPlaylist } = usePlaylistsStore(state => state);
  const { setBlackBarTransition } = useGlobalStore(state => state);
  const playlistCardTimeout = useRef<number>(null);
  const transitionTimeout = useRef<number>(null);

  let currentHotspot = useClubhouseStore(state => state.currentHotspot);
  if (isStorybook())
    currentHotspot = {
      type: hotspotType,
      label: '',
      position: { x: 0, y: 0 },
      roomNb: 0,
    };

  const storytellingType = HotspotTypes.storytelling1.replace(/\d+/g, '');
  const isStorytelling = useMemo(
    () =>
      currentHotspot &&
      currentHotspot.type.replace(/\d+/g, '') === storytellingType,
    [currentHotspot, storytellingType]
  );

  useEffect(() => {
    return () => {
      if (onboardingStep === 0 && isHotspotOverlayVisible) {
        setOnboardingStep(1);
      }
    };
  }, [onboardingStep, setOnboardingStep, isHotspotOverlayVisible]);

  useEffect(() => {
    let pagesCount = currentHotspot ? 1 : 0;

    if (isStorytelling) {
      const storytellingIndex = currentHotspot.type.match(/\d+/)
        ? parseInt(currentHotspot.type.match(/\d+/)[0]) - 1
        : 0;
      const storytellingOverlay =
        copy.clubhouses[currentClubhouse].hotspotsOverlays.storytelling[
        storytellingIndex
        ];
      pagesCount = Object.keys(storytellingOverlay).length;
    } else if (currentHotspot?.type === HotspotTypes.pro) {
      pagesCount =
        copy.clubhouses[currentClubhouse].hotspotsOverlays.pro.length;
    }

    setPagesCount(pagesCount);
  }, [
    copy?.clubhouses,
    currentClubhouse,
    currentHotspot,
    isStorytelling,
    setPagesCount,
  ]);

  useEffect(() => {
    return () => {
      if (transitionTimeout.current) clearTimeout(transitionTimeout.current);
      if (playlistCardTimeout.current) clearTimeout(playlistCardTimeout.current);
    };
  }, []);

  const clubhouseName =
    currentClubhouse === 'seoul'
      ? 'LCK'
      : currentClubhouse === 'berlin'
        ? 'LEC'
        : 'LPL';

  const isEasterEgg = useMemo(
    () => currentHotspot && currentHotspot.type === HotspotTypes.easterEgg,
    [currentHotspot]
  );
  const isSecondary = useMemo(() => !isStorytelling && !isEasterEgg, [
    isStorytelling,
    isEasterEgg,
  ]);

  const { animateIn, animateOut } = useMotion(
    { closeRef },
    {
      inDelay: isSecondary ? 1.5 : 1,
      outDelay: 0,
    }
  );


  useEffect(() => {
    if (isVisible) {
      animateIn();
    }

    // eslint-disable-next-line
  }, [isVisible]);


  if (!currentHotspot && !isStorybook()) return null;

  const onCloseButtonClick = () => {
    const proceed = () => {
      setHotspotOverlayVisible(false);
      handleCloseAnalytics();
    };

    animateOut();

    if (isEasterEgg && easterEggRef.current) {
      easterEggRef.current.animateOut(proceed);
    } else {
      setBlackBarTransition(
        BlackBarTransitionState.In,
        BlackBarPositionState.Edge,
        BlackBarPositionState.Cover,
        proceed
      );
    }
  };

  const handleCloseAnalytics = () => {
    switch (currentHotspot.type) {
      case 'pro':
        gtm.trackEvent(
          `${clubhouseName} Featured Pros - overlay`,
          'Click - close',
          `${clubhouseName} Featured Pros overlay - close`
        );
        break;
      case 'champion':
        gtm.trackEvent(
          `${clubhouseName} Featured Champion - overlay`,
          'Click - close',
          `${clubhouseName} Featured Champion - close`
        );
        break;
      case 'easterEgg':
        gtm.trackEvent(
          `${clubhouseName} Easter egg - overlay`,
          'Click - close',
          `${clubhouseName} Easter egg - close`
        );
        break;
      case 'skill':
        hasBeenOnboarded
          ? gtm.trackEvent(
            `${clubhouseName} Featured Skill Hotspot - overlay`,
            'Click - close',
            `${clubhouseName} Featured Skill Hotspot - close`
          )
          : gtm.trackEvent(
            `${clubhouseName} Storytelling : Act 1 (FT user)`,
            'Click - close',
            `${clubhouseName} Storytelling Act 1 (FT User)- close`
          );
        break;
      case 'storytelling1':
        hasBeenOnboarded
          ? gtm.trackEvent(
            `${clubhouseName} Storytelling Act 1 - overlay`,
            'Click - close',
            `${clubhouseName} Storytelling Act 1 - close`
          )
          : gtm.trackEvent(
            `${clubhouseName} Storytelling : Act 1 (FT user)`,
            'Click - close',
            `${clubhouseName} Storytelling Act 1 (FT User)- close`
          );
        break;
      case 'storytelling2':
        gtm.trackEvent(
          `${clubhouseName} Storytelling Act 2 - overlay`,
          'Click - close',
          `${clubhouseName} Storytelling Act 2 - close`
        );
        break;
      case 'storytelling3':
        gtm.trackEvent(
          `${clubhouseName} Storytelling Act 3 - overlay`,
          'Click - close',
          `${clubhouseName} Storytelling Act 3 - close`
        );
        break;
    }
  };

  const updateHotspotStates = () => {
    if (currentHotspot.hasBeenSeen) return;

    const city = currentClubhouse as ClubhousePageProps['city'];
    const storytellingHotspots = [...hotspots[city].storytelling];
    const storytellingIndex = currentHotspot.type.match(/\d+/)
      ? parseInt(currentHotspot.type.match(/\d+/)[0]) - 1
      : 0;
    storytellingHotspots[storytellingIndex].hasBeenSeen = true;

    setClubhouseHotspots(
      city,
      isStorytelling
        ? {
          storytelling: storytellingHotspots,
        }
        : {
          [currentHotspot.type]: {
            ...hotspots[city][currentHotspot.type],
            hasBeenSeen: true,
          },
        }
    );
  };

  const checkUnlockables = () => {
    if (lastUnlockedPlaylist === PlaylistTypes.personalized) {
      setBlackBarTransition(
        BlackBarTransitionState.In,
        BlackBarPositionState.Edge,
        BlackBarPositionState.Cover,
        () => {
          if (playlistCardTimeout.current) window.clearTimeout(playlistCardTimeout.current);
          playlistCardTimeout.current = window.setTimeout(() => {
            playlistCardTimeout.current = null;
            setCurrentCardScreen('playlist');
            AudioManager.play(SOUNDS_DATA.musicDiscovered);
          }, TRANSITION_END_DELAY);

          setBlackBarTransition(
            BlackBarTransitionState.Out,
            BlackBarPositionState.Cover,
            BlackBarPositionState.Center
          );
        }
      );
    }
  };

  const onClosed = () => {
    updateHotspotStates();
    setCurrentHotspot(null);
    setSlideIndex(0);
    setPreviousSlideIndex(0);

    if (transitionTimeout.current) window.clearTimeout(transitionTimeout.current);
    transitionTimeout.current = window.setTimeout(() => {
      transitionTimeout.current = null;
      !isEasterEgg && setBlackBarTransition(
        BlackBarTransitionState.Out,
        BlackBarPositionState.Cover,
        BlackBarPositionState.Edge, () => {
          if (playlistCardTimeout.current) window.clearTimeout(playlistCardTimeout.current);
          playlistCardTimeout.current = window.setTimeout(() => {
            playlistCardTimeout.current = null;
            checkUnlockables();
          }, 1000);
        }
      );
    }, TRANSITION_END_DELAY);
  };

  return (
    <AnimatePresence onExitComplete={onClosed}>
      {isVisible && (
        <Styled.Wrapper>
          {isStorytelling && <Storytelling />}
          {isSecondary && <Secondary type={currentHotspot.type} />}
          {isEasterEgg && <EasterEgg ref={easterEggRef} />}
          <Styled.ButtonCloseWrapper
            isStorytellingFirstPage={isStorytelling && !slideIndex}
            isSecondary={isSecondary}
          >
            <ButtonClose
              ref={closeRef}
              onClick={onCloseButtonClick}
              color={
                isEasterEgg || (isStorytelling && isMobileLayout())
                  ? ColorNames.white
                  : ColorNames.roti
              }
              wrapped={true}
              autoAnimate={false}
            />
          </Styled.ButtonCloseWrapper>
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default HotspotOverlay;
