import React from 'react';

import { useAudioStore } from 'store';
import useAnimation from './AudioToggle.animation';
import * as Styled from './AudioToggle.styles';

const AudioToggle = () => {
  const { toggleMuted } = useAudioStore();
  const { bars, getAnimationProps } = useAnimation();

  return (
    <Styled.Wrapper onClick={toggleMuted}>
      {[
        bars.current.map((bar, index) => (
          <Styled.Bar key={`AudioBar-${index}`} {...getAnimationProps(index)} />
        )),
      ]}
    </Styled.Wrapper>
  );
};

export default AudioToggle;
