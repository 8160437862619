import styled from 'styled-components';

import { layout, colors } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { setVh, setTypography, spotifyPlayerWrapper, fullscreen } from 'utils/styles/mixins';

export const Wrapper = styled.div<{ extraPaddingBottom?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: unset;
  right: unset;
  width: 100vw;
  height: ${setVh(100)};
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  padding-top: 100rem;
  padding-bottom: ${(props) => props.extraPaddingBottom ? '160rem' : '80rem'};
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;

  ${mediaTablet(`
    bottom: 0;
    right: 0;
    flex-direction: row;
    height: ${setVh(100)};
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  `)}
`;

export const OverlayTitleWrapper = styled.div`
  position: fixed;
  left: 310rem;
  top: ${layout.headerFooterPadding.desktop}rem;
  width: 50vw;
  padding-right: 260rem;
  pointer-events: none;
  z-index: 10;
`;

export const BreadcrumbWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding-right: 0;
  pointer-events: none;
  z-index: 10;

  ${mediaTablet(`
    left: 310rem;
    bottom: ${layout.headerFooterPadding.desktop}rem;
    width: 50vw;
    padding-right: 260rem;
  `)}
`;

export const ContentWrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: ${layout.headerFooterPadding.mobile}rem;
  padding-top: ${layout.secondaryHotspotOverlay.contentWrapperPadding.mobile.top}rem;
  padding-bottom: 220rem;
  pointer-events: auto;
  position: absolute;
  top: 0;
  left: 0;

  ${mediaTablet(`
    position: relative;
    width: 55vw;
    padding: ${layout.headerFooterPadding.tablet}rem 80rem 220rem ${80 + layout.ribbon.width.desktop}rem;
    top: auto;
    left: auto;
  `)}

  ${mediaDesktop(`
    padding: ${layout.headerFooterPadding.desktop}rem 200rem 220rem ${200 + layout.ribbon.width.desktop}rem;
    overflow-y: hidden;
  `)}
`;

export const MediaWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 1200rem;
  margin-left: -${layout.headerFooterPadding.mobile}rem;

  ${mediaTablet(`
    width: 45vw;
    height: ${setVh(100)};
    margin-left: 0;
  `)}
`;

export const MediaWrapperBackground = styled.div<{ background: string }>`
  ${fullscreen()}
  background-image: url('${(props) => props.background}');
  background-size: cover;
  background-position: center top;
  clip-path: inset(0% 0% 0% 100%);
`;

export const Subtitle = styled.h4<{ extraMarginTop?: boolean }>`
  color: ${colors.blueRibbon};
  margin-top: ${layout.headerFooterPadding.mobile}rem;
  margin-bottom: ${layout.hotspotOverlaySubtitle.marginBottom.mobile}rem;

  ${mediaTablet(`
    margin-top: ${setVh(layout.hotspotOverlaySubtitle.marginTop.desktop)};
    margin-bottom: ${setVh(layout.hotspotOverlaySubtitle.marginBottom.desktop)};
  `)}

  ${(props) => props.extraMarginTop ?
    `
      ${mediaTablet(`
        margin-top: ${setVh(layout.hotspotOverlaySubtitle.marginTop.desktopExtra)};
        margin-bottom: ${setVh(layout.hotspotOverlaySubtitle.marginBottom.desktop)};
      `)}
    ` :
    `
      ${mediaTablet(`
        margin-top: ${setVh(layout.hotspotOverlaySubtitle.marginTop.desktop)};
        margin-bottom: ${setVh(layout.hotspotOverlaySubtitle.marginBottom.desktop)};
      `)}
    `
  }
`;

export const Body = styled.div`
  padding-bottom: ${layout.headerFooterPadding.mobile}rem;

  ${mediaTablet(`
    padding-bottom: ${setVh(3)};
  `)}
`;

export const SectionLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('subtitle2', locale)};
  margin-bottom: 20rem;
`;

/**
 * Tagline
 */
export const Tagline = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 60rem;

  ${mediaTablet(`
    padding-bottom: 50rem;
  `)}

  ${mediaDesktop(`
    padding-bottom: 80rem;
  `)}
`;

export const TaglineBody = styled.div`
  padding-bottom: 60rem;

  ${mediaTablet(`
    padding-bottom: 50rem;
  `)}

  ${mediaDesktop(`
    padding-bottom: 80rem;
  `)}
`;

/**
 * Trivia
 */
export const Trivia = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80rem;
  padding-bottom: 60rem;

  ${mediaTablet(`
    padding-top: 0;
  `)}

  ${mediaDesktop(`
    padding-bottom: 80rem;
  `)}
`;

export const TriviaBody = styled.div`
  padding-bottom: 60rem;

  ${mediaTablet(`
    padding-bottom: 50rem;
  `)}

  ${mediaDesktop(`
    padding-bottom: 80rem;
  `)}
`;

/**
 * Divider
 */
export const Divider = styled.div<{ isVisible?: boolean, extraMarginTop?: number }>`
  display: ${(props) => props.isVisible ? 'block' : 'none'};
  width: 100%;
  height: 2rem;
  background-color: ${colors.alto};
  flex-shrink: 0;
  transform-origin: top left;
  ${props => props.extraMarginTop ? `margin-top: ${props.extraMarginTop}rem;` : ''}
`;
Divider.defaultProps = {
  isVisible: true
}

/**
 * Pro Content
 */
export const ProContentWrapper = styled.div`
  flex-shrink: 0;

  ${mediaTablet(`
    margin-top: 30rem;
  `)}
`;

export const ProContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaTablet(`
    flex-direction: row;
  `)}
`;

export const ProContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${layout.headerFooterPadding.mobile}rem;

  ${mediaTablet(`
    width: 65%;
    margin-top: 0;

    &:first-child {
      padding-right: 40rem;
    }

    &:last-child {
      width: 35%;
      margin-top: 0;
    }
  `)}
`;

export const ProContentProfile = styled.div`
  display: grid;
  width: 100%;
  padding-bottom: 40rem;

  ${mediaTablet(`
    padding-bottom: 0;
    grid-template-columns: 55% 45%;
    grid-template-rows: 55% 45%;
    grid-gap: 4rem;
  `)}
`;

export const ProContentProfileText = styled.p`
  ${({ theme: { locale } }) => setTypography('body', locale)};
  color: ${colors.gray};

  ${({ theme: { locale } }) => mediaTablet(`
    ${setTypography('bodySmallBook', locale)}
  `)}
`;

export const ProContentChamps = styled.ul`
  display: flex;
  list-style-type: none;
  padding-bottom: 40rem;

  ${mediaTablet(`
    padding-bottom: 0;
  `)}
`;

export const ProContentChamp = styled.li<{ background?: string }>`
  display: flex;
  flex-direction: column;
  width: 120rem;
  height: 120rem;
  margin-right: 20rem;
  background-image: url('${(props) => props.background}');
  background-size: cover;
  background-position: center;

  ${mediaTablet(`
    width: 50rem;
    height: 50rem;
  `)}

  ${mediaDesktop(`
    width: 80rem;
    height: 80rem;
  `)}
`;

/**
 * Spotify Track
 */
export const SpotifyTrackWrapper = styled.div<{ hasPaddingTop: boolean; hasExtraPaddingBottom?: boolean; }>`
  ${({ hasPaddingTop }) => hasPaddingTop ? 'padding-top: 80rem;' : ''}
  padding-bottom: ${(props) => props.hasExtraPaddingBottom ? '100rem' : 0};

  ${({ hasPaddingTop }) => mediaTablet(`
    ${hasPaddingTop ? `padding-top: ${setVh(3)};` : ''}
    width: 85%;
  `)}

  ${mediaDesktop(`
    width: 80%;
  `)}
`;

export const SpotifyTrack = styled.div<{ hasExtraPaddingBottom?: boolean }>``;

export const SpotifyTrackPlayer = styled.div`
  ${spotifyPlayerWrapper(0)}
`;

/**
 * Media Content Actions
 */
export const MediaContentActions = styled.div`
  position: absolute;
  top: 80rem;
  left: ${layout.headerFooterPadding.mobile}rem;
  display: flex;
  align-items: center;

  ${mediaTablet(`
    top: ${layout.headerFooterPadding.tablet}rem;
    left: ${layout.headerFooterPadding.tablet}rem;
    transform: translateY(-25%);
  `)}

  ${mediaDesktop(`
    top: ${layout.headerFooterPadding.desktop}rem;
    left: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;

/**
 * Downloadable
 */
export const Downloadable = styled.a``;

/**
 * Full profile
 */
export const FullProfile = styled.a`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin-top: 50rem;

  svg {
    height: 50rem;
    color: ${colors.roti};
  }

  svg path:nth-child(2) {
    stroke: ${colors.black};
  }

  ${mediaTablet(`
    margin-top: 0;
    svg path:nth-child(2) {
      stroke: ${colors.roti};
    }
  `)}
`;

export const FullProfileLabel = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle2', locale)};
  color: ${colors.black};
  margin-right: 20rem;

  ${mediaTablet(`
    color: ${colors.roti};
  `)}
`;

export const FullProfileMobileWrapper = styled.div`
  margin-top: 80rem;
`;
