import styled from 'styled-components';

import { layout } from 'utils/styles/theme';
import { hover, setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';

interface TextProps {
  opacity?: number;
}

export const Section = styled.div`
  display: flex;
  pointer-events: auto;
  align-items: center;
`;

export const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${layout.footerElementMargin.tablet}rem;

  &:nth-child(1) {
    display: flex;
    align-items: center;
    height: ${layout.audioToggleHeight.desktop}rem;
  }

  ${mediaDesktop(`
    margin-right: ${layout.footerElementMargin.desktop}rem;
  `)}
`;

export const Text = styled.p`
  ${({ theme: { locale } }) => setTypography('footer', locale)}
  opacity: ${(props: TextProps) => props.opacity};

  ${hover(`
    a {
      opacity: ${layout.hoverOpacity};
    }
  `)}
`;

export const LinkList = styled.a`
  ${({ theme: { locale } }) => setTypography('footer', locale, true)}
  text-align: center;

  &:not(:last-child) {
    margin-right: 25rem;
  }

  ${hover(`
    opacity: ${layout.hoverOpacity};
  `)}
`
export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${layout.headerFooterPadding.desktop}rem;
  z-index: ${layout.zIndex.footer};
  height: ${setVh(layout.headerFooterHeight.desktop)};
  pointer-events: none;

  ${ChildWrapper} {
    &:last-child {
      margin-right: 0;
    }
  }
`;
