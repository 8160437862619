import styled from 'styled-components';
import { setVh, spotifyPlayerWrapper } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export interface BackingImageProps {
  image: string;
  top: string;
  left: string;
}

export const Wrapper = styled.div`
  display: flex;

  .SplitText__wrapper, .SplitText__line {
    margin: 0 auto;
    text-align: center !important;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${setVh(100)};
  background-color: ${colors.roti};
  clip-path: inset(0% 0% 100% 0%);
`;

export const ContentWrapperBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.roti};
  opacity: 0.7;
  z-index: 2;
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 3;

  ${mediaTablet(`
    top: 0;
    left: -100%;
  `)}
`;

export const BackingImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const BackingImage = styled.div<BackingImageProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: 800rem;
  height: 900rem;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
`;

export const ContentInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 200rem;
  padding-bottom: 300rem;
  overflow-x: hidden;
  overflow-y: auto;

  ${mediaTablet(`
    overflow: unset;
    padding-top: 110rem;
    padding-bottom: 200rem;
    width: 55%;
    transform: translateX(${layout.ribbon.width.desktop / 2}rem);
  `)}
`;

export const Subtitle = styled.div`
  text-align: center;
  margin-top: 60rem;

  ${mediaTablet(`
    margin-top: 0;
  `)}
`;

export const Body = styled.div`
  width: 90%;
  margin-top: 40rem;
  margin-bottom: 40rem;
  padding: 0 20rem;

  ${mediaTablet(`
    width: 75%;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  `)}
`;

export const Teaser = styled.div`
  width: 80%;
  margin-bottom: 40rem;

  ${mediaTablet(`
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  `)}
`;

export const MusicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${layout.headerFooterPadding.mobile}rem;

  ${mediaTablet(`
    width: 75%;
    padding: 0;
  `)}

  ${mediaDesktop(`
    width: 50%;
  `)}
`;

export const MusicIntro = styled.div`
  margin-bottom: 40rem;
`;

export const SpotifyPlaceholder = styled.div`
  ${spotifyPlayerWrapper(0, 1000)}
  width: 700rem;

  ${mediaTablet(`
    width: 850rem;
  `)}
`;
