import React, { useRef } from 'react';

import Ribbon from 'components/Ribbon/Ribbon';
import OverlayTitle, { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import Breadcrumbs, { BreadcrumbsRef } from 'components/Breadcrumbs/Breadcrumbs';
import InteractiveMediaMessage, { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';
import useScrollLockGesture from 'components/ScrollLockContainer/ScrollLockContainer.gesture';
import { ColorNames, layout } from 'utils/styles/theme';
import { HotspotOverlayTypes } from 'store/clubhouse.types';
import StorytellingContent, { StorytellingContentRef } from '../StorytellingContent/StorytellingContent';
import { Track } from 'store/playlists.types';
import { useClubhouseStore, useHotspotOverlayStore } from 'store';
import useMotion from './StorytellingLayout.motion';
import * as Styled from './StorytellingLayout.styles';
import { isMobileLayout } from 'utils/styles/responsive';
import { isStorybook } from 'u9/utils/platform';

export interface StorytellingLayoutProps {
  stories: Record<string, any>[];
  tracks: Track[];
  entryImage: string;
  interactiveThumbnails: string[];
  interactiveVideos: string[];
  ribbon: string;
  title: string;
  breadcrumbs: string[];
}

const defaultProps: Partial<StorytellingLayoutProps> = {
  stories: [],
  tracks: [],
  entryImage: '',
  interactiveThumbnails: [],
  interactiveVideos: [],
  ribbon: '',
  title: '',
  breadcrumbs: []
};

const StorytellingLayout: React.FC<StorytellingLayoutProps> = ({
  stories,
  tracks,
  entryImage,
  interactiveThumbnails,
  interactiveVideos,
  ribbon,
  title,
  breadcrumbs
}: StorytellingLayoutProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const overlayTitleRef = useRef<OverlayTitleRef>(null);
  const breadcrumbsRef = useRef<BreadcrumbsRef>(null);
  const breadcrumbsWrapperRef = useRef<HTMLDivElement>(null);
  const entryImageRef = useRef<HTMLDivElement>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const ribbonWrapperRef = useRef<HTMLDivElement>(null);
  const interactiveMediaMessageRef = useRef<InteractiveMediaMessageRef>(null);
  const contentRefs = useRef<StorytellingContentRef[]>([]);

  let { currentClubhouse } = useClubhouseStore();
  if (isStorybook()) currentClubhouse = 'seoul';
  const { slideIndex, setSlideIndex, previousSlideIndex, setPreviousSlideIndex } = useHotspotOverlayStore();

  useScrollLockGesture({
    targetRef: wrapperRef,
    slidesLength: stories.length
  });

  const betweenFirstAndSecondSlides = (previousSlideIndex === 0 && slideIndex === 1) || (previousSlideIndex === 1 && slideIndex === 0)

  useMotion(
    {
      overlayTitleRef,
      breadcrumbsRef,
      breadcrumbsWrapperRef,
      entryImageRef,
      mainContentRef,
      ribbonWrapperRef,
      contentRefs,
      interactiveMediaMessageRef
    }
  );

  const storyComponents = stories.map((story, index) => {
    const isFirstSlide = index === 0;
    return (
      <StorytellingContent
        key={`StorytellingContent-${index}`}
        ref={(ref: StorytellingContentRef) => contentRefs.current[index] = ref}
        story={story}
        track={tracks[`page${index + 1}`] || null}
        storyIndex={index}
        slideIndex={slideIndex}
        interactiveThumbnail={index > 0 ? interactiveThumbnails[index - 1] : null}
        interactiveVideo={index > 0 ? interactiveVideos[index - 1] : null}
        interactiveVideoUseClick={index === stories.length - 1}
        useSlideMotion={isFirstSlide || betweenFirstAndSecondSlides}
      />
    );
  });

  const breadcrumbHandlers = isMobileLayout() ?
    {
      onPrev: () => {
        setPreviousSlideIndex(slideIndex);
        slideIndex > 0 && setSlideIndex(slideIndex - 1);
      },
      onNext: () => {
        setPreviousSlideIndex(slideIndex);
        slideIndex < stories.length - 1 && setSlideIndex(slideIndex + 1);
      }
    } : {};

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.EntryImage
        ref={entryImageRef}
        image={entryImage}
      />
      <Styled.MainContent ref={mainContentRef}>
        <Styled.OverlayTitleWrapper>
          <OverlayTitle
            ref={overlayTitleRef}
            hasWorldsIcon={false}
            overlayType={HotspotOverlayTypes.Storytelling}
            title={title}
            color={ColorNames.white}
          />
        </Styled.OverlayTitleWrapper>
        <Styled.BreadcrumbWrapper ref={breadcrumbsWrapperRef}>
          <Breadcrumbs
            ref={breadcrumbsRef}
            breadcrumbIndex={slideIndex}
            breadcrumbs={breadcrumbs}
            {...breadcrumbHandlers}
          />
        </Styled.BreadcrumbWrapper>
        {storyComponents}
        <Styled.RibbonWrapper ref={ribbonWrapperRef}>
          <Ribbon
            copy={ribbon}
            alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
            icon={currentClubhouse}
            iconSize={layout.iconSizes[currentClubhouse].ribbon}
          />
        </Styled.RibbonWrapper>
        {
          !isMobileLayout() &&
          <Styled.InteractiveMediaMessageWrapper>
            <InteractiveMediaMessage
              ref={interactiveMediaMessageRef}
              useClick={slideIndex === stories.length - 1}
            />
          </Styled.InteractiveMediaMessageWrapper>
        }
      </Styled.MainContent>
    </Styled.Wrapper>
  );
};

StorytellingLayout.defaultProps = defaultProps;

export default StorytellingLayout;
