import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  MutableRefObject,
} from 'react';

import UserImagePlaceholder from 'assets/images/user-image-placeholder.png';
import ImagePlaylistCover from 'assets/images/playlistCover.jpg';
import Ribbon from 'components/Ribbon/Ribbon';
import Button, { ButtonRef } from 'components/Button/Button';
import OverlayTitle, { OverlayTitleRef } from 'components/overlays/OverlayTitle/OverlayTitle';
import ButtonClose, { ButtonCloseRef } from 'components/ButtonClose/ButtonClose';
import Tooltip from 'components/Tooltip/Tooltip';
import SplitContent, { SplitContentRef, SplitContentFontScale, SplitContentMotionType } from 'components/SplitContent/SplitContent';
import Playlists, { PlaylistsRef } from 'components/Playlists/Playlists';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import {
  useCopyStore,
  useGlobalStore,
  usePlaylistsStore,
  useClubhouseStore,
} from 'store';
import { PlaylistData, PlaylistTypes, Track } from 'store/playlists.types';
import { isStorybook, isSafari } from 'u9/utils/platform';
import {
  createPlaylist,
  findUserPlaylistWithId,
  getFavoritePlaylistData,
  getFavoritePlaylistName,
  setPlaylistCover,
  setPlaylistItems,
} from 'utils/api';
import { copyToClipboard } from 'utils/clipboard';
import { ANTHEM_TRACK } from 'utils/config.assets';
import { toDataURL } from 'utils/url';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';
import gtm from 'u9/utils/gtm';

import useMotion from './PlaylistsOverlay.motion';
import * as Styled from './PlaylistsOverlay.styles';

export const validButtons = ['none', 'save', 'update'] as const;

export interface PlaylistsOverlayProps {
  buttonStatus?: typeof validButtons[number];
  isVisible: boolean;
}

const defaultProps: Partial<PlaylistsOverlayProps> = {};

const PlaylistsOverlay = ({
  buttonStatus,
  isVisible,
}: PlaylistsOverlayProps) => {
  const [hasPlaylist, setPlaylist] = useState<boolean>(false);
  const [isTooltipUpdateVisible, setTooltipUpdateVisible] = useState<boolean>(
    false
  );
  const [isTooltipShareVisible, setTooltipShareVisible] = useState<boolean>(
    false
  );
  const {
    anthemTrack,
    setFavoritedTracks,
    favoritedTracks,
    togglePlaylistsOverlayVisible,
    isPlaylistsOverlayVisible,
    lastUnlockedPlaylist,
    setLastUnlockedPlaylist,
  } = usePlaylistsStore(state => state);
  const { user, setUser, toggleAuthPromptVisible } = useGlobalStore(
    state => state
  );
  const {
    onboardingStep,
    setOnboardingStep,
    currentClubhouse,
    hasBeenOnboarded,
  } = useClubhouseStore(state => state);
  const clubhouseName =
    currentClubhouse === 'seoul'
      ? 'LCK'
      : currentClubhouse === 'berlin'
        ? 'LEC'
        : 'LPL';

  const buttonsRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<ButtonCloseRef>(null);
  const tooltipUpdateRef = useRef<HTMLDivElement>(null);
  const tooltipShareRef = useRef<HTMLDivElement>(null);
  const tooltipUpdateTimeout = useRef<number>(null);
  const tooltipShareTimeout = useRef<number>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentLeftRef = useRef<HTMLDivElement>(null);
  const overlayTitleRef = useRef<OverlayTitleRef>(null);
  const headingRef = useRef<SplitContentRef>(null);
  const bodyRef = useRef<SplitContentRef>(null);
  const playlistsRef = useRef<PlaylistsRef>(null);
  const actionButtonRefs = useRef<ButtonRef[]>([]);

  const { animateIn, animateOut } = useMotion({
    closeRef,
    wrapperRef,
    contentLeftRef,
    overlayTitleRef,
    headingRef,
    bodyRef,
    playlistsRef,
    actionButtonRefs
  });

  useEffect(() => {
    if (isVisible) {
      if (lastUnlockedPlaylist === PlaylistTypes.personalized) {
        animateIn(false);
      } else {
        animateIn();
      }
    }

    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    if (
      (isStorybook() && buttonStatus === 'update') ||
      user?.favoritePlaylistData?.id
    ) {
      setPlaylist(true);
    } else if (
      (isStorybook() && buttonStatus === 'save') ||
      !user?.favoritePlaylistData?.id
    ) {
      setPlaylist(false);
    }
  }, [user, buttonStatus]);

  const userImage = useMemo(() => {
    if (user?.image) {
      return user.image;
    } else if (!isStorybook() || buttonStatus === 'none') {
      return;
    } else if (isStorybook()) {
      return UserImagePlaceholder;
    }
  }, [user, buttonStatus]);

  const { copy } = useCopyStore(state => state);

  useEffect(() => {
    if (isTooltipShareVisible)
      hideTooltip(tooltipShareTimeout, setTooltipShareVisible);
    if (isTooltipUpdateVisible)
      hideTooltip(tooltipUpdateTimeout, setTooltipUpdateVisible);

    return () => {
      if (tooltipUpdateTimeout.current) {
        window.clearTimeout(tooltipUpdateTimeout.current);
        tooltipUpdateTimeout.current = null;
      }

      if (tooltipShareTimeout.current) {
        window.clearTimeout(tooltipShareTimeout.current);
        tooltipShareTimeout.current = null;
      }
    };
  }, [isTooltipUpdateVisible, isTooltipShareVisible]);

  useEffect(() => {
    if (isVisible && lastUnlockedPlaylist !== '') {
      AudioManager.play(SOUNDS_DATA.tracksAdded);
      setLastUnlockedPlaylist('');
    }
  }, [isVisible, lastUnlockedPlaylist, setLastUnlockedPlaylist]);

  if (!copy.experience) return null;

  const overlay = copy.experience.personalizedPlaylist.overlay;
  const card = copy.experience.personalizedPlaylist.card;

  const updateLabel = overlay.logged.cta1_playlist;
  const saveLabel = overlay.logged.cta1_noPlaylist;

  const savePlaylist = async (
    event?: SyntheticEvent,
    savedFavoritedTracks: Track[] = null,
    isSilent = false
  ): Promise<PlaylistData> => {
    const {
      favoritePlaylistTitle,
      favoritePlaylistDescription,
    } = copy.experience.personalizedPlaylist.overlay.logged;
    const playlistName = getFavoritePlaylistName(
      favoritePlaylistTitle,
      user.name
    );

    if (!savedFavoritedTracks) {
      // We check whether the playlist might've already been created in the meantime
      const existingPlaylist = await getFavoritePlaylistData(
        playlistName,
        ANTHEM_TRACK.id
      );
      if (existingPlaylist) return updatePlaylist(null, existingPlaylist, isSilent);
    }

    // If not, we create it
    const { data: apiPlaylist } = await createPlaylist(
      user.id,
      playlistName,
      favoritePlaylistDescription
    );

    const updatedFavoritedTracks = setFavoritedTracks([
      anthemTrack,
      ...(savedFavoritedTracks ? savedFavoritedTracks : favoritedTracks),
    ]);
    const createdPlaylist: PlaylistData = {
      id: apiPlaylist.id,
      url: apiPlaylist.external_urls.spotify,
      tracks: updatedFavoritedTracks,
    };

    const coverBase64 = (await toDataURL(ImagePlaylistCover)).replace(
      /(.*)base64,/,
      ''
    );
    await setPlaylistCover(createdPlaylist.id, coverBase64);
    return updatePlaylist(null, createdPlaylist, isSilent);
  };

  const updatePlaylist = async (
    event?: SyntheticEvent,
    createdPlaylist?: PlaylistData,
    isSilent = false
  ): Promise<PlaylistData> => {
    const updatedPlaylist: PlaylistData = createdPlaylist || {
      id: user.favoritePlaylistData.id,
      url: user.favoritePlaylistData.url,
      tracks: favoritedTracks,
    };

    // We check the playlist still exists, and if not, we create it first, reusing the user's favourited tracks
    const existingPlaylist = await findUserPlaylistWithId(updatedPlaylist.id);
    if (!existingPlaylist) return savePlaylist(null, favoritedTracks, isSilent);

    await setPlaylistItems(
      updatedPlaylist.id,
      updatedPlaylist.tracks.map(track => `spotify:track:${track.id}`)
    );
    setUser({ favoritePlaylistData: updatedPlaylist });

    if (!isSilent) showTooltip('update');
    return updatedPlaylist;
  };

  const showTooltip = (tooltip: 'update' | 'share') => {
    const tooltipRef =
      tooltip === 'update' ? tooltipUpdateRef.current : tooltipShareRef.current;
    const setTooltipVisible =
      tooltip === 'update' ? setTooltipUpdateVisible : setTooltipShareVisible;

    const buttonBCR = buttonsRef.current.children[
      tooltip === 'update' ? 0 : 1
    ].getBoundingClientRect();
    const tooltipBCR = tooltipRef.getBoundingClientRect();
    tooltipRef.style.left = `${buttonBCR.left +
      (isMobileLayout() ? 0 : (buttonBCR.width + tooltipBCR.width) / 2)
      }px`;
    tooltipRef.style.top = `${buttonBCR.top + (isMobileLayout() ? buttonBCR.height : 0)
      }px`;
    setTooltipVisible(true);
  };

  const hideTooltip = (
    tooltipTimeout: MutableRefObject<number>,
    setTooltipVisible: (state: boolean) => void
  ) => {
    if (tooltipTimeout.current) window.clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = window.setTimeout(() => {
      tooltipTimeout.current = null;
      setTooltipVisible(false);
    }, 2000);
  };

  const sharePlaylist = async () => {
    if (isSafari()) {
      copyToClipboard(user.favoritePlaylistData.url);
    } else {
      const playlist = await (hasPlaylist
        ? updatePlaylist(null, null, true)
        : savePlaylist(null, null, true));
      copyToClipboard(playlist.url);
    }
    showTooltip('share');
  };

  const handleClose = () => {
    animateOut(() => {
      hasBeenOnboarded
        ? gtm.trackEvent(
          `${clubhouseName} Playlist`,
          'Click - exit',
          `${clubhouseName} Playlist - Exit`
        )
        : gtm.trackEvent(
          `${clubhouseName} Playlist (FT User)`,
          'Click - exit',
          `${clubhouseName} Playlist (FT User) - Exit`
        );
      togglePlaylistsOverlayVisible();
      if (onboardingStep === 1 && isPlaylistsOverlayVisible) {
        setOnboardingStep(2);
      }
    });
  };

  const handleLogButton = () => {
    hasBeenOnboarded
      ? gtm.trackEvent(
        `${clubhouseName} Playlist`,
        'Click - to Spotify playlist',
        `${clubhouseName} Playlist - first playlist`
      )
      : gtm.trackEvent(
        `${clubhouseName} Playlist (FT User)`,
        'Click - to Spotify playlist',
        `${clubhouseName} Playlist (FT User) - first playlist`
      );
    toggleAuthPromptVisible();
  };

  return (
    isVisible && (
      <>
        <Styled.Wrapper ref={wrapperRef}>
          <Styled.RibbonWrapper>
            <Ribbon
              copy={card.ribbon_moving}
              alignment={isMobileLayout() ? 'horizontal' : 'vertical'}
            />
          </Styled.RibbonWrapper>
          <Styled.ContentLeft ref={contentLeftRef}>
            <OverlayTitle
              ref={overlayTitleRef}
              title={overlay.header}
              hasWorldsIcon={false}
              color={ColorNames.white}
              spotifyProfile={userImage}
            />
            <Styled.ContentInner>
              <Styled.HeadingWrapper>
                <SplitContent
                  ref={headingRef}
                  copy={overlay.title}
                  fontScale={SplitContentFontScale.Heading4}
                  motionType={SplitContentMotionType.SlidingLines}
                />
              </Styled.HeadingWrapper>
              <Styled.BodyWrapper>
                <SplitContent
                  ref={bodyRef}
                  copy={overlay.description}
                  fontScale={isMobileLayout() ? SplitContentFontScale.LeadBody : SplitContentFontScale.Body}
                  motionType={SplitContentMotionType.SlidingGroup}
                />
              </Styled.BodyWrapper>
            </Styled.ContentInner>
            {(user || (buttonStatus && buttonStatus !== 'none')) && (
              <Styled.ButtonWrapper ref={buttonsRef}>
                <Button
                  ref={ref => actionButtonRefs.current[0] = ref}
                  label={hasPlaylist ? updateLabel : saveLabel}
                  color={ColorNames.black}
                  icon={hasPlaylist ? 'update' : 'save'}
                  iconColor={ColorNames.white}
                  isBig={true}
                  onClick={hasPlaylist ? updatePlaylist : savePlaylist}
                  showFrom="right"
                />
                <Button
                  ref={ref => actionButtonRefs.current[1] = ref}
                  label={overlay.logged.cta2}
                  color={ColorNames.black}
                  icon={'share'}
                  iconColor={ColorNames.white}
                  isBig={true}
                  onClick={sharePlaylist}
                  isDisabled={isSafari() && !hasPlaylist}
                  showFrom="right"
                />
              </Styled.ButtonWrapper>
            )}
            {((!user && !isStorybook()) || buttonStatus === 'none') && (
              <Styled.ButtonWrapper>
                <Button
                  ref={ref => actionButtonRefs.current[2] = ref}
                  label={overlay.notLogged.cta}
                  color={ColorNames.black}
                  isBig={true}
                  onClick={handleLogButton}
                  showFrom="right"
                />
              </Styled.ButtonWrapper>
            )}

            <Styled.TooltipWrapper ref={tooltipUpdateRef}>
              <Tooltip
                label={overlay.logged.updateConfirm}
                isVisible={isTooltipUpdateVisible}
                xPosition={isMobileLayout() ? 'left' : 'center'}
                yPosition={isMobileLayout() ? 'top' : 'bottom'}
              />
            </Styled.TooltipWrapper>

            <Styled.TooltipWrapper ref={tooltipShareRef}>
              <Tooltip
                label={overlay.logged.clipboardConfirm}
                isVisible={isTooltipShareVisible}
                xPosition={isMobileLayout() ? 'left' : 'center'}
                yPosition={isMobileLayout() ? 'top' : 'bottom'}
              />
            </Styled.TooltipWrapper>
          </Styled.ContentLeft>

          <ButtonClose
            ref={closeRef}
            onClick={handleClose}
            autoAnimate={false}
          />

          <Styled.ContentRight>
            <Playlists ref={playlistsRef} />
          </Styled.ContentRight>
        </Styled.Wrapper>
      </>
    )
  );
};

PlaylistsOverlay.defaultProps = defaultProps;

export default PlaylistsOverlay;
