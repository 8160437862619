import styled from 'styled-components';

import { colors, layout } from 'utils/styles/theme';
import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${setVh(100)};
  z-index: ${layout.zIndex.transitionBars};
  pointer-events: none;
`;

export const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.black};
  opacity: 0;
`;

export const BarWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background: ${colors.black};
  transform-origin: center;
  pointer-events: auto;
`;
