import React, { useRef, useImperativeHandle } from 'react';

import Button, { ButtonRef } from 'components/Button/Button';
import InteractiveMediaMessage, { InteractiveMediaMessageRef } from 'components/InteractiveMedia/InteractiveMediaMessage/InteractiveMediaMessage';
import ProBg from 'assets/images/ui/pro-bg.jpg';
import { useCopyStore } from 'store';
import PreloaderService from 'u9/services/preloader.service';
import { ColorNames } from 'utils/styles/theme';
import useGesture from './InteractiveImages.gesture';
import useMotion from './InteractiveImages.motion';
import * as Styled from './InteractiveImages.styles';

export interface InteractiveImagesRef {
  animateIn: () => gsap.core.Timeline;
}

export interface InteractiveImagesSources {
  main?: string;
  revealed?: string;
}

export interface InteractiveImagesProps {
  backgroundImage?: string;
  images?: InteractiveImagesSources[];
  mainDownloadable?: string;
  color?: ColorNames;
  currentIndex?: number;
  previousIndex?: number;
}

const defaultProps: Partial<InteractiveImagesProps> = {
  backgroundImage: ProBg,
  images: [],
  mainDownloadable: '',
  color: ColorNames.roti,
  currentIndex: 0,
  previousIndex: 0
};

const InteractiveImages = React.forwardRef<InteractiveImagesRef, InteractiveImagesProps>(
  (props: InteractiveImagesProps, ref) => {
    const { images, mainDownloadable, currentIndex, previousIndex, backgroundImage } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const downloadableRef = useRef<HTMLAnchorElement>(null);
    const imagesWrapperRefs = useRef<HTMLDivElement[]>([]);
    const interactiveMediaMessageWrapperRef = useRef<HTMLDivElement>(null);
    const interactiveMediaMessageRef = useRef<InteractiveMediaMessageRef>(null);
    const downloadButtonRef = useRef<ButtonRef>(null);

    const { copy } = useCopyStore();

    const { held } = useGesture(wrapperRef);
    const { animateIn } = useMotion(
      {
        imagesWrapperRefs,
        downloadableRef,
        interactiveMediaMessageWrapperRef,
        interactiveMediaMessageRef,
        downloadButtonRef
      },
      {
        held,
        currentIndex,
        previousIndex
      }
    );

    useImperativeHandle(
      ref,
      (): InteractiveImagesRef => ({
        animateIn
      })
    );

    if (!copy.experience) return null;
    const {
      experience: { hotspotsOverlays: { download: downloadCopy } },
    } = copy;

    if (!downloadCopy) return null;

    return (
      <Styled.Wrapper
        ref={wrapperRef}
        background={PreloaderService.get(backgroundImage)}
      >
        {
          mainDownloadable &&
          <Styled.Downloadable
            ref={downloadableRef}
            href={mainDownloadable}
            download
          >
            <Button
              ref={downloadButtonRef}
              label={downloadCopy}
              icon="download"
              color={ColorNames.roti}
              onClick={() => null}
              isBig
              autoAnimate={false}
              showFrom="right"
            />
          </Styled.Downloadable>
        }
        {
          images.map((image, index) => (
            <Styled.ImagesWrapper
              key={`ImagesWrapper-${index}`}
              ref={ref => imagesWrapperRefs.current[index] = ref}
            >
              <Styled.MainImageWrapper>
                <Styled.MainImage src={image.main} />
              </Styled.MainImageWrapper>
              <Styled.RevealedImageWrapper>
                <Styled.Image src={image.revealed} />
              </Styled.RevealedImageWrapper>
            </Styled.ImagesWrapper>
          ))
        }
        <Styled.InteractiveMediaMessageWrapper ref={interactiveMediaMessageWrapperRef}>
          <InteractiveMediaMessage ref={interactiveMediaMessageRef} color={ColorNames.roti} />
        </Styled.InteractiveMediaMessageWrapper>
      </Styled.Wrapper>
    );
  }
);

InteractiveImages.displayName = 'InteractiveImages';
InteractiveImages.defaultProps = defaultProps;

export default InteractiveImages;
