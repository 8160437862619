import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors, layout } from 'utils/styles/theme';
import { fullscreen, setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export interface InteractionProps {
  isContain?: boolean;
}

export const Wrapper = styled.div`
 ${fullscreen()}
  user-select: none;

  ${mediaTablet(`
    pointer-events: none;
  `)}
`;

export const InteractionOverlay = styled.div<InteractionProps>`
  position: absolute;
  top: 0;
  left: ${(props) => props.isContain ? '50%' : 0};
  transform: ${(props) => props.isContain ? 'translateX(-50%)' : 'none'};
  width: ${(props) => props.isContain ? '45vw' : '100%'};;
  height: 100%;
  background: transparent;
  opacity: 0.5;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
`;

export const VideoOverlay = styled(motion.div)`
  ${fullscreen()}
  user-select: none;
`;

export const VideoOverlayBackground = styled.div`
  ${fullscreen()}
  background-color: ${colors.blueRibbon};
  opacity: 0.5;
  user-select: none;
`;

export const VideoOverlayWrapper = styled.div<InteractionProps>`
  position: absolute;
  top: 0;
  left: ${(props) => props.isContain ? '50%' : 0};
  transform: ${(props) => props.isContain ? 'translateX(-50%)' : 'none'};
  width: ${(props) => props.isContain ? '45vw' : '100%'};;
  height: 100%;
  cursor: pointer;
  user-select: none;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }
`;

export const Message = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle2', locale)};
  margin-left: 24rem;
  user-select: none;
`;

export const InteractiveMediaMessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 60rem;
  transform: translateX(-50%);

  ${mediaTablet(`
    bottom: ${layout.headerFooterPadding.tablet - 12}rem;
  `)}

  ${mediaDesktop(`
    bottom: ${layout.headerFooterPadding.desktop}rem;
  `)}
`;
