import { useRef, useEffect, useState } from 'react';

import {
  useCopyStore,
  useGlobalStore,
  useHotspotOverlayStore,
  useClubhouseStore
} from 'store';
import { isMobileLayout } from 'utils/styles/responsive';

const useTooltip = (isTooltipInViewport: boolean, pageIndex = 0) => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const { copy } = useCopyStore();
  const { user } = useGlobalStore();
  const { slideIndex } = useHotspotOverlayStore();
  const { hasSeenSpotifyPlayer, setSeenSpotifyPlayer } = useClubhouseStore();

  const showTooltipTimeout = useRef<number>(null);

  const hideTooltip = () => {
    setTooltipVisible(false);
  }

  useEffect(() => {
    return () => {
      if (showTooltipTimeout.current) window.clearTimeout(showTooltipTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (!hasSeenSpotifyPlayer && pageIndex === slideIndex && isTooltipInViewport) {
      setSeenSpotifyPlayer(true);

      if (showTooltipTimeout.current) window.clearTimeout(showTooltipTimeout.current);
      showTooltipTimeout.current = window.setTimeout(() => {
        showTooltipTimeout.current = null;
        setTooltipVisible(true);
      }, isMobileLayout() ? 500 : 1500);
    }

    if (pageIndex !== slideIndex && isTooltipVisible) {
      if (showTooltipTimeout.current) {
        window.clearTimeout(showTooltipTimeout.current);
        showTooltipTimeout.current = null;
      }
      setTooltipVisible(false);
    }
  }, [hasSeenSpotifyPlayer, setSeenSpotifyPlayer, pageIndex, slideIndex, isTooltipVisible, isTooltipInViewport]);

  if (!copy.experience) return null;
  const {
    experience: { clubhouseEntry: { prompts: { favorite_logged, favorite_notLogged } } },
  } = copy;
  if (!favorite_logged || !favorite_notLogged) return null;

  const tooltipCopy = user ? favorite_logged : favorite_notLogged;

  return {
    isTooltipVisible,
    tooltipCopy,
    hideTooltip
  }
};

export default useTooltip;
