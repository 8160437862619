import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import ProBg from 'assets/images/ui/pro-bg.jpg';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { useCopyStore } from 'store';
import { ReactComponent as HexagonCursorSvg } from 'svgs/hexagon-cursor.svg';
import { isTouchDevice } from 'u9/utils/platform';
import { ColorNames } from 'utils/styles/theme';

import useInteraction from './../InteractiveMedia.gesture';
import useMotion from './InteractiveImage.motion';

import * as Styled from './InteractiveImage.styles';

export interface InteractiveImageRef {
  showImage: () => gsap.core.Timeline;
  hideImage: () => gsap.core.Timeline;
}

export interface InteractiveImageProps {
  backgroundImage?: string;
  mainImage?: string;
  revealedImage?: string;
  color?: ColorNames;
  withMessage?: boolean;
}

const defaultProps: Partial<InteractiveImageProps> = {
  backgroundImage: ProBg,
  mainImage: null,
  revealedImage: null,
  color: ColorNames.roti,
  withMessage: true
};

const InteractiveImage = React.forwardRef<InteractiveImageRef, InteractiveImageProps>(
  ({
    backgroundImage,
    mainImage,
    revealedImage,
    color,
    withMessage
  }, ref) => {
    const { experience: { hotspotsOverlays: { view } } } = useCopyStore(state => state.copy);
    const interactionOverlayRef = useRef<HTMLDivElement>(null);
    const imageWrapperRef = useRef<HTMLDivElement>(null);
    const { held } = useInteraction(interactionOverlayRef);
    const {
      fadeMotion,
      slideMotionMain,
      slideMotionSecondary,
      showImage,
      hideImage
    } = useMotion(held, interactionOverlayRef, imageWrapperRef);

    useEffect(() => {
      if (held) AudioManager.play(SOUNDS_DATA.behindBurnShort);
    }, [held]);

    useImperativeHandle(
      ref,
      (): InteractiveImageRef => ({
        showImage,
        hideImage
      })
    )

    return (
      <Styled.Wrapper ref={interactionOverlayRef} background={backgroundImage}>
        <Styled.ImageWrapper {...slideMotionSecondary}>
          <Styled.Image src={revealedImage} scale={0.95} />
        </Styled.ImageWrapper>

        <Styled.MainImageWrapper ref={imageWrapperRef} {...slideMotionMain}>
          <Styled.MainImage src={mainImage} scale={0.95} />
        </Styled.MainImageWrapper>

        <AnimatePresence>
          {
            !held && withMessage &&
            <Styled.MessageWrapper
              color={color}
              {...fadeMotion}
            >
              <HexagonCursorSvg />
              <Styled.Message>
                {isTouchDevice() ? view.mobile : view.desktop}
              </Styled.Message>
            </Styled.MessageWrapper>
          }
        </AnimatePresence>
      </Styled.Wrapper>
    );
  }
)

InteractiveImage.displayName = 'InteractiveImage';
InteractiveImage.defaultProps = defaultProps;

export default InteractiveImage;
